import {
  clientsClientsCreate,
  clientsClientsDestroy,
  clientsClientsInfoList,
  clientsClientsPartialUpdate,
  clientsClientsPredictionsRetrieve,
  clientsClientsRetrieve,
  clientsClientsUpdateRegularStatusRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { genId } from '~/shared/lib/utils';

import type {
  Counterparty,
  CounterpartyPatch,
  CounterpartyPost,
  CounterpartyQueryParams,
  CounterpartyRecord,
} from '../lib';

@singleton()
export class CounterpartiesRepository extends EntityRepository<
  Counterparty,
  CounterpartyQueryParams,
  CounterpartyRecord,
  CounterpartyPost,
  CounterpartyPatch
> {
  readonly entityName = 'clients.Client';

  schemas = {
    entity: undefined,
    entityRecord: undefined,
    entityPost: undefined,
    entityPatch: undefined,
  };

  getFn = async (id: number) => {
    const { data } = await clientsClientsRetrieve({ path: { id } });
    return data;
  };

  queryFn = async (query: CounterpartyQueryParams) => {
    const { data } = await clientsClientsInfoList({ query });
    return { records: data.results, count: data.count };
  };

  searchFn = async (input: string) => {
    const { data } = await clientsClientsPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };

  createFn = async (dto: CounterpartyPost) => {
    const res = await clientsClientsCreate({ body: { ...dto, client_verification: [] } });

    return res.data;
  };

  updateFn = async (entity: CounterpartyPatch) => {
    const { data } = await clientsClientsPartialUpdate({ body: entity, path: { id: entity.id } });
    return data;
  };

  deleteFn = async (id: number): Promise<void> => {
    await clientsClientsDestroy({ path: { id } });
  };

  updateRegularStatus = async () => {
    await clientsClientsUpdateRegularStatusRetrieve();
  };

  recordToPatch = (record: CounterpartyRecord): CounterpartyPatch => {
    return {
      ...record,
      business_units: record.business_units?.map((bu) => bu.id) ?? [],
      relations: record.relations as unknown as CounterpartyPatch['relations'],
      related_counterparties: record.related_counterparties?.map((rc) => rc.id) ?? [],
      regions: record.regions?.map((rc) => rc.id) ?? [],
    };
  };

  buildNewRecord = (): CounterpartyRecord => {
    return {
      id: -genId(),
      name: '',
      status: 'New',
      approval_status: 'wait',
      regular_status: 'potencial',
      relations: 'Neutral',
      related_counterparties: [],
      business_units: [],
      is_producer: false,
      company_group_title: '',
      supplier_names: '',
      exporter_names: '',
      role_names: [],
      persons_count: 0,
      last_update: '',
      content_type: 0,
      updates_count: 0,
      documents_count: 0,
      contracts_count: 0,
      invoices_count: 0,
      logistics_count: 0,
      create_time: null,
      update_time: null,
      author_first_name: '',
      author_last_name: '',
      author_avatar: '',
      editor_first_name: '',
      editor_last_name: '',
      editor_avatar: '',
      approval_ids: undefined,
      country_name: '',
      region_titles: [],
      district_title: '',
      district_phone_code: '',
      city_title: '',
      custom_clientroles_name_list: [],
      days_before_deadline_to_notify: 0,
      sap_vendor_number: '',
      abbreviation: '',
      custom_status_title: '',
      custom_status_codename: '',
      do_num_of_voyages: 0,
      ho_num_of_voyage: 0,
      custom_fields_data: [],
      renewal_date: '',
      predictions_order: 0,
      warehouses_list: [],
      payment_conditions_option_title: '',
      size: 'Small',
      basis_names: [],
      warehouse_distances: [],
      is_agent: false,
      transports_count: 0,
      cash_flow: '',
      assets: '',
      use_local_currency: false,
      current_server_time: '',
      pledge: '',
      clientupdate_next_future_contact: '',
      approvals: [],
      use_foreign_currency: false,
      crop_areas: [],
      clientupdate_last_create_time: '',
      regions: [],
      payment_conditions_option: 0,
    };
  };
}
