import type { Table } from '@tanstack/react-table';
import React from 'react';

import { useTranslation } from '~/shared/lib/i18n';
import { useObservableEagerState, useObservableState } from '~/shared/lib/state';
import { ActionView } from '~/shared/ui/components/action-bar/types';
import { AddFileIcon, BucketIcon, PlusIcon } from '~/shared/ui/icons';

import { InlineEditToolbar } from './inline-edit-toolbar';
import { SelectRecordToolbar } from './select-record-toolbar';
import { useDatasetModel } from '../../hooks/models';
import type { DataParams, DataRecord, DatasetArgs } from '../../types';

export const TableViewToolbar = <R extends DataRecord, P extends DataParams>({
  table,
  editing$,
}: Pick<DatasetArgs<R, P>, 'editing$'> & { table: Table<R> }) => {
  const datasetModel = useDatasetModel<R, P>();
  const { t } = useTranslation();
  const selectedCount = React.useMemo(() => table.getSelectedRowModel().rows.length, [table]);
  const errors = useObservableEagerState(datasetModel.errors$);
  const groupActions = useObservableState(datasetModel.groupActions$, []);
  const editing = useObservableState(editing$);

  return (
    <>
      {!!selectedCount && !editing && (
        <SelectRecordToolbar
          selectedCount={selectedCount}
          config={[
            {
              label: {
                text: t('Delete'),
                context: 'text-text-additional-danger',
              },
              view: ActionView.Button,
              key: 'delete',
              Icon: BucketIcon,
              iconContext: 'text-text-additional-danger',
              action: () => {
                datasetModel.deleteRecords(table.getSelectedRowModel().rows.map((r) => r.original));
              },
            },
          ]}
        />
      )}
      {editing && (
        <InlineEditToolbar
          errors={errors}
          selectedCount={selectedCount}
          actions={groupActions}
          records={table.getSelectedRowModel().rows.map((r) => r.original)}
          config={[
            ...(selectedCount > 0
              ? [
                  {
                    label: {
                      text: t('Delete'),
                      context: 'text-text-additional-danger',
                    },
                    view: ActionView.Button,
                    key: 'delete',
                    Icon: BucketIcon,
                    iconContext: 'text-text-additional-danger',
                    action: () => {
                      datasetModel.deleteRecords(
                        table.getSelectedRowModel().rows.map((r) => r.original),
                      );
                    },
                  },
                ]
              : []),
            {
              label: {
                text: t('Save'),
                context: 'text-text-additional-success',
              },
              Icon: AddFileIcon,
              iconContext: 'text-text-additional-success',
              view: ActionView.Button,
              key: 'save',
              disabled: errors.length > 0,
              action: datasetModel.saveRecords,
            },
            {
              label: {
                text: t('Quick add'),
                context: 'text-text-additional-success',
              },
              Icon: PlusIcon,
              iconContext: 'text-text-additional-success',
              view: ActionView.Button,
              key: 'create',
              action: datasetModel.addRecord,
            },
          ]}
        />
      )}
    </>
  );
};
