import React from 'react';

import type { PageFiltersProps } from '~/core/page-filters/types';
import type { ComponentViewProps } from '~/shared/lib/components';
import { createComponent } from '~/shared/lib/components';

import { PageFiltersModelContext } from '../../hooks';
import { PageFiltersModel } from '../../services/page-filters.model';
import { FiltersLine } from '../filters-line';

const PageFiltersView = ({
  model,
  mode,
}: ComponentViewProps<PageFiltersModel, PageFiltersProps>) => {
  return (
    <PageFiltersModelContext.Provider value={model}>
      {mode === 'line' ? <FiltersLine /> : <>Mode {mode} not implemented</>}
    </PageFiltersModelContext.Provider>
  );
};

export const PageFilters = createComponent(PageFiltersView, PageFiltersModel);
