import type { Cell } from '@tanstack/react-table';
import React from 'react';

import { CellComponent } from './cell-component';
import type { Row } from './types';
import { useDatasetModel } from '../../hooks/models';
import type { DataParams, DataRecord } from '../../types';

const CellMemo = <R extends DataRecord>({ cell }: { cell: Cell<R, unknown> }) => {
  const cellValue = React.useMemo(() => {
    return {
      id: cell.id,
      rowId: cell.row.original.id,
      colKey: String(cell.column.columnDef.meta?.field.key),
      sticky: cell.column.columnDef.enablePinning,
      cell: cell.column.columnDef.cell,
      getContext: cell.getContext,
      getSize: cell.column.getSize,
      getStart: cell.column.getStart,
    };
  }, [
    cell.column.columnDef.cell,
    cell.column.columnDef.enablePinning,
    cell.column.columnDef.meta?.field.key,
    cell.column.getSize,
    cell.column.getStart,
    cell.getContext,
    cell.id,
    cell.row.original.id,
  ]);

  return <CellComponent cell={cellValue} />;
};

export const RowComponent = <R extends DataRecord>({ row }: { row: Row<R> }) => {
  const datasetModel = useDatasetModel<R, DataParams>();

  const rowRef = React.useMemo(
    () => datasetModel.getRowRef(row.original.id),
    [datasetModel, row.original.id],
  );

  return (
    <div
      data-index={row.index}
      ref={rowRef}
      className="group max-height-[60px] z-[1] flex h-[32px] w-full select-none"
    >
      {row.getVisibleCells().map((cell) => (
        <CellMemo key={cell.id} cell={cell} />
      ))}
    </div>
  );
};
