import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import type {
  Request,
  RequestPatch,
  RequestPost,
  RequestQueryParams,
  RequestRecord,
  RequestsRepository,
} from '~/features/deals/requests';
import { createComponent } from '~/shared/lib/components';

import type { RequestsListContainerProps } from './requests-list.model';
import { RequestsListModel } from './requests-list.model';

const RequestsListContainerView: React.FC<
  ContainerViewProps<RequestsListModel, RequestsListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      RequestsListModel,
      Request,
      RequestQueryParams,
      RequestsRepository,
      RequestsListContainerProps,
      RequestRecord,
      RequestPost,
      RequestPatch
    >
      model={model}
    />
  );
};

export const RequestsListContainer = createComponent(RequestsListContainerView, RequestsListModel);
