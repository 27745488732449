import ng from 'angular';

import type { CounterpartyRecord } from '~/features/crm/counterparties';
import { notify, notifyError } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CoreService } from '^/app/core/core.service';
import type { CoreUtils } from '^/app/core/core.utils';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import { getModalRoot } from '^/shared/ui/modal';
import { html } from '^/shared/utils';

function Service(
  $resource: ng.resource.IResourceService,
  $http: ng.IHttpService,
  $uibModal: ng.ui.bootstrap.IModalService,
  $rootScope: GtRootScopeService,
  $state: ng.ui.IStateService,
  $q: ng.IQService,
  gettext: ng.gettext.gettextFunction,
  CoreUtils: CoreUtils,
  CoreService: CoreService,
  AccountsService: AccountsService,
) {
  let _counters = {};

  const ClientResource: any = $resource(
    '/api/clients/clients/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      details: {
        method: 'GET',
        isArray: false,
        url: 'api/clients/client-details/:id/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/predictions/',
      },
      emailRecipients: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/email_recipients/',
      },
      table_info: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/info/',
      },
      sidebar: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/sidebar/',
      },
      table_legal: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/legal/',
      },
      table_dates: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/confirmation/',
      },
      table_special: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/special/',
      },
      table_limits: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/limits/',
      },
      table_potentials: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/potentials/',
      },
      approvals: {
        method: 'POST',
        isArray: false,
        url: '/api/clients/clients/approvals/',
      },
      save_confirmations: {
        method: 'PATCH',
        isArray: false,
        url: '/api/clients/clients/:id/save_confirmations/',
      },
      deleteConfirmation: {
        method: 'GET',
        url: '/api/clients/clients/:id/delete_confirmation/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clients/export_column_names/',
      },
    },
  );

  const ResponsibilityResource = $resource(
    '/api/clients/responsibilities/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/responsibilities/predictions/',
      },
    },
  );

  const PersonResource: any = $resource(
    '/api/clients/persons/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      createUser: {
        method: 'PATCH',
        isArray: false,
        url: '/api/clients/persons/:id/create_user/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/persons/predictions/',
      },
    },
  );

  const ClientUpdateResource = $resource(
    '/api/clients/clientupdates/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clientupdates/predictions/',
      },
      listInfo: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clientupdates-info/',
      },
      getSubjectContentTypes: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clientupdates/get_updates_entity_contenttypes/',
      },
      exportColumnNames: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clientupdates/export_column_names/',
      },
    },
  );

  const ClientRoleResource = $resource(
    '/api/clients/clientroles/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clientroles/predictions/',
      },
      predictionsDetails: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clientroles/:id/predictions_details/',
      },
      emailRecipients: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/clientroles/email_recipients/',
      },
    },
  );

  const CustomClientRoleResource = $resource(
    '/api/clients/customclientroles/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/customclientroles/predictions/',
      },
      predictionsDetails: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/customclientroles/:id/predictions_details/',
      },
    },
  );

  const ClientTemplateResource = $resource(
    '/api/clients/client-templates/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/client-templates/predictions/',
      },
    },
  );

  const ConsolidatedResource = $resource(
    '/consolidated/info/',
    {},
    {
      query: { method: 'GET', isArray: false },
    },
  );

  const ElevatorResource = $resource(
    '/api/clients/elevators/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
        cancellable: true,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/elevators/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/elevators/predictions/',
      },
    },
  );

  const DelivererResource = $resource(
    '/api/clients/deliverers/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/deliverers/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/deliverers/predictions/',
      },
    },
  );

  const SupplierResource: any = $resource(
    '/api/clients/suppliers/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
        cancellable: true,
      },
      table_info: {
        method: 'GET',
        isArray: false,
        cancellable: true,
        url: '/api/clients/suppliers/info/',
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/suppliers/:id/contracts_info/',
      },
      contractsByCrops: {
        method: 'GET',
        isArray: true,
        url: '/api/clients/suppliers/:id/contracts_by_crops/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/suppliers/predictions/',
      },
      table_list: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/suppliers/table_list/',
      },
      tableLimitsLight: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/suppliers/limits/',
      },
      bulkUpdate: {
        method: 'POST',
        url: '/api/clients/suppliers/bulk_update/',
      },
    },
  );

  const BuyerResource: any = $resource(
    '/api/clients/buyers/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      table_info: {
        method: 'GET',
        isArray: false,
        cancellable: true,
        url: '/api/clients/buyers/info/',
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/buyers/:id/contracts_info/',
      },
      contractsByCrops: {
        method: 'GET',
        isArray: true,
        url: '/api/clients/buyers/:id/contracts_by_crops/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/buyers/predictions/',
      },
      table_list: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/buyers/table_list/',
      },
      tableLimitsLight: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/buyers/limits/',
      },
    },
  );

  const BrokerResource = $resource(
    '/api/clients/brokers/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/brokers/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/brokers/predictions/',
      },
    },
  );

  const InsurerResource = $resource(
    '/api/clients/insurers/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/insurers/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/insurers/predictions/',
      },
    },
  );

  const ExporterResource = $resource(
    '/api/clients/exporters/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/exporters/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/exporters/predictions/',
      },
      table_list: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/exporters/table_list/',
      },
    },
  );

  const BankResource = $resource(
    '/api/clients/banks/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/banks/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/banks/predictions/',
      },
    },
  );

  const OwnerResource = $resource(
    '/api/clients/owners/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/owners/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/owners/predictions/',
      },
      predictionsDetails: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/owners/:id/predictions_details/',
      },
    },
  );

  const OtherResource = $resource(
    '/api/clients/others/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/others/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/others/predictions/',
      },
    },
  );

  const FarmResource = $resource(
    '/api/clients/farms/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
        cancellable: true,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/farms/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/farms/predictions/',
      },
      bulkUpdate: {
        method: 'POST',
        url: '/api/clients/farms/bulk_update/',
      },
    },
  );

  const ConfirmationResource = $resource(
    '/api/workflow/stages/confirmations/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
    },
  );

  const CompanyGroupResource = $resource(
    '/api/clients/company-groups/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/company-groups/predictions/',
      },
    },
  );

  const SurveyorResource = $resource(
    '/api/clients/surveyors/:id/',
    {
      id: '@id',
    },
    {
      query: {
        method: 'GET',
        isArray: false,
      },
      update: {
        method: 'PATCH',
      },
      contracts_info: {
        method: 'GET',
        url: '/api/clients/surveyors/:id/contracts_info/',
      },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/surveyors/predictions/',
      },
    },
  );

  const ClientConfirmationDocumentResource = $resource(
    '/api/clients/client-confirmation-documents/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/client-confirmation-documents/predictions/',
      },
    },
  );

  const ClientCommentaryResource = $resource(
    '/api/clients/client-commentaries/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/client-commentaries/predictions/',
      },
    },
  );

  const UpdateTopicResource = $resource(
    '/api/clients/update-topics/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/update-topics/predictions/',
      },
    },
  );

  const UpdateFailureReasonResource = $resource(
    '/api/clients/update-failure-reasons/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/update-failure-reasons/predictions/',
      },
    },
  );

  const ClientDocumentTypeResource = $resource(
    '/api/clients/client-document-types/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/client-document-types/predictions/',
      },
    },
  );

  const CargoBrandResource = $resource(
    '/api/clients/cargo-brands/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/cargo-brands/predictions/',
      },
    },
  );

  const CustomsResource = $resource(
    '/api/clients/customs/:id/',
    {
      id: '@id',
    },
    {
      query: { method: 'GET', isArray: false },
      update: { method: 'PATCH' },
      predictions: {
        method: 'GET',
        isArray: false,
        url: '/api/clients/customs/predictions/',
      },
    },
  );

  const CropAreaQualityResource = $resource(
    '/api/clients/crop-area-qualities/:id/',
    {
      id: '@id',
    },
    {
      get: {
        method: 'GET',
      },
      save: {
        method: 'POST',
      },
      query: {
        method: 'GET',
        isArray: false,
      },
      remove: {
        method: 'DELETE',
      },
      delete: {
        method: 'DELETE',
      },
      update: {
        method: 'PATCH',
      },
      bulkCreateOrUpdate: {
        method: 'POST',
        isArray: false,
        url: '/api/clients/crop-area-qualities/bulk_create_or_update/',
      },
    },
  );

  const ClientRoleSeasonLimitResource = $resource(
    '/api/clients/clientrole-season-limits/:id/',
    { id: '@id' },
    {
      get: {
        method: 'GET',
      },
      save: {
        method: 'POST',
      },
      update: {
        method: 'PATCH',
      },
    },
  );

  return {
    Client: ClientResource,
    Person: PersonResource,
    Responsibility: ResponsibilityResource,
    Elevator: ElevatorResource,
    Deliverer: DelivererResource,
    Supplier: SupplierResource,
    SupplierLight: getSupplierLight,
    Buyer: BuyerResource,
    BuyerLight: getBuyerLight,
    Broker: BrokerResource,
    Insurer: InsurerResource,
    Exporter: ExporterResource,
    Bank: BankResource,
    Owner: OwnerResource,
    Other: OtherResource,
    Farm: FarmResource,
    Confirmation: ConfirmationResource,
    Surveyor: SurveyorResource,
    ClientUpdate: ClientUpdateResource,
    CompanyGroup: CompanyGroupResource,
    ClientConfirmationDocument: ClientConfirmationDocumentResource,
    UpdateTopic: UpdateTopicResource,
    UpdateFailureReason: UpdateFailureReasonResource,
    ClientDocumentType: ClientDocumentTypeResource,
    ClientCommentary: ClientCommentaryResource,
    CropAreaQuality: CropAreaQualityResource,
    CargoBrand: CargoBrandResource,
    Customs: CustomsResource,
    clientModal: clientModal,
    clientModalQuick: clientModalQuick,
    getCounterpartyData: getCounterpartyData,
    roleModal: roleModal,
    openRoleDetails: openRoleDetails,
    clientUpdateModal: clientUpdateModal,
    personModal: personModal,
    updateListModal: updateListModal,
    getCounters: getCounters,
    setCounters: setCounters,
    updateCounters: updateCounters,
    ClientRole: ClientRoleResource,
    CustomClientRole: CustomClientRoleResource,
    ClientTemplate: ClientTemplateResource,
    cloneRole: cloneRole,
    Consolidated: ConsolidatedResource,
    roleListModal: roleListModal,
    clonePerson: clonePerson,
    createUser: createUser,
    sendEmailModal: sendEmailModal,
    getPagesConfig: getPagesConfig,
    getSimilarClients: getSimilarClients,
    getBuyers: getBuyers,
    getClient: getClient,
    getClientDetails: getClientDetails,
    getClientList: getClientList,
    saveClient: saveClient,
    deleteClient: deleteClient,
    cloneClient: cloneClient,
    saveClientConfirmations: saveClientConfirmations,
    saveRole: saveRole,
    getContractsConsolidated: getContractsConsolidated,
    getContractsByCrops: getContractsByCrops,
    getPaymentConditions: getPaymentConditions,
    getClientResource: getClientResource,
    getDefaultClientValue: getDefaultClientValue,
    getClientTemplate: getClientTemplate,
    getClientCheckList: getClientCheckList,
  };

  ////////////////

  function getCounters() {
    return _counters;
  }

  function getSupplierLight(queryParams: QueryParams & { serializer: string }) {
    // temporary way to handle old and new supplier endpoint systems
    const newEndpointMapper: any = {
      table_info: 'table_info',
      table_contacts: 'query',
      table_dates: 'query',
      table_deals: 'query',
      table_limits: 'tableLimitsLight',
      table_special: 'query',
      table_legal: 'query',
    };
    return SupplierResource[newEndpointMapper[queryParams.serializer]](queryParams).$promise;
  }

  function getBuyerLight(queryParams: QueryParams & { serializer: string }) {
    // temporary way to handle old and new buyer endpoint systems
    const newEndpointMapper: any = {
      table_info: 'table_info',
      table_contacts: 'query',
      table_dates: 'query',
      table_deals: 'query',
      table_limits: 'tableLimitsLight',
      table_special: 'query',
      table_legal: 'query',
    };
    return BuyerResource[newEndpointMapper[queryParams.serializer]](queryParams).$promise;
  }

  function updateCounters(counters: any) {
    _counters = { ..._counters, ...counters };
    $rootScope.$broadcast('clients_counters_updated');
  }

  function setCounters(counters: any) {
    _counters = counters;
    $rootScope.$broadcast('clients_counters_updated');
  }

  function openClientModal(clientId?: number, withRoles?: boolean, tab?: string) {
    return $uibModal.open({
      backdrop: 'static',
      template: html`<client-modal client="client" tab="tab" modal-instance="$uibModalInstance">
      </client-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).client = { id: clientId };
          ($scope as any).tab = tab;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      appendTo: getModalRoot(),
      windowClass: 'modal-template',
      size: 'sm',
    }).result as Promise<object>;
  }

  function cloneClient(client: { id: number }, tab?: string): Promise<void> {
    if (!confirm(gettext('Do you want to clone this Client?'))) {
      return Promise.resolve();
    }
    return ClientResource.get({
      id: client.id,
      serializer: 'full',
    }).$promise.then(function (client: any) {
      const newClient = CoreUtils.cleanBeforeClone({ ...client }, [
        'user',
        'status',
        'approval_status',
        'custom_status',
      ]);

      return $uibModal.open({
        backdrop: 'static',
        template: html`<client-modal client="client" tab="tab" modal-instance="$uibModalInstance">
        </client-modal>`,
        controller: [
          '$scope',
          '$uibModalInstance',
          function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
            ($scope as any).client = newClient;
            ($scope as any).tab = tab;
            ($scope as any).$uibModalInstance = $uibModalInstance;
          },
        ],
        windowClass: 'modal-template',
        appendTo: getModalRoot(),
        size: 'sm',
      }).result;
    });
  }

  function clientModal(clientId?: number, withRoles?: boolean, tab?: string): Promise<object> {
    if (AccountsService.hasPerm('edit_closed_elements')) {
      return openClientModal(clientId, withRoles, tab);
    }
    return ClientResource.get({ id: clientId }).$promise.then((data: any) => {
      if (['Approved', 'Approved_condition', 'Rejected'].every((v) => v !== data.status)) {
        return openClientModal(clientId, withRoles, tab);
      } else {
        notifyError(gettext("You can't edit approved clients"));
        return Promise.resolve();
      }
    });
  }

  function openClientModalQuick(clientId?: number, withRoles?: boolean, tab?: string) {
    return $uibModal.open({
      backdrop: 'static',
      template: html`<client-modal client="client" tab="tab" modal-instance="$uibModalInstance">
      </client-modal>`,
      controller: [
        '$scope',
        '$uibModalInstance',
        function ($scope: ng.IScope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) {
          ($scope as any).client = { id: clientId };
          ($scope as any).tab = tab;
          ($scope as any).$uibModalInstance = $uibModalInstance;
        },
      ],
      windowClass: 'modal-template modal-template-half-width quick-modal',
      appendTo: getModalRoot(),
      size: 'sm',
    }).result as Promise<object>;
  }

  function clientModalQuick(clientId?: number, withRoles?: boolean, tab?: string): Promise<object> {
    if (AccountsService.hasPerm('edit_closed_elements')) {
      return openClientModalQuick(clientId, withRoles, tab);
    }
    return ClientResource.get({ id: clientId }).$promise.then((data: any) => {
      if (['Approved', 'Approved_condition', 'Rejected'].every((v) => v !== data.status)) {
        return openClientModalQuick(clientId, withRoles, tab);
      } else {
        notifyError(gettext("You can't edit approved clients"));
        return Promise.resolve();
      }
    });
  }

  function personModal(person: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./person-modal/person-modal.tpl.html?raw'),
      controller: 'ClientsPersonModalController as vm',
      windowClass: 'modal-template modal-template-third-width',
      appendTo: getModalRoot(),
      resolve: {
        person: () => {
          return person;
        },
      },
    }).result;
  }

  function getSimilarClients(client: any) {
    const lookupField = $rootScope.user.settings.COUNTERPARTY_LOOKUP_FIELD,
      searchValue = client[lookupField],
      defer = $q.defer(),
      params: Record<string, string> = {};
    if (searchValue == 'name') {
      params.search = searchValue;
    } else {
      params[lookupField] = searchValue;
    }

    if (searchValue) {
      ClientResource.predictions(params, function (data: any) {
        defer.resolve(data.results);
      });
    } else {
      defer.resolve([]);
    }
    return defer.promise;
  }

  function updateListModal(queryParams: QueryParams) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./components/update-list-modal/update-list-modal.tpl.html?raw'),
      controller: 'UpdateListModalController as vm',
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      resolve: {
        queryParams: () => {
          return queryParams;
        },
      },
    }).result;
  }

  function roleModal(role: any) {
    if (role?.id) {
      return ClientRoleResource.get({ id: role.id, fields: ['client'] }).$promise.then(
        (data: any) => clientModal(data.client),
      );
    }
    //  return new created role
    let modalResult = clientModal();
    if (role.model_name) {
      modalResult = modalResult.then(function (data: any) {
        if (data?.id) {
          return ClientRoleResource.query({
            client: data.id,
            role_name: role.model_name.toLowerCase(),
            fields: ['id', 'name', 'model_name'],
          }).$promise.then(function (data: any) {
            return data.results.shift();
          });
        }
      });
    }
    return modalResult;
  }

  function openRoleDetails(role: any) {
    if (!role.id) {
      return $state.go('clients');
    }
    return ClientRoleResource.get({ id: role.id, fields: ['client'] }).$promise.then((data: any) =>
      $state.go('gt.page.client', { id: data.client }),
    );
  }

  function getCounterpartyData(params: any) {
    if (!params.company_code) {
      return;
    }
    return $http({
      method: 'GET',
      url: '/api/clients/clients/get_counterparty_data/',
      params: params,
    }).then((res: any) => {
      return res.data;
    });
  }

  function cloneRole(this: any, role: any) {
    return this[role.model_name].get({ id: role.id }).$promise.then(function (role: any) {
      delete role.id;
      delete role.client;
      return roleModal(role).then(function (data: any) {
        if (data?.id) {
          $state.go('gt.role', { role: data.model_name, id: data.id });
        } else {
          $state.go('clients.roles', {
            roleName: role.model_name.toLowerCase(),
          });
        }
      });
    });
  }

  function roleListModal(queryParams: QueryParams, args: any) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./components/role-list-modal/role-list-modal.tpl.html?raw'),
      controller: 'RoleListModalController as vm',
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      resolve: {
        queryParams: () => {
          return queryParams;
        },
        args: () => {
          return args;
        },
      },
    }).result;
  }

  function clientUpdateModal(id: number) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./client-update-modal/client-update-modal.tpl.html?raw'),
      controller: 'ClientUpdateModalController as vm',
      windowClass: 'modal-template',
      appendTo: getModalRoot(),
      resolve: {
        id: () => {
          return id;
        },
      },
    }).result;
  }

  function sendEmailModal(
    modelType: string,
    objectId: number,
    getRecipients?: any,
    hiddenCopy?: any,
    filterLevel?: string,
  ) {
    return $uibModal.open({
      backdrop: 'static',
      template: require('./components/send-email-modal/send-email-modal.tpl.html?raw'),
      controller: 'SendEmailModalController as vm',
      windowClass: 'modal-template modal-template-three-thirds-width',
      appendTo: getModalRoot(),
      resolve: {
        modelType: () => {
          return modelType;
        },
        objectId: () => {
          return objectId;
        },
        getRecipients: () => {
          return getRecipients;
        },
        hiddenCopy: () => {
          return hiddenCopy;
        },
        filterLevel: () => {
          return filterLevel;
        },
      },
    }).result as Promise<void>;
  }

  function clonePerson(person: any) {
    if (!confirm(gettext('Do you want to clone this Person?'))) {
      return $q.resolve();
    }
    return PersonResource.get({
      id: person.id,
      serializer: 'full',
    }).$promise.then(function (person: any) {
      let newObject = { ...person };
      delete newObject.user;
      newObject = CoreUtils.cleanBeforeClone(newObject);

      return personModal(newObject);
    });
  }

  function createUser(person: any) {
    if (!confirm(gettext('Do you want to create a user for this Person?'))) {
      return $q.resolve();
    }
    return PersonResource.createUser({ id: person.id }).$promise.then(
      () => notify(gettext('user created')),
      function (fail: any) {
        if (fail.data.errors) {
          ng.forEach(Object.keys(fail.data.errors), function (error: any) {
            notifyError(error + ': ' + fail.data.errors[error]);
          });
        } else {
          notifyError(fail.data.detail);
        }
      },
    );
  }

  function getBuyers(params: object) {
    return BuyerResource.query(params).$promise;
  }

  function getClientDetails(id: number, params?: object) {
    return ClientResource.details({ id: id, ...(params ?? {}) }).$promise;
  }

  function getClient(id: number, params?: object) {
    return ClientResource.get({ id: id, ...(params ?? {}) }).$promise;
  }

  function getClientList(params: any) {
    if (params.serializer && ClientResource[params.serializer]) {
      return ClientResource[params.serializer](params).$promise;
    }
    return ClientResource.query(params).$promise;
  }

  function saveClient(client: CounterpartyRecord) {
    client.custom_fields_data = client.custom_fields_data ?? [];
    const saveFunc = client.id ? ClientResource.update : ClientResource.save;
    return saveFunc(client).$promise;
  }

  function deleteClient(client: any) {
    return ClientResource.delete({ id: client.id }).$promise;
  }

  function saveClientConfirmations(clientId: number, confirmations: any, roleNames: any) {
    return ClientResource.save_confirmations({
      id: clientId,
      confirmations: confirmations,
      role_names: roleNames,
    }).$promise;
  }

  function saveRole(role: any, roleName: string, seasonId?: number) {
    // TODO: refactor this

    roleName = roleName || 'ClientRole';

    if (seasonId) {
      const newLimit = {
        limit_one_deal_in_usd_spot: role.limit_one_deal_in_usd_spot,
        limit_general_in_usd_spot: role.limit_general_in_usd_spot,
        limit_one_deal_in_usd_forward: role.limit_one_deal_in_usd_forward,
        limit_general_in_usd_forward: role.limit_general_in_usd_forward,
        season: seasonId,
        client_role: role.id,
      };

      if (role._limitId) {
        return ClientRoleSeasonLimitResource.update({ id: role._limitId }, newLimit).$promise;
      } else {
        return ClientRoleSeasonLimitResource.save(newLimit).$promise;
      }
    } else {
      const roleResource = getRoleResource(roleName);
      const saveFunc = role.id ? roleResource.update : roleResource.save;
      return saveFunc(role).$promise;
    }
  }

  function getPaymentConditions(roleName: string, roleId: number) {
    const roleResource = getRoleResource(roleName);
    return roleResource.get({
      id: roleId,
      fields: ['payment_conditions', 'payment_conditions_auto', 'payment_conditions_option'],
    }).$promise;
  }

  function getRoleResource(roleName: string) {
    const resources: any = {
      elevator: ElevatorResource,
      deliverer: DelivererResource,
      supplier: SupplierResource,
      buyer: BuyerResource,
      broker: BrokerResource,
      insurer: InsurerResource,
      exporter: ExporterResource,
      bank: BankResource,
      owner: OwnerResource,
      surveyor: SurveyorResource,
      other: OtherResource,
      farm: FarmResource,
      clientrole: ClientRoleResource,
    };

    return resources[roleName.toLowerCase()];
  }

  function getContractsConsolidated(clientId: number) {
    return $http({
      method: 'GET',
      url: '/api/contracts/contracts/client_details_consolidated/',
      params: { client: clientId },
    }).then(function (res: any) {
      return res.data;
    });
  }

  function getContractsByCrops(clientId: number) {
    const defer = $q.defer();
    const result: any = { buyer: [], supplier: [] };

    getClientRoles({ client: clientId, fields: ['id', 'model_name'] }).then(function (data: any) {
      const promises: any = [];

      const buyerRole = data.results
        .filter(function (i: any) {
          return i.model_name == 'Buyer';
        })
        .shift();
      if (buyerRole) {
        promises.push(
          $http({
            method: 'GET',
            url: '/api/clients/buyers/' + buyerRole.id + '/contracts_by_crops/',
            params: { model_name: 'Buyer' },
          }).then(function (res: any) {
            result.buyer = res.data;
          }),
        );
      } else {
        promises.push($q.when([]));
      }

      const supplierRole = data.results
        .filter(function (i: any) {
          return i.model_name == 'Supplier';
        })
        .shift();
      if (supplierRole) {
        promises.push(
          $http({
            method: 'GET',
            url: '/api/clients/suppliers/' + supplierRole.id + '/contracts_by_crops/',
            params: { model_name: 'Supplier' },
          }).then(function (res: any) {
            result.supplier = res.data;
          }),
        );
      } else {
        promises.push($q.when([]));
      }

      $q.all(promises).then(function () {
        defer.resolve(result);
      });
    });

    return defer.promise;
  }

  function getClientRoles(params?: object) {
    params = params ?? {};
    return $http({
      method: 'GET',
      url: '/api/clients/clientroles/',
      params: params,
    }).then(function (res: any) {
      return res.data;
    });
  }

  function getClientResource(role: any, contractType: any) {
    const clientsResource: any = {
      buyer: contractType === 'sale' ? 'clients.Buyer' : 'clients.Owner',
      supplier: contractType === 'sale' ? 'clients.Owner' : 'clients.Supplier',
    };
    return clientsResource[role];
  }

  function getDefaultClientValue() {
    const defaultSettings = $rootScope.user.settings.DEFAULT_VALUES;
    const {
      payment_conditions: paymentConditions,
      payment_conditions_auto: paymentConditionsAuto,
      payment_conditions_option: paymentConditionsOption,
    }: any = {
      ...defaultSettings,
    };

    return {
      business_units: CoreService.getBuList(),
      author: $rootScope.user.id,
      responsible: $rootScope.user.id,
      responsible_for_confirmation: $rootScope.user.id,
      payment_conditions: paymentConditions,
      payment_conditions_auto: paymentConditionsAuto,
      payment_conditions_option: paymentConditionsOption,
      relations: 'Neutral',
      size: 'Medium',
      status: 'New',
      regular_status: 'active',
      approval_status: 'wait',
      days_before_deadline_to_notify: 0,
      is_resident: true,
    };
  }

  function getPagesConfig() {
    return [
      {
        title: gettext('Counterparties'),
        permissions: ['view_client'],
        state: 'clients.roles',
        stateParams: { roleName: 'client' },
        icon: 'fa-building',
        counter: 'clients.client',
      },
      {
        title: gettext('Persons'),
        permissions: ['view_person'],
        state: 'clients.persons',
        counter: 'clients.person',
        icon: 'fa-users',
      },
      {
        title: gettext('Updates'),
        permissions: ['view_clientupdate'],
        state: 'clients.updates',
        icon: 'fa-commenting',
        counter: 'clients.clientupdate',
      },
    ];
  }

  function getClientTemplate(params: any) {
    if (!params.id) {
      return;
    }
    return $http({
      method: 'GET',
      url: '/api/clients/client-templates/' + params.id + '/get_template/',
    }).then((data: any) => data.data);
  }

  function getClientCheckList(clientId: number) {
    return ClientConfirmationDocumentResource.query({ client: clientId }).$promise;
  }
}

(function () {
  'use strict';
  ng.module('crm.clients.legacy').factory('ClientsService', Service);

  Service.$inject = [
    '$resource',
    '$http',
    '$uibModal',
    '$rootScope',
    '$state',
    '$q',
    'gettext',
    'CoreUtils',
    'CoreService',
    'AccountsService',
  ];
})();

export type ClientsService = ReturnType<typeof Service>;
