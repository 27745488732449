import React from 'react';

import { type ContainerViewProps, EntityListContainer } from '~/core/entity-lists';
import type { FinancePositionListContainerProps } from '~/features/finances/finance-position/components/finance-position-container/finance-position-list.model';
import { FinancePositionListModel } from '~/features/finances/finance-position/components/finance-position-container/finance-position-list.model';
import type {
  FinancePosition,
  FinancePositionListParams,
  FinancePositionPatch,
  FinancePositionPost,
  FinancePositionRecord,
} from '~/features/finances/finance-position/lib';
import { createComponent } from '~/shared/lib/components';

import type { FinancePositionRepository } from '../../services';

const FinancePositionListContainerView: React.FC<
  ContainerViewProps<FinancePositionListModel, FinancePositionListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      FinancePositionListModel,
      FinancePosition,
      FinancePositionListParams,
      FinancePositionRepository,
      FinancePositionListContainerProps,
      FinancePositionRecord,
      FinancePositionPost,
      FinancePositionPatch
    >
      model={model}
    />
  );
};

export const FinancePositionListContainer = createComponent(
  FinancePositionListContainerView,
  FinancePositionListModel,
);
