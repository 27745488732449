import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { FormFieldParamsService } from '^/app/core/components/form-field-params/form-field-params.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

export class RequestFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  AccountsService: AccountsService;
  ClientsService: any;
  ContractsService: ContractsService;
  CoreService: CoreService;
  FinancesService: FinancesService;
  FormFieldParamsService: FormFieldParamsService;
  GtUtils: GtUtilsService;
  LocationService: any;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
    ClientsService: any,
    AccountsService: AccountsService,
    ContractsService: ContractsService,
    LocationService: any,
    FormFieldParamsService: FormFieldParamsService,
    FinancesService: FinancesService,
  ) {
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.CoreService = CoreService;
    this.ClientsService = ClientsService;
    this.AccountsService = AccountsService;
    this.ContractsService = ContractsService;
    this.LocationService = LocationService;
    this.FormFieldParamsService = FormFieldParamsService;
    this.FinancesService = FinancesService;
  }

  getApprovalContractModel(request: any) {
    switch (request.stage) {
      case 'indicator':
        return ['contracts.Indicator', 'contracts.Request'];
      case 'ticket':
        return ['contracts.Request', 'contracts.Ticket', this.getApprovalConfigModel(request)];
      default:
        return ['contracts.Request'];
    }
  }

  getApprovalConfigModel(request: any) {
    const modelsMap = {
      indicator: { sale: 'contracts.Indicator', purchase: 'contracts.Indicator' },
      ticket: {
        sale: 'contracts.SaleTicketPosition',
        purchase: 'contracts.PurchaseTicketPosition',
        undefined: {},
        null: {},
      },
    } as any;
    return modelsMap[request.stage][request.contract_type];
  }

  getFormNamePrefix(request: any) {
    const prefix: any = [request.deal_type || '', request.contract_type || '']
      .filter((v) => Boolean(v))
      .join('-');
    return `${prefix}${prefix ? '-' : ''}`;
  }

  getFields(request: any, formName: string, priceWidgetFields?: any) {
    const isRequired = (scope: any) =>
      scope.fields
        .filter((field: any) => field.key === scope.options.key)
        .some((field: any) => field.templateOptions.required);
    let isExchangeFieldRequired: any;
    this.FormFieldParamsService.isRequiredCurrencyExchangeField(
      formName,
      request.business_unit,
    ).then((required: any) => (isExchangeFieldRequired = required));
    let isCargoFieldDisabled: any;
    if (request.from_request && request.stage === 'ticket') {
      this.ContractsService.isCargoFieldDisabled(request).then(
        (isDisabled: any) => (isCargoFieldDisabled = isDisabled),
      );
    }
    const fieldsDef: any = [
      {
        className: 'form-group-container col-xs-12 col-sm-3',
        fieldGroup: [
          {
            wrapper: 'gt-panel',
            templateOptions: {
              label: this.gettext('MAIN'),
            },
            fieldGroup: [
              {
                key: 'contract_type',
                type: 'gt-select',
                defaultValue: request.deal_type === 'services' ? 'purchase' : 'sale',
                templateOptions: {
                  label: this.gettext('Type'),
                  placeholder: this.gettext('Sale or Purchase'),
                  valueProp: 'value',
                  labelProp: 'name',
                  disabled: request.id,
                  options: [
                    { name: this.gettext('Purchase'), value: 'purchase' },
                    { name: this.gettext('Sale'), value: 'sale' },
                  ],
                },
              },
              {
                key: 'auto_name',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Generate name'),
                  hint: this.gettext('Check this if you want name to be auto generated'),
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.id',
                },
              },
              {
                key: 'contract_number',
                type: 'gt-input',
                templateOptions: {
                  label: (
                    {
                      indicator: this.gettext('Indicators number'),
                      ticket: this.gettext('Ticket number'),
                    } as any
                  )[request.stage],
                  addon: this.gettext('#'),
                },
              },
              {
                key: 'available_in_bot',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Available in bot'),
                },
                expressionProperties: {
                  'templateOptions.hide': () => request.stage !== 'indicator',
                },
              },
              {
                key: 'conclusion_date',
                type: 'gt-date-select',
                defaultValue: new Date(),
                templateOptions: {
                  label: this.gettext('Conclusion (date)'),
                  placeholder: this.gettext('date'),
                  required: true,
                  disabled: this.$rootScope.user.settings.DISABLE_CONCLUSION_DATE,
                  ...(request?.conclusionDateRange || {}),
                },
                validation: {
                  show: true,
                },
              },
              {
                key: 'internal_chain',
                type: 'gt-checkbox',
                defaultValue: false,
                templateOptions: {
                  label: this.gettext('Internal chain'),
                  hint: this.gettext('Check this if you want to use it as internal chain'),
                },
              },

              {
                key: 'possible_buyers_info',
                type: 'gt-textarea',
                templateOptions: {
                  label: this.gettext('Type new buyer'),
                },
                expressionProperties: {
                  'templateOptions.hide': () =>
                    request.contract_type === 'purchase' || request.stage === 'indicator',
                },
              },

              {
                key: 'potential_buyers',
                type: 'gt-ui-multiselect',
                templateOptions: {
                  label: this.gettext('POTENTIAL BUYERS'),
                  resource: 'clients.Buyer',
                  getQueryParams: () => ({ buyers: request.buyer }),
                  addFunc: () => this.ClientsService.roleModal({ model_name: 'Buyer' }),
                  addIcon: this.GtUtils.getIcon('clients.Buyer'),
                  addPerms: ['add_buyer'],
                },
                expressionProperties: {
                  'templateOptions.hide': () => request.contract_type === 'purchase',
                },
              },
              {
                key: 'buyer',
                type: 'gt-ui-select',
                defaultValue:
                  request.contract_type === 'sale'
                    ? undefined
                    : this.$rootScope.user.settings.DEFAULT_VALUES.owner,
                templateOptions: {
                  label:
                    request.contract_type === 'sale'
                      ? this.gettext('Buyer')
                      : this.gettext('Buyer/Owner'),
                  addFunc:
                    request.contract_type === 'sale'
                      ? () => this.ClientsService.roleModal({ model_name: 'Buyer' })
                      : () => this.ClientsService.roleModal({ model_name: 'Owner' }),
                  onSelect: () => {
                    if (request.contract_type === 'sale' && !request.id) {
                      this.ClientsService.getPaymentConditions('Buyer', request.buyer).then(
                        (data: any) => {
                          request.payment_conditions = data.payment_conditions;
                          request.payment_conditions_auto = data.payment_conditions_auto;
                          request.payment_conditions_option = data.payment_conditions_option;
                        },
                      );

                      this.ClientsService.Buyer.get({ id: request.buyer }).$promise.then(
                        (buyerDetails: any) => {
                          request.buyerStatus = buyerDetails.approval_status;
                          return buyerDetails;
                        },
                      );
                    }
                  },
                  addIcon:
                    request.contract_type === 'sale'
                      ? this.GtUtils.getIcon('clients.Buyer')
                      : this.GtUtils.getIcon('clients.Owner'),
                  addPerms: request.contract_type === 'sale' ? ['add_buyer'] : ['add_owner'],
                },
                expressionProperties: {
                  'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                    isRequired(scope),
                  'templateOptions.resource': (viewValue: any, modelValue: any, scope: any) =>
                    this.ClientsService.getClientResource(
                      scope.options.key,
                      scope.model.contract_type,
                    ),
                },
              },
              {
                template: html`
                  <div style="margin: 8px 0" ng-if="!to.hide">
                    <cp-status-display
                      status="to.buyerStatus"
                    </cp-status-display>
                  </div>
                `,
                expressionProperties: {
                  'templateOptions.hide': () => !request.buyer || request.contract_type !== 'sale',
                  'templateOptions.buyerStatus': (viewValue: any, modelValue: any, scope: any) => {
                    return scope.model.buyerStatus;
                  },
                },
                defaultValue: request.buyer
                  ? this.ClientsService.Buyer.get({ id: request.buyer }).$promise.then(
                      (buyerDetails: any) => {
                        request.buyerStatus = buyerDetails.approval_status;
                        return buyerDetails;
                      },
                    )
                  : null,
              },
              {
                template: html`
                  <div style="margin: 8px 0" ng-if="!to.hide">
                    <season-limit-display
                      role-id="to.roleId"
                      role-name="'buyer'"
                      season-id="to.seasonId"
                      deal-type="to.dealType"
                    </season-limit-display>
                  </div>
                `,
                expressionProperties: {
                  'templateOptions.hide': () =>
                    request.stage === 'indicator' || !request.buyer || !request.season,
                  'templateOptions.roleId': (viewValue: any, modelValue: any, scope: any) => {
                    return scope.model.buyer;
                  },
                  // 'templateOptions.roleName': () => 'buyer',
                  'templateOptions.seasonId': (viewValue: any, modelValue: any, scope: any) => {
                    return scope.model.season;
                  },
                  'templateOptions.dealType': (viewValue: any, modelValue: any, scope: any) => {
                    return scope.model.deal_type;
                  },
                },
              },
              {
                key: 'supplier',
                type: 'gt-ui-select',
                defaultValue:
                  request.potential_supplier ||
                  (request.contract_type === 'purchase'
                    ? undefined
                    : this.$rootScope.user.settings.DEFAULT_VALUES.owner),
                templateOptions: {
                  label:
                    request.contract_type === 'purchase'
                      ? this.gettext('Supplier')
                      : this.gettext('Supplier/Owner'),
                  addFunc:
                    request.contract_type === 'purchase'
                      ? () => this.ClientsService.roleModal({ model_name: 'Supplier' })
                      : () => this.ClientsService.roleModal({ model_name: 'Owner' }),
                  onSelect: () => {
                    if (request.contract_type === 'purchase' && !request.id) {
                      this.ClientsService.getPaymentConditions('Supplier', request.supplier).then(
                        (data: any) => {
                          request.payment_conditions = data.payment_conditions;
                          request.payment_conditions_auto = data.payment_conditions_auto;
                          request.payment_conditions_option = data.payment_conditions_option;
                        },
                      );

                      this.ClientsService.Supplier.get({ id: request.supplier }).$promise.then(
                        (supplierDetails: any) => {
                          request.supplierStatus = supplierDetails.approval_status;
                          return supplierDetails;
                        },
                      );
                    }
                  },
                  addIcon:
                    request.contract_type === 'purchase'
                      ? this.GtUtils.getIcon('clients.Supplier')
                      : this.GtUtils.getIcon('clients.Owner'),
                  addPerms: request.contract_type === 'purchase' ? ['add_supplier'] : ['add_owner'],
                },
                expressionProperties: {
                  'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                    isRequired(scope),
                  'templateOptions.resource': (viewValue: any, modelValue: any, scope: any) =>
                    this.ClientsService.getClientResource(
                      scope.options.key,
                      scope.model.contract_type,
                    ),
                },
              },
              {
                template: html`
                  <div style="margin: 8px 0" ng-if="!to.hide">
                    <cp-status-display
                      status="to.supplierStatus"
                    </cp-status-display>
                  </div>
                `,

                expressionProperties: {
                  'templateOptions.hide': () =>
                    !request.supplier || request.contract_type !== 'purchase',
                  'templateOptions.supplierStatus': (
                    viewValue: any,
                    modelValue: any,
                    scope: any,
                  ) => {
                    return scope.model.supplierStatus;
                  },
                },
                defaultValue: request.supplier
                  ? this.ClientsService.Supplier.get({ id: request.supplier }).$promise.then(
                      (supplierDetails: any) => {
                        request.supplierStatus = supplierDetails.approval_status;
                        return supplierDetails;
                      },
                    )
                  : null,
              },
              {
                template: html`
                  <div style="margin: 8px 0" ng-if="!to.hide">
                    <season-limit-display
                      role-id="to.roleId"
                      role-name="'supplier'"
                      season-id="to.seasonId"
                      deal-type="to.dealType"
                    </season-limit-display>
                  </div>
                `,
                expressionProperties: {
                  'templateOptions.hide': () =>
                    request.stage === 'indicator' || !request.supplier || !request.season,
                  'templateOptions.roleId': (viewValue: any, modelValue: any, scope: any) => {
                    return scope.model.supplier;
                  },
                  // 'templateOptions.roleName': () => 'buyer',
                  'templateOptions.seasonId': (viewValue: any, modelValue: any, scope: any) => {
                    return scope.model.season;
                  },
                  'templateOptions.dealType': (viewValue: any, modelValue: any, scope: any) => {
                    return scope.model.deal_type;
                  },
                },
              },
              {
                template: html`<div col-xs-12 pull-right">
                              <button ng-click="to.clear()"
                                class="clear-btn pull-right">
                                <span class="fa fa-remove"></span>
                              </button>
                              <ul class="ul-inline-row">
                                <li ng-repeat="role in to.roles">&nbsp
                                <input
                                type="checkbox"
                                ng-model="role.selected"
                                ng-change="to.onChange(role)"
                              />
                               {[{ role.title }]} </li>
                              </ul>
                          </div>
                          <div class="clearfix"></div>`,
                templateOptions: {
                  roles: [
                    { id: 'exporter', title: this.gettext('Exporter') },
                    { id: 'farm', title: this.gettext('Farm') },
                    { id: 'elevator', title: this.gettext('Elevator') },
                  ],
                  clear: () => delete request._supplier,
                  onChange: (role: any) => {
                    if (role.selected) {
                      if (
                        !confirm(
                          this.gettext(
                            'Do you want to create a supplier' +
                              ` with short name ${request._supplier.name} and role ${role.title}`,
                          ),
                        )
                      ) {
                        role.selected = false;
                      }
                      this.ClientsService.saveClient({
                        ...request._supplier,
                        ...this.ClientsService.getDefaultClientValue(),
                        role_names: ['supplier', role.id],
                      })
                        .then((counterparty: any) => {
                          notify(this.gettext('Counterparty created'));
                          request.supplier = counterparty.role_names_set
                            .filter((role: any) => role.role_name === 'supplier')
                            .map((role: any) => role.id)
                            .shift();
                        }, this.GtUtils.errorClb)
                        .finally(() => {
                          delete request._supplier;
                          role.selected = false;
                        });
                    }
                  },
                },

                hideExpression: (viewValue: any, modelValue: any, scope: any) =>
                  scope.model.stage === 'ticket' || scope.model.contract_type === 'sale'
                    ? true
                    : !scope.model._supplier || scope.model.supplier,
              },

              {
                key: 'potential_suppliers',
                type: 'gt-ui-multiselect',
                templateOptions: {
                  label: this.gettext('POTENTIAL SUPPLIERS'),
                  resource: 'clients.Supplier',
                  getQueryParams: () => {
                    return { suppliers: request.supplier };
                  },
                  addFunc: () => {
                    return this.ClientsService.roleModal({
                      model_name: 'Supplier',
                    });
                  },
                  addIcon: this.GtUtils.getIcon('clients.Supplier'),
                  addPerms: ['add_supplier'],
                },
                expressionProperties: {
                  'templateOptions.hide': () => request.contract_type === 'sale',
                },
              },
              {
                key: 'possible_suppliers_info',
                type: 'gt-textarea',
                templateOptions: {
                  label: this.gettext('Type new supplier'),
                },
                expressionProperties: {
                  'templateOptions.hide': () =>
                    request.contract_type === 'sale' || request.stage === 'indicator',
                },
              },
              {
                key: 'exporters',
                type: 'gt-ui-multiselect',
                templateOptions: {
                  label: this.gettext('EXPORTERS'),
                  resource: 'clients.Exporter',
                  getQueryParams: () => {
                    return { suppliers: request.supplier };
                  },
                  addFunc: () => {
                    return this.ClientsService.roleModal({
                      model_name: 'Exporter',
                    });
                  },
                  addIcon: this.GtUtils.getIcon('clients.Exporter'),
                  addPerms: ['add_exporter'],
                },
                expressionProperties: {
                  'templateOptions.hide': () => request.contract_type === 'sale',
                },
              },

              {
                key: 'season',
                type: 'gt-ui-select',
                defaultValueResolve: () => this.CoreService.getDefaultSeasonId(),
                templateOptions: {
                  label: this.gettext('Season'),
                  placeholder: this.gettext('Season'),
                  resource: 'core.Season',
                  queryParams: () => {
                    return { is_current: 1 };
                  },
                },
              },
              {
                key: 'business_unit',
                type: 'gt-ui-select',
                defaultValueResolve: () => this.CoreService.getDefaultBuId(request),
                templateOptions: {
                  label: this.gettext('Business unit'),
                  placeholder: this.gettext('Business Unit'),
                  queryParams: this.GtUtils.getBUQueryParams(),
                  resource: 'core.BusinessUnit',
                },
              },
              {
                key: 'responsible',
                type: 'gt-ui-select',
                defaultValue: this.$rootScope.user.id,
                templateOptions: {
                  label: this.gettext('Assigned to'),
                  placeholder: this.gettext('User to be responsible for the deal'),
                  resource: 'auth.User',
                  onSelect: () => {
                    this.AccountsService.User.get(request.responsible)
                      .then((userDetails) => {
                        request.responsibleWorkStatus = userDetails.profile.work_status;
                        return userDetails;
                      })
                      .catch(this.GtUtils.errorClb);
                  },
                },
              },
              {
                template: html`
                  <div style="margin: 8px 0">
                    <work-status-display
                      status="to.responsibleWorkStatus"
                    </work-status-display>
                  </div>
                `,
                hideExpression: () =>
                  !request.responsible || !this.$rootScope.user.settings.USE_WORK_STATUS,
                expressionProperties: {
                  'templateOptions.responsibleWorkStatus': (
                    viewValue: any,
                    modelValue: any,
                    scope: any,
                  ) => {
                    return scope.model.responsibleWorkStatus;
                  },
                },
                defaultValue: request.responsible
                  ? this.AccountsService.User.get(request.responsible).then((userDetails) => {
                      request.responsibleWorkStatus = userDetails.profile.work_status;
                      return userDetails;
                    })
                  : null,
              },
              {
                key: 'approval_config',
                type: 'gt-ui-select',
                defaultValueResolve: () =>
                  this.AccountsService.getDefaultApprovalConfigId(
                    this.getApprovalConfigModel(request),
                  ),
                templateOptions: {
                  label: this.gettext('Approval Config'),
                  resource: 'accounts.ApprovalConfig',
                  queryParams: () => ({
                    object_type_list: this.getApprovalContractModel(request),
                    is_active: 1,
                    bu_list: request.business_unit,
                  }),
                },
              },
              {
                key: 'quality_rule_template',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Quality rule Config'),
                  resource: 'crops.QualityRuleTemplate',
                  addFunc: () => {
                    this.$window.open('/admin/crops/qualityruletemplate/');
                  },
                  addIcon: 'fa fa-flask',
                  addPerms: true,
                },
                expressionProperties: {
                  'templateOptions.disabled': 'model.id',
                  'templateOptions.hide': () => request.contract_type === 'sale',
                },
              },
              {
                key: 'from_request',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Main ticket'),
                  resource: 'contracts.Request',
                  queryParams: () => {
                    return { stage: 'indicator' };
                  },
                },
              },
              {
                key: 'from_production',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('From production'),
                  resource: 'contracts.Request',
                  queryParams: () => {
                    return { stage: 'indicator' };
                  },
                },
              },
              {
                key: 'regional_managers',
                type: 'gt-ui-multiselect',
                templateOptions: {
                  label: this.gettext('Sales managers'),
                  resource: 'accounts.RegionalManager',
                },
                expressionProperties: {
                  'templateOptions.hide': () => request.contract_type === 'sale',
                },
              },
              {
                key: 'regional_manager_heads',
                type: 'gt-ui-multiselect',
                templateOptions: {
                  label: this.gettext('Heads of sale managers'),
                  resource: 'accounts.RegionalManager',
                },
                expressionProperties: {
                  'templateOptions.hide': () => request.contract_type === 'sale',
                },
              },

              {
                key: 'general_agreement',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('General Agreement'),
                  resource: 'contracts.GeneralAgreement',
                  addFunc: () => {
                    return this.ContractsService.generalAgreementsModal();
                  },
                  addIcon: 'fa fa-handshake-o',
                  addPerms: true,
                  queryParams: () => {
                    const queryParams: QueryParams = {
                      purchase: {
                        agreement_type: 'purchase',
                        buyer: request.buyer,
                        supplier: request.supplier,
                        potential_supplier_list: request.potential_suppliers,
                      } as any,
                      sale: {
                        agreement_type: 'sale',
                        buyer: request.buyer,
                        supplier: request.supplier,
                      } as any,
                    };

                    return queryParams[request.contract_type] as any;
                  },
                },
              },
              {
                key: 'request_status',
                type: 'gt-select',
                defaultValue: 'new',
                templateOptions: {
                  label: this.gettext('Status'),
                  placeholder: this.gettext('Choose status'),
                  hint: this.gettext('Status depends on current progress on request'),
                  valueProp: 'value',
                  labelProp: 'name',
                  options: [
                    { name: this.gettext('New'), value: 'new' },
                    { name: this.gettext('Approved'), value: 'approved' },
                    { name: this.gettext('Rejected'), value: 'rejected' },
                    { name: this.gettext('Cancelled'), value: 'cancelled' },
                    { name: this.gettext('Executed'), value: 'executed' },
                    { name: this.gettext('Processed'), value: 'processed' },
                    { name: this.gettext('Sent'), value: 'sent' },
                    { name: this.gettext('Lost'), value: 'lost' },
                    { name: this.gettext('Draft'), value: 'draft' },
                  ],
                  disabled: !this.AccountsService.hasPerm('approve_request'),
                },
              },
              {
                key: 'custom_status',
                type: 'gt-ui-select',
                templateOptions: {
                  label: this.gettext('Custom status'),
                  placeholder: this.gettext('Choose custom status'),
                  resource: 'core.CustomStatus',
                  hint: this.gettext('custom status'),
                  addFunc: () => {
                    this.$window.open('/admin/core/customstatus/add/');
                  },
                  addIcon: 'fa fa-tasks',
                  addPerms: true,
                  queryParams: {
                    content_type__model: 'contractbase',
                  },
                },
              },
              {
                key: 'deal_type',
                type: 'gt-select',
                defaultValue: 'spot',
                templateOptions: {
                  label: this.gettext('Deal type'),
                  placeholder: this.gettext('Choose type'),
                  hint: this.gettext('Spot or Forward'),
                  valueProp: 'value',
                  labelProp: 'name',
                  options: [
                    { name: this.gettext('Spot'), value: 'spot' },
                    { name: this.gettext('Forward'), value: 'forward' },
                  ],
                },
              },
              {
                key: 'ignore_limits',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Ignore limits '),
                  hint: this.gettext('Ignore counterparty limits'),
                },
                hideExpression: ($viewValue: any, $modelValue: any, scope: any) => {
                  return scope.$root.user.settings.CONTRACTS_LIMIT_ACTION == 'forbid';
                },
              },
              {
                key: 'is_lost',
                type: 'gt-checkbox',
                templateOptions: {
                  label: this.gettext('Is lost'),
                  hint: this.gettext('Is a ticket lost'),
                },
                expressionProperties: {
                  'templateOptions.hide': () => request.stage === 'indicator',
                },
              },
              {
                key: 'ignore_client_status',
                type: 'gt-checkbox',
                defaultValue:
                  this.$rootScope.user.settings.DEFAULT_VALUES.ignore_client_compliance_status,
                templateOptions: {
                  label: this.gettext('Ignore client status '),
                  hint: this.gettext('Ignore counterparty status'),
                  addPerms: ['can_ignore_client_compliance_status'],
                },
                expressionProperties: {
                  'templateOptions.hide': () =>
                    !this.AccountsService.hasPerm('can_ignore_client_compliance_status'),
                },
              },
              {
                key: 'type_of_activities',
                type: 'gt-ui-multiselect',
                templateOptions: {
                  label: this.gettext('Type of activities'),
                  resource: 'core.TypeOfActivity',
                  hint: this.gettext('If you want to add type or activity to this company'),
                  addFunc: () => this.$window.open('/admin/core/typeofactivity/add/'),
                  addPerms: true,
                  addIcon: 'fa fa-tasks',
                },
              },
              {
                key: 'sap_orders',
                type: 'gt-ui-multiselect',
                templateOptions: {
                  label: this.gettext('Sap Orders'),
                  resource: 'contracts.saporder',
                  addFunc: () => {
                    this.$window.open('/admin/contracts/saporder/add/');
                  },
                  addPerms: ['add_saporder'],
                },
              },
            ],
          },
        ],
      },
      {
        className: 'form-group-container col-xs-12 col-sm-3',
        fieldGroup: [],
      },
    ];

    fieldsDef[1].fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('PRODUCT'),
      },
      fieldGroup: [
        {
          key: 'cargo',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Product'),
            placeholder: this.gettext('Choose product'),
            required: request.deal_type !== 'services',
            resource: 'crops.Crop',
            addFunc: () => {
              this.$window.open('/admin/crops/crop/add/');
            },
            addIcon: this.GtUtils.getIcon('crops.Crop'),
            addPerms: ['add_crop'],
            onSelect: () => (request.commodity_opposite = request.cargo),
            queryParams: () =>
              request.general_agreement && this.$rootScope.user.settings.GENERAL_AGREEMENT_CARGO
                ? { general_agreement_list: request.general_agreement }
                : {},
          },
          validation: {
            show: true,
          },
          expressionProperties: {
            'templateOptions.disabled': () => isCargoFieldDisabled,
            'templateOptions.hide': () =>
              request._show_contractprice ||
              request.contract_prices?.length > 0 ||
              request.deal_type === 'services' ||
              request?.use_type === 'services',
          },
        },
        {
          key: 'charge',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Charge'),
            placeholder: this.gettext('Choose charge'),
            resource: 'finances.Charge',
            required: request.deal_type === 'services' || request?.use_type === 'services',
            title: request.charge_title,
            queryParams: () => {
              return {
                use_type_list:
                  request.charge_use_type_list || request.positions_charge_use_type_list,
              };
            },
          },
          expressionProperties: {
            'templateOptions.hide': () =>
              request.deal_type !== 'services' && request?.use_type !== 'services',
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'volume',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Volume'),
            placeholder: this.gettext('Type volume'),
            type: 'number',
            addon: this.gettext('t'),
          },
          expressionProperties: {
            'templateOptions.hide': () =>
              request._show_contractprice || request.contract_prices?.length > 0,
            'templateOptions.disabled': '!!model.quantity',
          },
        },
        {
          key: 'volume_min',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('volume min'),
            placeholder: this.gettext('Type volume min'),
            type: 'number',
            addon: this.gettext('t'),
          },
          expressionProperties: {
            'templateOptions.disabled': '!!model.quantity',
          },
        },
        {
          key: 'volume_max',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('volume max'),
            placeholder: this.gettext('Type volume max'),
            type: 'number',
            addon: this.gettext('t'),
          },
          expressionProperties: {
            'templateOptions.disabled': '!!model.quantity',
          },
        },
        {
          key: 'measurement',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('measurement'),
            placeholder: this.gettext('measurement'),
            resource: 'stockexchanges.measurement',
            addFunc: () => {
              this.$window.open('/admin/stockexchanges/measurement/add/');
            },
            addIcon: this.GtUtils.getIcon('stockexchanges.measurement'),
            addPerms: ['add_measurement'],
          },
        },
        {
          key: 'quantity',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('quantity of measurement'),
            placeholder: this.gettext('Type quantity'),
            type: 'number',
          },
          expressionProperties: {
            'templateOptions.hide': () => !request.measurement,
          },
        },
        {
          key: 'measurement_price',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Price for measurement'),
            placeholder: this.gettext('Type price'),
            type: 'number',
          },
          expressionProperties: {
            'templateOptions.hide': () => !request.measurement,
          },
        },
        {
          key: 'measurement_price_kg',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Price for measurement / kg'),
            placeholder: this.gettext('Type price'),
            type: 'number',
          },
          expressionProperties: {
            'templateOptions.hide': () => !request.measurement,
          },
        },
        {
          key: 'volume_options',
          type: 'gt-input',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.volume_options,
          templateOptions: {
            label: this.gettext('Tolerance'),
            placeholder: this.gettext('Type tolerance'),
            addon: this.gettext('0-100'),
            type: 'number',
          },
        },
        {
          key: 'volume_options_company',
          type: 'gt-select',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.volume_options_company,
          templateOptions: {
            label: this.gettext('Option'),
            placeholder: this.gettext('option'),
            hint: this.gettext('Choose who decide the option'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Buyer'), value: 'buyer' },
              { name: this.gettext('Seller'), value: 'seller' },
            ],
          },
        },
        {
          key: 'crop_year',
          type: 'gt-ui-select',
          defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.crop_year,
          templateOptions: {
            label: this.gettext('Crop Year'),
            placeholder: this.gettext('Crop Year'),
            resource: 'core.cropyear',
          },
        },
        {
          key: 'agent',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Agent'),
            resource: 'clients.Clientrole',
            addFunc: () => {
              return this.ClientsService.roleModal({ model_name: 'Broker' });
            },
            queryParams: () => {
              return { is_agent: 1 };
            },
            addIcon: this.GtUtils.getIcon('clients.Broker'),
            addPerms: ['add_broker'],
            title: request.agent_name,
          },
        },
        {
          key: 'broker',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Broker'),
            resource: 'clients.Broker',
            addFunc: () => {
              return this.ClientsService.roleModal({ model_name: 'Broker' });
            },
            addIcon: this.GtUtils.getIcon('clients.Broker'),
            addPerms: ['add_broker'],
          },
        },
        {
          key: 'broker_contacts',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Broker details'),
            resource: 'clients.Person',
            addFunc: () => {
              return this.ClientsService.personModal({ model_name: 'Broker' });
            },
            addIcon: this.GtUtils.getIcon('clients.Person'),
            addPerms: ['add_person'],
            getQueryParams: () => {
              return {
                is_verified: '1',
                companies_role_list: request.broker,
              };
            },
          },
        },
        {
          key: 'customs_broker',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Customs broker'),
            placeholder: this.gettext('Customs broker'),
            resource: 'clients.clientrole',
            queryParams: () => ({ role_name: 'customs_broker' }),
          },
        },
        {
          key: 'possible_brokers_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Type new broker'),
          },
          expressionProperties: {
            'templateOptions.hide': () => request.stage == 'indicator',
          },
        },

        {
          key: 'broker_cost_t',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Broker cost'),
            type: 'number',
            addon: this.gettext('per 1 mt'),
          },
          expressionProperties: {
            'templateOptions.disabled': '!!model.broker',
            'templateOptions.hide': () => Boolean(request.id),
          },
        },
        {
          key: 'surveyor',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Surveyor'),
            resource: 'clients.Surveyor',
            addFunc: () => {
              return this.ClientsService.roleModal({
                model_name: 'Surveyor',
              });
            },
            addIcon: this.GtUtils.getIcon('clients.Surveyor'),
            addPerms: ['add_surveyor'],
          },
        },
        {
          key: 'insurer',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Insurer'),
            resource: 'clients.Insurer',
            addFunc: () => {
              return this.ClientsService.roleModal({
                model_name: 'Insurer',
              });
            },
            addIcon: this.GtUtils.getIcon('clients.Insurer'),
            addPerms: ['add_insurer'],
          },
        },
        {
          key: 'bank',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Bank'),
            resource: 'clients.Bank',
            addFunc: () => {
              return this.ClientsService.roleModal({ model_name: 'Bank' });
            },
            addIcon: this.GtUtils.getIcon('clients.Bank'),
            addPerms: ['add_bank'],
          },
        },
        {
          key: 'consignee',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Consignee'),
            placeholder: this.gettext('Consignee'),
            resource: 'clients.clientrole',
            addFunc: () => {
              return this.ClientsService.roleModal({ model_name: 'Other' });
            },

            addIcon: this.GtUtils.getIcon('clients.Other'),
            addPerms: ['add_other'],
          },
        },
        {
          key: 'distributors',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Distributors'),
            resource: 'clients.Supplier',
            queryParams: {
              is_distributor: 1,
            },
            addFunc: () => {
              return this.ClientsService.roleModal({
                model_name: 'Supplier',
                is_distributor: 1,
              });
            },
            addIcon: this.GtUtils.getIcon('clients.Supplier'),
            addPerms: ['add_supplier'],
          },
        },
        {
          key: 'region',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Region'),
            addPerms: ['add_region'],
            queryParams: () => {
              return { country: request.origin_of_crop };
            },
            addFunc: () => this.$window.open('/admin/location/region/add/', '_blank'),
            addIcon: 'fa fa-location-arrow',
            resource: 'location.region',
          },
        },
        {
          key: 'district',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('District'),
            resource: 'location.District',
            addPerms: ['add_district'],
            placeholder: this.gettext('a district'),
            addFunc: () => this.$window.open('/admin/location/district/add/'),
            addIcon: 'fa fa-location-arrow',
          },
        },
        {
          key: 'additional_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Additional Info'),
            placeholder: this.gettext('Specific information about this object'),
            tooltip: request.additional_info,
            className: 'additional-info',
          },
        },
      ],
    });

    if (
      this.$rootScope.user.settings.CONTRACT_OPTION_MODEL &&
      request.contract_type == 'purchase'
    ) {
      this.ContractsService.getMainContractOptions().then((defaultMainOption: any) => {
        fieldsDef[1].fieldGroup.push({
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('LOYALITY PROGRAM'),
          },
          fieldGroup: [
            {
              key: 'contract_option',
              type: 'gt-ui-select',
              defaultValue: defaultMainOption.id,
              templateOptions: {
                label: this.gettext('Contract Option'),
                resource: 'contracts.ContractOption',
                addFunc: () => {
                  this.$window.open('/admin/contracts/contractoption/add/');
                },
                addPerms: ['add_contractoption'],
              },
            },
            {
              template: html`
                <loyality-program-data
                  obj="model"
                  date-from="model.estimated_date_execution || model.date_of_execution"
                  date-to="model.estimated_date_execution || model.end_of_execution"
                  bonus-percentage="model.premium_bonus_percentage"
                ></loyality-program-data>
              `,
              expressionProperties: {
                'templateOptions.hide': () => !request.contract_option,
              },
            },
          ],
        });
      });
    }

    if (request.stage === 'indicator') {
      fieldsDef[1].fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {},
        fieldGroup: [
          {
            template: html`
              <custom-values-container
                filter-level="'indicator-custom-values-container'"
                init-query-params="{
                  object_id: model.id,
                  purpose: model.content_type,
                  purpose_model: 'indicator',
                }"
                mode="model.id ? 'edit' : 'create'"
                object-id="model.id"
              ></custom-values-container>
            `,
          },
        ],
      });
    }

    if (request.stage === 'ticket') {
      fieldsDef[0].fieldGroup.push({
        wrapper: 'gt-panel',
        templateOptions: {},
        fieldGroup: [
          {
            template: html`
              <custom-values-container
                filter-level="'request-custom-values-container'"
                init-query-params="{
                  page_size: 1000000,
                  object_id: model.id,
                  purpose: model.content_type,
                  purpose_model: 'request',
                }"
                mode="model.id ? 'edit' : 'create'"
                object-id="model.id"
              ></custom-values-container>
            `,
          },
        ],
      });
    }

    fieldsDef.push({
      className: 'form-group-container col-xs-12 col-sm-3',
      fieldGroup: [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('PRICE'),
          },
          fieldGroup: [
            {
              key: '_show_contractprice',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Few prices'),
                hint: this.gettext('Check this if you to show basis-port-price widget'),
                onChange: () => {
                  if (!request._show_contractprice) {
                    request.contract_prices = [];
                  }
                },
              },
              expressionProperties: {
                'templateOptions.hide': () => request.stage === 'indicator',
              },
            },
            {
              key: 'contract_prices',
              type: 'gt-choice-list',
              templateOptions: {
                label: this.gettext('Price options'),
                contractType: request.contract_type,
                contractId: request?.id || -1,
                contractVolume: request?.volume || 0,
                contractCargo: request?.cargo,
                contractDealType: request.deal_type,
                fieldsConfig: priceWidgetFields,
                contractGeneralAgreement:
                  request.general_agreement && this.$rootScope.user.settings.GENERAL_AGREEMENT_CARGO
                    ? { general_agreement_list: request.general_agreement }
                    : null,
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  request.stage === 'indicator' ||
                  (!request._show_contractprice && request.contract_prices?.length == 0),
              },
            },
            {
              key: 'use_open_price',
              type: 'gt-checkbox',
              defaultValue: false,
              templateOptions: {
                label: this.gettext('Use open price'),
              },
            },
            {
              key: 'price',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Price'),
                placeholder: this.gettext('Price'),
                type: 'number',
                addon: this.gettext('per 1 mt'),
                required: true,
                expressionProperties: {
                  'templateOptions.hide': () => request.use_open_price,
                },
              },
            },
            {
              key: 'fixed_price_bonus',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Fixed price bonus'),
                placeholder: this.gettext('Fixed price bonus'),
                type: 'number',
                required: false,
                expressionProperties: {
                  'templateOptions.hide': () => !request.use_open_price,
                },
              },
            },
            {
              key: 'analytical_price',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('analytical price'),
                placeholder: this.gettext('analytical price'),
                type: 'number',
                addon: this.gettext('per 1 mt'),
              },
            },
            {
              key: 'indicator_price',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Indicator price'),
                placeholder: this.gettext('Indicator price'),
                type: 'number',
                addon: this.gettext('per 1 mt'),
                required: false,
                disabled: true,
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.indicator_price,
              },
            },
            {
              template: html`
                <div style="margin: 8px 0">
                  <div ng-if="model.contract_type === 'sale'">
                    <span
                      class="label"
                      ng-if="model.price > model.indicator_price"
                      ng-class="'label_success'"
                      style="margin: 0 30% 10px"
                    >
                      <translate>Ticket price exceeds indicator’s price</translate>
                    </span>
                    <span
                      class="label"
                      ng-if="model.price < model.indicator_price"
                      ng-class="'label_danger'"
                      style="margin: 0 30% 10px"
                    >
                      <translate>Ticket price is lower than indicator’s price</translate>
                    </span>
                  </div>

                  <div ng-if="model.contract_type === 'purchase'">
                    <span
                      class="label"
                      ng-if="model.price > model.indicator_price"
                      ng-class="'label_danger'"
                      style="margin: 0 30% 10px"
                    >
                      <translate>Ticket price exceeds indicator’s price</translate>
                    </span>
                    <span
                      class="label"
                      ng-if="model.price < model.indicator_price"
                      ng-class="'label_success'"
                      style="margin: 0 30% 10px"
                    >
                      <translate>Ticket price is lower than indicator’s price</translate>
                    </span>
                  </div>
                </div>
              `,
              expressionProperties: {
                'templateOptions.hide': () => !request.indicator_price,
              },
            },
            {
              key: 'price_indicative',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Price (with costs, without VAT)'),
                placeholder: this.gettext('Price indicative'),
                type: 'number',
                addon: this.gettext('per 1 mt'),
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.price_indicative,
              },
            },
            {
              key: 'price_indicative_with_vat',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Price (with costs, with VAT)'),
                type: 'number',
                addon: this.gettext('per 1 mt'),
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.price_indicative_with_vat,
              },
            },
            {
              key: 'price_indicative_default_currency',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Price (with costs, without VAT), $'),
                type: 'number',
                addon: this.gettext('per 1 mt'),
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.price_indicative_default_currency,
              },
            },
            {
              key: 'currency',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Currency'),
                placeholder: this.gettext('USD, UAH'),
                resource: 'finances.Currency',
                addFunc: () => {
                  this.$window.open('/admin/finances/currency/add/');
                },
                onSelect: () => (request.currency_exchange = null),
                addIcon: this.GtUtils.getIcon('finances.Currency'),
                addPerms: ['add_currency'],
                required: true,
              },
            },
            {
              key: 'currency_exchange',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Exchange rate'),
                placeholder: this.gettext('USD, UAH'),
                resource: 'finances.CurrencyExchange',
                addFunc: () => {
                  this.$window.open('/admin/finances/currencyexchange/add/');
                },
                queryParams: () => ({ local_currency: request.currency }),
                addIcon: this.GtUtils.getIcon('finances.CurrencyExchange'),
                addPerms: ['add_currencyexchange'],
              },
              expressionProperties: {
                'templateOptions.disabled': '!model.currency',
                'templateOptions.required': (viewValue: any, modelValue: any, scope: any) =>
                  isExchangeFieldRequired &&
                  scope.model.currency !== this.FinancesService.getDefaultCurrency().id,
              },
            },
            {
              key: 'VAT_option',
              type: 'gt-checkbox',
              defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.default_vat,
              templateOptions: {
                label: this.gettext('VAT'),
                onChange: () => {
                  if (request.VAT_option) {
                    request.VAT_value = this.$rootScope.user.settings.VAT_VALUE;
                  } else {
                    request.VAT_value = 0;
                  }
                },
              },
              expressionProperties: {
                'templateOptions.disabled': '!model.price',
              },
            },
            {
              key: 'VAT_value',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('VAT value'),
                placeholder: this.gettext('0-100'),
                type: 'number',
                addon: this.gettext('%'),
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.VAT_option,
              },
            },
            {
              key: 'vat_return_rate',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('vat return rate'),
                placeholder: this.gettext('0-100'),
                type: 'number',
                addon: this.gettext('%'),
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.VAT_option,
              },
            },
            {
              key: '_priceWoVat',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Price without VAT'),
                type: 'number',
                disabled: true,
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.VAT_option,
              },
            },
            {
              key: '_priceVatValue',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Price VAT'),
                type: 'number',
                disabled: true,
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.VAT_option,
              },
            },
            {
              key: 'calculated_amount_with_vat',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Amount'),
                placeholder: this.gettext('Amount'),
                type: 'number',
                disabled: true,
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.VAT_option,
              },
            },
            {
              key: 'basis',
              type: 'gt-ui-select',
              defaultValue:
                request.contract_type === 'purchase'
                  ? this.$rootScope.user.settings.DEFAULT_VALUES.basis_purchase
                  : this.$rootScope.user.settings.DEFAULT_VALUES.basis_sale,
              templateOptions: {
                label: this.gettext('Basis'),
                placeholder: this.gettext('Choose option'),
                resource: 'logistics.Basis',
                addFunc: () => {
                  this.$window.open('/admin/logistics/basis/add/');
                },
                addIcon: 'fa fa-anchor',
                addPerms: ['add_basis'],
              },
              expressionProperties: {
                'templateOptions.hide': () => Boolean(request._show_contractprice),
              },
            },
            {
              key: 'ports',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('PORTS'),
                resource: 'logistics.port',
                hint: this.gettext('You can pick up multiple objects, clicking with CTRL'),
                addFunc: () => {
                  this.$window.open('/admin/logistics/port/add/', '_blank');
                },
                addIcon: this.GtUtils.getIcon('logistics.Port'),
                addPerms: ['add_port'],
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  Boolean(request._show_contractprice) && request.stage === 'ticket',
              },
            },
            {
              key: 'origin_of_crop',
              type: 'gt-ui-select',
              defaultValueResolve: () => this.GtUtils.getDefaultOriginId(),
              templateOptions: {
                label: this.gettext('Origin'),
                placeholder: this.gettext('Type origin'),
                resource: 'location.Country',
                hint: this.gettext('From which country purchased'),
                addon: this.gettext('country'),
                onSelect: () => {
                  this.GtUtils.getResource('logistics.basis')
                    .get({ id: request.basis })
                    .$promise.then((basis: any) => {
                      if (['FOB', 'EXW', 'FCA'].includes(basis.codename)) {
                        request.destination_of_crop = request.origin_of_crop;
                      }
                    });
                },
                addFunc: () => {
                  this.$window.open('/admin/location/country/add/', '_blank');
                },
                addIcon: 'fa fa-location-arrow',
                addPerms: ['add_country'],
              },
            },
            {
              key: 'origins_of_crop',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Origin'),
                resource: 'location.Country',
                addon: this.gettext('country'),
                addFunc: () => {
                  this.$window.open('/admin/location/country/add/', '_blank');
                },
                addIcon: 'fa fa-location-arrow',
                addPerms: ['add_country'],
              },
            },
            {
              key: 'destination_of_crop',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Destination'),
                placeholder: this.gettext('Type destination'),
                resource: 'location.Country',
                hint: this.gettext('To which country delivered'),
                addon: this.gettext('country'),
                addFunc: () => {
                  this.$window.open('/admin/location/country/add/', '_blank');
                },
                addIcon: 'fa fa-location-arrow',
                addPerms: ['add_country'],
              },
              expressionProperties: {
                'templateOptions.required': (_$viewValue: any, _$modelValue: any, scope: any) => {
                  if (
                    this.$rootScope.user.settings.DESTINATION_CONTRACT_REQUIRED &&
                    scope.model.contract_type === 'sale' &&
                    scope.model.basis
                  ) {
                    return this.GtUtils.getResource('logistics.basis')
                      .get({ id: scope.model.basis })
                      .$promise.then(
                        (basis: any) =>
                          (scope.to.required = ['CIF', 'FOB', 'CFR'].includes(basis.codename)),
                      );
                  } else {
                    return false;
                  }
                },
              },
            },
            {
              key: 'destinations_of_crop',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Destinations'),
                resource: 'location.Country',
                hint: this.gettext('To which country delivered'),
                addon: this.gettext('country'),
                addFunc: () => {
                  this.$window.open('/admin/location/country/add/', '_blank');
                },
                addIcon: 'fa fa-location-arrow',
                addPerms: ['add_country'],
              },
            },
            {
              key: '_showMtmFields',
              type: 'gt-checkbox',
              templateOptions: {
                label: this.gettext('Opposite details'),
                placeholder: this.gettext('Opposite details'),
              },
            },
            {
              key: 'price_opposite',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Opposite price'),
                placeholder: this.gettext('Opposite price'),
                type: 'number',
                addon: this.gettext('per 1 mt'),
                required: false,
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  Boolean(request.derivative_opposite) ||
                  (!request._showMtmFields && !request.price_opposite),
              },
            },

            {
              key: 'currency_opposite',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Currency opposite'),
                placeholder: this.gettext('USD, UAH'),
                resource: 'finances.Currency',
              },
              expressionProperties: {
                'templateOptions.hide': () => !request._showMtmFields && !request.currency_opposite,
              },
            },
            {
              key: 'price_premium',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Premium price'),
                placeholder: this.gettext('Premium price'),
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.derivative,
              },
            },
            {
              key: 'derivative',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Derivative'),
                placeholder: this.gettext('derivative'),
                resource: 'stockexchanges.Derivative',
                addFunc: () => {
                  this.$window.open('/admin/stockexchanges/derivative/add/');
                },
                queryParams: () => {
                  return { currency: request.currency };
                },
                addIcon: this.GtUtils.getIcon('stockexchanges.Derivative'),
                addPerms: ['add_derivative'],
              },
            },
            {
              key: 'price_premium_opposite',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Premium price opposite'),
                placeholder: this.gettext('Premium price opposite'),
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.derivative_opposite,
              },
            },
            {
              key: 'derivative_opposite',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Opposite derivative'),
                placeholder: this.gettext('derivative'),
                resource: 'stockexchanges.Derivative',
                addFunc: () => {
                  this.$window.open('/admin/stockexchanges/derivative/add/');
                },
                queryParams: () => {
                  return { currency: request.currency_opposite };
                },
                addIcon: this.GtUtils.getIcon('stockexchanges.Derivative'),
                addPerms: ['add_derivative'],
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  Boolean(request.price_opposite) ||
                  (!request._showMtmFields && !request.derivative_opposite),
              },
            },
            {
              key: 'estimated_pnl',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('Estimated P&L'),
              },
            },
            {
              key: 'basis_opposite',
              type: 'gt-ui-select',
              defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.basis_purchase,
              templateOptions: {
                label: this.gettext('Opposite basis'),
                placeholder: this.gettext('Choose option'),
                resource: 'logistics.Basis',
                addFunc: () => {
                  this.$window.open('/admin/logistics/basis/add/');
                },
                addIcon: this.GtUtils.getIcon('logistics.Basis'),
                addPerms: ['add_basis'],
              },
              expressionProperties: {
                'templateOptions.hide': () => !request._showMtmFields && !request.basis_opposite,
              },
            },
            {
              key: 'counterparty_opposite',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Opposite counterparties'),
                placeholder: this.gettext('Opposite counterparties'),
                resource: 'clients.Client',
                required: false,
                queryParams: () => {
                  if (request.contract_type === 'sale') {
                    return { role_name: 'supplier' };
                  } else {
                    return { role_name: 'buyer' };
                  }
                },
                addFunc: () => {
                  this.$window.open('/admin/clients/client/add/');
                },
                addIcon: this.GtUtils.getIcon('clients.Client'),
                addPerms: ['add_client'],
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  !request._showMtmFields && !request.counterparty_opposite,
              },
            },
            {
              key: 'ports_opposite',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Ports opposite'),
                resource: 'logistics.port',
                hint: this.gettext('You can pick up multiple objects, clicking with CTRL'),
                addFunc: () => {
                  this.$window.open('/admin/logistics/port/add/', '_blank');
                },
                addIcon: this.GtUtils.getIcon('logistics.Port'),
                addPerms: ['add_port'],
              },
              expressionProperties: {
                'templateOptions.hide': () => !request._showMtmFields && !request.ports_opposite,
              },
            },
            {
              key: 'market_zone',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('market zone'),
                placeholder: this.gettext('Port'),
                resource: 'logistics.Port',
              },
              expressionProperties: {
                'templateOptions.hide': () => !request._showMtmFields && !request.market_zone,
              },
            },
            {
              key: 'commodity_opposite',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Commodity opposite'),
                placeholder: this.gettext('Choose commodity'),
                resource: 'crops.Crop',
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  !request._showMtmFields && !request.commodity_opposite,
              },
            },

            {
              key: 'date_of_execution_opposite',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('date of execution opposite'),
                placeholder: this.gettext('date'),
                useWatch: true,
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  !request._showMtmFields && !request.date_of_execution_opposite,
              },
            },
            {
              key: 'calc_tax_profit_to_pay',
              type: 'gt-checkbox',
              defaultValue: true,
              templateOptions: {
                label: this.gettext('Calculate tax profit to pay'),
              },
            },
            {
              key: 'prices_auto',
              type: 'gt-checkbox',
              defaultValue: true,
              templateOptions: {
                label: this.gettext('Calculate auto tariff'),
              },
            },
            {
              key: 'is_position_closing',
              type: 'gt-checkbox',
              defaultValue: false,
              templateOptions: {
                label: this.gettext('Closing of existing position'),
              },
              expressionProperties: {
                'templateOptions.hide': () => request.stage !== 'ticket',
              },
            },
            {
              key: 'prices_transshipment',
              type: 'gt-checkbox',
              defaultValue: true,
              templateOptions: {
                label: this.gettext('Calculate elevator costs'),
              },
            },
            {
              key: 'prices_railway',
              type: 'gt-checkbox',
              defaultValue: true,
              templateOptions: {
                label: this.gettext('Calculate railway tariff'),
              },
            },
          ],
        },
      ],
    });

    fieldsDef.push({
      className: 'form-group-container col-xs-12 col-sm-3',
      fieldGroup: [
        {
          wrapper: 'gt-panel',
          templateOptions: {
            label: this.gettext('EXECUTION'),
            defaultHide: true,
          },
          fieldGroup: [
            {
              key: 'delivery_condition',
              type: 'gt-ui-select',
              defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.delivery_condition,
              templateOptions: {
                label: this.gettext('Delivery conditions'),
                placeholder: this.gettext('Choose option'),
                resource: 'logistics.DeliveryCondition',
                addFunc: () => {
                  this.$window.open('/admin/logistics/deliverycondition/add/');
                },
                addIcon: this.GtUtils.getIcon('logistics.DeliveryCondition'),
                addPerms: ['add_deliverycondition'],
              },
            },
            {
              key: 'delivery_conditions',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Delivery conditions'),
                placeholder: this.gettext('Choose option'),
                resource: 'logistics.DeliveryCondition',
                addFunc: () => {
                  this.$window.open('/admin/logistics/deliverycondition/add/');
                },
                addIcon: this.GtUtils.getIcon('logistics.DeliveryCondition'),
                addPerms: ['add_deliverycondition'],
              },
            },
            {
              key: 'deliverer',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Forwarder'),
                resource: 'clients.Deliverer',
                addFunc: () => {
                  return this.ClientsService.roleModal({
                    model_name: 'Deliverer',
                  });
                },
                addIcon: this.GtUtils.getIcon('clients.Deliverer'),
                addPerms: ['add_deliverer'],
              },
              expressionProperties: {
                'templateOptions.hide': () => request.contract_type === 'sale',
              },
            },
            {
              key: 'warehouse',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Warehouse'),
                resource: 'logistics.warehouse',
                queryParams: { is_group: '0' },
              },
            },
            {
              key: 'warehouses',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Warehouses'),
                resource: 'logistics.warehouse',
                queryParams: { is_group: '0' },
              },
            },
            {
              key: 'text_loading_address',
              type: 'gt-textarea',
              defaultValueResolve: () =>
                this.LocationService.getAddressListByIds(request.loading_addresses),
              templateOptions: {
                label: this.gettext('Loading address'),
                placeholder: this.gettext('Loading address'),
                onBlurFunc: (address: any) => {
                  if (!address) {
                    return false;
                  }
                  return this.LocationService.Address.query({
                    city_district: address,
                    address_types: 'Loading',
                  }).$promise.then((data: any) => {
                    if (data.count) {
                      request.loading_addresses = data.results.map((address: any) => address.id);
                      return request.loading_addresses;
                    }
                    return this.LocationService.Address.save({
                      city_district: address.trim(),
                      address_types: ['Loading'],
                    }).$promise.then((data: any) => {
                      request.loading_addresses = [data.id];
                    });
                  });
                },
              },
            },
            {
              key: 'loading_addresses',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Loading addresses'),
                resource: 'location.address',
                required: false,
                addFunc: () => {
                  this.$window.open('/admin/location/address/add/');
                },
                addIcon: 'fa-location-arrow',
                addPerms: ['add_station'],
                queryParams: { address_type: 'Loading', is_actual: '1' },
              },
            },
            {
              key: 'delivery_addresses',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Delivery addresses'),
                resource: 'location.address',
                required: false,
                addFunc: () => {
                  this.$window.open('/admin/location/address/add/');
                },
                addIcon: 'fa-location-arrow',
                addPerms: ['add_station'],
                queryParams: { address_type: 'Delivery', is_actual: '1' },
              },
            },
            {
              key: 'weighing_address',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Weighing addresses'),
                resource: 'location.address',
                required: false,
                addFunc: () => {
                  this.$window.open('/admin/location/address/add/');
                },
                addIcon: 'fa-location-arrow',
                addPerms: ['add_station'],
                queryParams: { address_type: 'Weighing', is_actual: '1' },
              },
            },
            {
              key: 'rerouting_address',
              type: 'gt-ui-multiselect',
              templateOptions: {
                label: this.gettext('Rerouting addresses'),
                resource: 'location.address',
                required: false,
                addFunc: () => {
                  this.$window.open('/admin/location/address/add/');
                },
                addIcon: 'fa-location-arrow',
                addPerms: ['add_station'],
                queryParams: { address_type: 'Rerouting', is_actual: '1' },
              },
            },
            {
              key: 'vehicle_restrictions',
              type: 'gt-textarea',
              templateOptions: {
                label: this.gettext('Vehicle restrictions'),
                placeholder: this.gettext('Vehicle restrictions'),
              },
            },
            {
              key: 'station',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Station departure'),
                placeholder: this.gettext('Station'),
                resource: 'logistics.Station',
                addFunc: () => {
                  this.$window.open('/admin/logistics/station/add/');
                },
                addIcon: this.GtUtils.getIcon('logistics.Station'),
                addPerms: ['add_station'],
              },
            },
            {
              key: 'station_receiving',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Station arrival'),
                placeholder: this.gettext('Station'),
                resource: 'logistics.Station',
                addFunc: () => {
                  this.$window.open('/admin/logistics/station/add/');
                },
                addIcon: this.GtUtils.getIcon('logistics.Station'),
                addPerms: ['add_station'],
              },
            },
            {
              key: 'freight_estimated',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Freight'),
                placeholder: this.gettext('Freight'),
                type: 'number',
                addon: this.gettext('per 1 mt'),
                required: false,
              },
            },
            {
              key: 'ship_classification',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Ship classification'),
                placeholder: this.gettext('Choose ship classification'),
                resource: 'logistics.shipclassification',
                addFunc: () => {
                  this.$window.open('/admin/logistics/shipclassification/add/');
                },
                addPerms: ['add_shipclassification'],
              },
            },
            {
              key: 'terminal',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Terminal'),
                placeholder: this.gettext('Terminal'),
                resource: 'logistics.Terminal',
                addFunc: () => {
                  this.$window.open('/admin/logistics/terminal/add/');
                },
                addIcon: this.GtUtils.getIcon('logistics.Terminal'),
                addPerms: ['add_terminal'],
                queryParams: {
                  is_actual: '1',
                },
              },
            },
            {
              key: 'shipment_terminal',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Shipment Terminal'),
                placeholder: this.gettext('Shipment Terminal'),
                resource: 'logistics.Terminal',
                addFunc: () => {
                  this.$window.open('/admin/logistics/terminal/add/');
                },
                addIcon: this.GtUtils.getIcon('logistics.Terminal'),
                addPerms: ['add_terminal'],
                queryParams: {
                  is_actual: '1',
                },
              },
            },

            {
              key: 'payment_conditions',
              type: 'gt-input',
              defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.payment_conditions,
              templateOptions: {
                label: this.gettext('RW payment conditions'),
                placeholder: this.gettext('0-100'),
                hint: this.gettext('Pick from 0 to 100 to set up prepay'),
                type: 'number',
                addon: this.gettext('%'),
              },
            },
            {
              key: 'payment_conditions_auto',
              type: 'gt-input',
              defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.payment_conditions_auto,
              templateOptions: {
                label: this.gettext('Auto payment conditions'),
                placeholder: this.gettext('0-100'),
                hint: this.gettext('Pick from 0 to 100 to set up prepay'),
                type: 'number',
                addon: this.gettext('%'),
              },
            },
            {
              key: 'payment_conditions_option',
              type: 'gt-ui-select',
              defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.payment_conditions_descr,
              templateOptions: {
                label: this.gettext('Payment conditions option'),
                placeholder: this.gettext('Choose option'),
                resource: 'finances.PaymentCondition',
                addFunc: () => {
                  this.$window.open('/admin/finances/paymentcondition/');
                },
                addIcon: this.GtUtils.getIcon('finances.PaymentCondition'),
                onSelect: () => {
                  this.GtUtils.getResource('finances.paymentcondition')
                    .get({ id: request.payment_conditions_option })
                    .$promise.then((paymentConditionsOption: any) => {
                      request.payment_days = paymentConditionsOption.days;
                    });
                },
                queryParams: () => {
                  return { is_active: 1 };
                },
              },
            },
            {
              key: 'prepay_payment',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Prepay payment'),
                placeholder: this.gettext('Choose option'),
                resource: 'finances.BalancePayment',
                addIcon: this.GtUtils.getIcon('finances.PaymentCondition'),
                queryParams: { invoice_type_list: ['prepay', 'all'] },
              },
            },
            {
              key: 'pre_advice',
              type: 'gt-input',
              defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.default_preadvice_days,
              templateOptions: {
                label: this.gettext('pre advice'),
                placeholder: this.gettext('Type days'),
                type: 'number',
              },
            },
            {
              key: 'balance_payment',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('Balance payment'),
                placeholder: this.gettext('Choose option'),
                resource: 'finances.BalancePayment',
                addIcon: this.GtUtils.getIcon('finances.PaymentCondition'),
                queryParams: { invoice_type_list: ['balance', 'all'] },
              },
            },
            {
              key: 'crop_protection',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Crop protection'),
                required: false,
                type: 'number',
              },
            },
            {
              key: 'seeds',
              type: 'gt-input',
              defaultValue: 0,
              templateOptions: {
                label: this.gettext('Seeds'),
                required: false,
                type: 'number',
              },
            },

            {
              key: 'loan_percentage',
              type: 'gt-input',
              defaultValue: 100,
              templateOptions: {
                label: this.gettext('financing - loan percentage'),
                placeholder: this.gettext('0-100'),
                hint: this.gettext('Pick from 0 to 100 to set up loan percentage'),
                addon: this.gettext('%'),
                type: 'number',
                addIcon: 'fa fa-percent',
              },
              validators: {
                notFalse: ($viewValue: any, $modelValue: any) => {
                  return (
                    parseFloat($viewValue || $modelValue || 0) >= 0 &&
                    parseFloat($viewValue || $modelValue || 0) <= 100
                  );
                },
              },
            },
            {
              key: 'interest_rate',
              type: 'gt-input',
              defaultValue: this.$rootScope.user.settings.DEFAULT_VALUES.interest_rate,
              templateOptions: {
                label: this.gettext('financing - interest rate'),
                placeholder: this.gettext('0-100'),
                hint: this.gettext('Pick from 0 to 100 to set up interest rate'),
                addon: this.gettext('%'),
                type: 'number',
                addIcon: 'fa fa-percent',
              },
              validators: {
                notFalse: ($viewValue: any, $modelValue: any) => {
                  return (
                    parseFloat($viewValue || $modelValue || 0) >= 0 &&
                    parseFloat($viewValue || $modelValue || 0) <= 100
                  );
                },
              },
            },
            {
              key: 'estimated_opposite_payment_date',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('financing - estimated opposite payment date'),
                useWatch: true,
                placeholder: this.gettext('date'),
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  !request._showMtmFields && !request.estimated_opposite_payment_date,
              },
            },
            {
              key: 'payment_days',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('financing - payment days'),
                placeholder: this.gettext('payment days'),
                type: 'number',
              },
            },

            {
              key: 'estimated_date_execution',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Delivery estimated month'),
                startView: 'month',
                minView: 'month',
              },
            },
            {
              key: 'estimated_date_execution_part_of_month',
              type: 'gt-select',
              templateOptions: {
                label: this.gettext('Delivery estimated part of month'),
                placeholder: this.gettext('Choose part of month'),
                hint: this.gettext('Choose option'),
                valueProp: 'value',
                labelProp: 'name',
                options: [
                  { name: this.gettext('1st half'), value: 'first' },
                  { name: this.gettext('2nd half'), value: 'second' },
                ],
              },
            },
            {
              key: 'date_of_execution',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Delivery (start date)'),
                placeholder: this.gettext('date'),
                onSelected: ($event: any) => {
                  const date = $event?.date;
                  if (request.end_of_execution || !date) {
                    return false;
                  }
                  if (
                    this.$rootScope.user.settings.DEFAULT_VALUES.default_execution_duration != 30
                  ) {
                    request.end_of_execution = new Date(
                      date.getFullYear(),
                      date.getMonth(),
                      date.getDate() +
                        this.$rootScope.user.settings.DEFAULT_VALUES.default_execution_duration,
                      0,
                    );
                  } else {
                    request.end_of_execution = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                  }
                },
              },

              expressionProperties: {
                'templateOptions.hide': () => Boolean(request._show_contractprice),
                'templateOptions.required': (_viewValue: any, _modelValue: any, scope: any) => {
                  if (scope.options.templateOptions._required === undefined) {
                    scope.options.templateOptions._required =
                      scope.options.templateOptions.required;
                  }
                  return scope.options.hide ? false : scope.options.templateOptions._required;
                },
              },
            },
            {
              key: 'end_of_execution',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('Delivery (end date)'),
                placeholder: this.gettext('date'),
                useWatch: true,
                onSelected: ($event: any) => {
                  const date = $event?.date;
                  if (request.date_of_execution_opposite || !date) {
                    return false;
                  }
                  request.date_of_execution_opposite = request.end_of_execution;
                  request.estimated_opposite_payment_date =
                    request.estimated_opposite_payment_date || request.end_of_execution;
                },
              },
              expressionProperties: {
                'templateOptions.hide': () => Boolean(request._show_contractprice),
                'templateOptions.required': (_viewValue: any, _modelValue: any, scope: any) => {
                  if (scope.options.templateOptions._required === undefined) {
                    scope.options.templateOptions._required =
                      scope.options.templateOptions.required;
                  }
                  return scope.options.hide ? false : scope.options.templateOptions._required;
                },
              },
            },
            {
              key: 'expiration_start',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: this.gettext('Expiration start'),
                placeholder: this.gettext('Pick a time'),
                className: 'datetimepicker',
              },
            },
            {
              key: 'expiration_end',
              type: 'gt-date-select',
              templateOptions: {
                minView: 'minute',
                label: this.gettext('Expiration end'),
                placeholder: this.gettext('Pick a time'),
                className: 'datetimepicker',
              },
            },
            {
              key: 'arrival_period_start',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('arrival period start'),
                placeholder: this.gettext('date'),
                useWatch: true,
              },
            },
            {
              key: 'arrival_period_end',
              type: 'gt-date-select',
              templateOptions: {
                label: this.gettext('arrival period end'),
                placeholder: this.gettext('date'),
                useWatch: true,
              },
            },
            {
              key: 'loadport',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('loadport'),
                placeholder: this.gettext('loadport'),
                resource: 'logistics.Port',
              },
            },
            {
              key: 'disport',
              type: 'gt-ui-select',
              templateOptions: {
                label: this.gettext('disport'),
                placeholder: this.gettext('disport'),
                resource: 'logistics.Port',
              },
            },
            {
              key: 'load_rate',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('load rate'),
                placeholder: this.gettext('load rate'),
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.required': ($viewValue: any, $modelValue: any, scope: any) => {
                  if (
                    this.$rootScope.user.settings.DESTINATION_CONTRACT_REQUIRED &&
                    scope.model.basis
                  ) {
                    return this.GtUtils.getResource('logistics.basis')
                      .get({ id: scope.model.basis })
                      .$promise.then(
                        (basis: any) => (scope.to.required = ['FOB'].includes(basis.codename)),
                      );
                  } else {
                    return false;
                  }
                },
              },
            },
            {
              key: 'discharge_rate',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('discharge rate'),
                placeholder: this.gettext('discharge rate'),
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.required': (_$viewValue: any, _$modelValue: any, scope: any) => {
                  if (
                    this.$rootScope.user.settings.DESTINATION_CONTRACT_REQUIRED &&
                    scope.model.basis
                  ) {
                    return this.GtUtils.getResource('logistics.basis')
                      .get({ id: scope.model.basis })
                      .$promise.then(
                        (basis: any) =>
                          (scope.to.required = ['CIF', 'CFR'].includes(basis.codename)),
                      );
                  } else {
                    return false;
                  }
                },
              },
            },
            {
              key: 'demmurage_rate',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('demurrage rate'),
                placeholder: this.gettext('demurrage rate'),
                type: 'number',
                addon: this.gettext('$/day'),
              },
            },
            {
              key: 'dispatch_rate',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('dispatch rate'),
                placeholder: this.gettext('dispatch rate'),
                type: 'number',
                addon: this.gettext('$/day'),
              },
            },

            {
              key: 'implied_freight_level',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('implied freight level'),
                placeholder: this.gettext('implied freight level'),
                type: 'number',
              },
            },
            {
              key: 'opposite_freight_level',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('opposite freight level'),
                placeholder: this.gettext('opposite freight level'),
                type: 'number',
              },
              expressionProperties: {
                'templateOptions.hide': () =>
                  !request._showMtmFields && !request.opposite_freight_level,
              },
            },
            {
              key: 'is_extension',
              type: 'gt-checkbox',
              defaultValue: true,
              templateOptions: {
                label: this.gettext('Is extension'),
              },
            },
            {
              key: 'counterparty_approval_status',
              type: 'gt-input',
              templateOptions: {
                label: this.gettext('CP approval status'),
                hint: this.gettext('CP approval status'),
                required: false,
                disabled: true,
              },
              expressionProperties: {
                'templateOptions.hide': () => !request.counterparty_approval_status,
              },
            },
          ],
        },
      ],
    });

    return {
      formName: formName,
      fieldsDef: fieldsDef,
    };
  }
}
RequestFormFieldsService.$inject = [
  '$rootScope',
  '$window',
  'gettext',
  'GtUtils',
  'CoreService',
  'ClientsService',
  'AccountsService',
  'ContractsService',
  'LocationService',
  'FormFieldParamsService',
  'FinancesService',
];
