import React from 'react';

import { MinusIcon } from '~/shared/ui/icons';
import { Checkbox } from '~/shared/ui/kit/checkbox';

import { CellRender } from './cell-render';
import { HeaderRender } from './header-render';
import type { ColumnDef } from './types';
import type { DataParams, DataRecord, DatasetArgs } from '../../types';

export const buildColumnDefs = <R extends DataRecord, P extends DataParams>({
  fields,
  selectable,
}: Pick<DatasetArgs<R, P>, 'fields' | 'selectable'>) => {
  const columns: ColumnDef<R>[] = fields.map((field) => {
    return {
      id: `id_${field.key.toString()}`,
      accessorKey: !field.key.toString().startsWith('_') ? String(field.key) : 'id',
      header: () => HeaderRender({ title: field.title ?? field.key.toString(), writable: false }),
      size: field.size,
      enablePinning: field.sticky,
      enableResizing: true,
      meta: { field },
      cell: CellRender,
    };
  });

  if (selectable) {
    columns.unshift({
      id: '_selectRowCheckbox',
      size: 24,
      enablePinning: true,
      header: ({ table }) => (
        <Checkbox
          size="small"
          checked={table.getIsAllRowsSelected()}
          onClick={table.getToggleAllRowsSelectedHandler()}
          icon={
            table.getIsSomeRowsSelected() && (
              <MinusIcon size={16} strokeWidth={2} className="text-background-main-primary pt-1" />
            )
          }
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          size="small"
          checked={row.getIsSelected()}
          onClick={row.getToggleSelectedHandler()}
        />
      ),
      meta: {
        field: {
          key: '_selectRowCheckbox',
          dataType: 'template',
        },
      },
    });
  }

  return columns;
};
