import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import type {
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
  PnlLogisticRecord,
} from '~/features/execution/logistic/common';
import { createComponent } from '~/shared/lib/components';

import type { PnlLogisticListContainerProps } from './pnl-logistic-list.model';
import { PnlLogisticListModel } from './pnl-logistic-list.model';
import type { PnlLogisticRepository } from '../services';

const PnlLogisticContainerView: React.FC<
  ContainerViewProps<PnlLogisticListModel, PnlLogisticListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      PnlLogisticListModel,
      Logistic,
      LogisticQueryParams,
      PnlLogisticRepository,
      PnlLogisticListContainerProps,
      PnlLogisticRecord,
      LogisticPost,
      LogisticPatch
    >
      model={model}
    />
  );
};

export const PnlLogisticContainer = createComponent(PnlLogisticContainerView, PnlLogisticListModel);
