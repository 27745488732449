import React from 'react';

import { useTranslation } from '~/shared/lib/i18n';
import { cn } from '~/shared/lib/utils';
import { TextFieldWrite } from '~/shared/ui/data-types';
import { CloseEyeIcon, DragIcon, EyeIcon, ShieldExclamationIcon } from '~/shared/ui/icons';
import { Tooltip } from '~/shared/ui/kit/tooltip';
import { CSS, useSortable } from '~/shared/ui/lib/dnd';

import { getConfigParamsTitle } from '../../helpers/configs';
import type { ColumnConfigParams } from '../../types';

export const ParamsDndListItem = ({
  params,
  loading,
  toggleVisibility,
  toggleRequirement,
  onChange,
}: {
  params: ColumnConfigParams;
  loading: boolean;
  toggleVisibility: (params: ColumnConfigParams) => void;
  toggleRequirement: (params: ColumnConfigParams) => void;
  onChange: (params: ColumnConfigParams) => void;
}) => {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: params.id,
  });
  const toggleVisibilityHandler = React.useCallback(
    () => toggleVisibility(params),
    [params, toggleVisibility],
  );
  const toggleRequirementHandler = React.useCallback(
    () => toggleRequirement(params),
    [params, toggleRequirement],
  );
  const onTitleChangeHandler = React.useCallback(
    (new_title?: string) => onChange({ ...params, new_title }),
    [onChange, params],
  );
  const title = React.useMemo(() => getConfigParamsTitle(params), [params]);

  const requiredBtnClasses = React.useMemo(() => {
    if (params.requiredByDefault) {
      return 'text-text-additional-danger opacity-50';
    }
    return params.required
      ? 'text-text-additional-danger hover:shadow-sm'
      : 'text-text-main-secondary hover:shadow-sm hover:text-text-main-primary';
  }, [params.required, params.requiredByDefault]);

  const style = React.useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
    }),
    [transform, transition],
  );

  return (
    <span
      ref={setNodeRef}
      style={style}
      className={cn(
        'text-text-main-secondary ml-2 flex w-full items-center',
        loading ? 'pointer-events-none animate-pulse' : '',
      )}
    >
      <div {...attributes} {...listeners} className="mb-1 cursor-grab">
        <DragIcon />
      </div>
      <div className="min-w-0 flex-1 [&_input]:px-1">
        <TextFieldWrite
          value={title}
          onChange={onTitleChangeHandler}
          typeExtra={{ inputProps: { placeholder: params.column_name } }}
        />
      </div>
      <div className="ml-2 flex items-center gap-2 pr-7">
        <Tooltip content={t('Required')}>
          <button
            disabled={params.requiredByDefault}
            onClick={toggleRequirementHandler}
            className={cn('mr-2 rounded-sm', requiredBtnClasses)}
          >
            <ShieldExclamationIcon />
          </button>
        </Tooltip>
        <Tooltip content={t('Visibility')}>
          <button
            onClick={toggleVisibilityHandler}
            className="hover:text-text-main-primary rounded-sm hover:shadow-sm"
          >
            {params.visible ? <EyeIcon /> : <CloseEyeIcon />}
          </button>
        </Tooltip>
      </div>
    </span>
  );
};
