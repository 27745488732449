import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import type {
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
  PurchaseLogisticRecord,
} from '~/features/execution/logistic/common';
import { createComponent } from '~/shared/lib/components';

import type { PurchaseLogisticListContainerProps } from './purchase-logistic-list.model';
import { PurchaseLogisticListModel } from './purchase-logistic-list.model';
import type { PurchaseLogisticRepository } from '../services';

const PurchaseLogisticContainerView: React.FC<
  ContainerViewProps<PurchaseLogisticListModel, PurchaseLogisticListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      PurchaseLogisticListModel,
      Logistic,
      LogisticQueryParams,
      PurchaseLogisticRepository,
      PurchaseLogisticListContainerProps,
      PurchaseLogisticRecord,
      LogisticPost,
      LogisticPatch
    >
      model={model}
    />
  );
};

export const PurchaseLogisticContainer = createComponent(
  PurchaseLogisticContainerView,
  PurchaseLogisticListModel,
);
