import type { DatasetId, FieldDef } from '~/core/datasets';
import { ComponentModel } from '~/shared/lib/components';
import type { InjectionToken } from '~/shared/lib/di';
import { container } from '~/shared/lib/di';
import type { EntityRepository } from '~/shared/lib/entities';
import type {
  Entity,
  EntityQueryParams,
  ErrorResponse,
  Patched,
  RecordErrors,
} from '~/shared/lib/entities/types';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { BehaviorSubject, shareReplay, switchMap, tap } from '~/shared/lib/state';
import { tryCatch } from '~/shared/lib/utils';

import type { ContainerProps } from './types';

/**
 * Abstract class representing a container model for managing entity lists.
 *
 * @template TEntity - The type of the entity being managed.
 * @template TParams - The type of the query parameters used for fetching entities.
 * @template TRepo - The type of the repository used for entity operations.
 * @template TProps - The type of the container properties.
 * @template TEntityRecord - The type of the entity record, defaults to `TEntity`.
 * @template TEntityPost - The type of the entity used for creating new records, defaults to `TEntity`.
 * @template TEntityPatch - The type of the entity used for updating records, defaults to a patched version of `TEntityPost`.
 */
export abstract class EntityListContainerModel<
  TEntity extends Entity,
  TParams extends EntityQueryParams,
  TRepo extends EntityRepository<TEntity, TParams, TEntityRecord, TEntityPost, TEntityPatch>,
  TProps extends ContainerProps,
  TEntityRecord extends Entity = TEntity,
  TEntityPost extends Entity = TEntity,
  TEntityPatch extends Entity = Patched<TEntityPost>,
> extends ComponentModel<TProps> {
  private readonly editingModeSubj = new BehaviorSubject<boolean>(true);

  protected abstract readonly entityRepoClass: InjectionToken<TRepo>;

  private repoObj?: TRepo;

  get repo() {
    this.repoObj ??= container.resolve(this.entityRepoClass);
    return this.repoObj;
  }
  protected getInitParams() {
    return { page_size: 25, page: 1 } as TParams;
  }

  protected readonly pageParamsSubj = new BehaviorSubject<TParams>(this.getInitParams());
  protected readonly loadingSubj = new BehaviorSubject(false);

  readonly pageData$ = this.pageParamsSubj.pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap((params) => this.repo.query(params)),
    tap(() => this.loadingSubj.next(false)),
    shareReplay({ bufferSize: 1, refCount: false }),
  );
  readonly pageParams$ = this.pageParamsSubj.asObservable();
  readonly loading$ = this.loadingSubj.asObservable();

  get datasetId(): DatasetId {
    return `${this.repo.entityName}:${this.props.containerId}`;
  }

  pageParamsChanged = (params: TParams, replace?: boolean) => {
    this.pageParamsSubj.next(replace ? params : { ...this.pageParamsSubj.value, ...params });
  };

  pageParamsUpdated = (params: TParams) => {
    this.pageParamsSubj.next(params);
  };

  loadingChanged = (loading: boolean) => {
    this.loadingSubj.next(loading);
  };

  createNewRecord = () => Promise.resolve(this.repo.buildNewRecord());

  readonly editingMode$ = this.editingModeSubj.asObservable();

  setEditing = (editing: boolean) => {
    this.editingModeSubj.next(editing);
  };

  deleteRecords = (records: TEntityRecord[]) => {
    Promise.all(records.map((rec) => this.repo.delete(rec.id)))
      .catch(errorHandler)
      .finally(() => {
        notifySuccess('Records deleted');
        this.pageParamsChanged(this.pageParamsSubj.value);
      });
  };

  beforeSaveRecords = async (args: Parameters<typeof this.saveRecords>[0]) => Promise.resolve(args);

  saveRecords = async (records: TEntityRecord[]) => {
    this.loadingChanged(true);
    records = await this.beforeSaveRecords(records);

    const errors: Record<number, RecordErrors> = {};
    for (const record of records) {
      const { error } = await tryCatch<ErrorResponse>(
        record.id > 0
          ? this.repo.update(this.repo.recordToPatch(record))
          : this.repo.create(this.repo.recordToPost(record)),
      );
      if (error) {
        errors[record.id] = Object.entries(error).reduce((acc, [key, value]) => {
          acc[key] = { type: 'danger', text: value.join(', ') };
          return acc;
        }, {} as RecordErrors);
      }
    }

    const errorsCount = Object.keys(errors).length;
    if (errorsCount) {
      errorHandler(`${errorsCount} errors`);
    }
    this.loadingChanged(false);
    return errorsCount ? errors : undefined;
  };

  getFields = (): Promise<FieldDef<TEntityRecord>[]> => {
    console.error('Method getFields not implemented: Container Model', this.repo.entityName);
    return Promise.resolve([]);
  };
}
