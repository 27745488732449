import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import { createComponent } from '~/shared/lib/components';

import type { UserListContainerProps } from './user-list.model';
import { UserListModel } from './user-list.model';
import type { User, UserPatch, UserPost, UserQueryParams } from '../../lib/types';
import type { UserRepository } from '../../services/user.repository';

const UserListContainerView: React.FC<
  ContainerViewProps<UserListModel, UserListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      UserListModel,
      User,
      UserQueryParams,
      UserRepository,
      UserListContainerProps,
      User,
      UserPost,
      UserPatch
    >
      model={model}
    />
  );
};

export const UserListContainer = createComponent(UserListContainerView, UserListModel);
