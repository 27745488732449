import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import type {
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
  QualitiesLogisticRecord,
} from '~/features/execution/logistic/common';
import { createComponent } from '~/shared/lib/components';

import type { QualitiesLogisticListContainerProps } from './qualities-logistic-list.model';
import { QualitiesLogisticListModel } from './qualities-logistic-list.model';
import type { BaseLogisticListContainerProps } from '../../common/components';
import type { QualitiesLogisticRepository } from '../services';

const QualitiesLogisticContainerView: React.FC<
  ContainerViewProps<QualitiesLogisticListModel, QualitiesLogisticListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      QualitiesLogisticListModel,
      Logistic,
      LogisticQueryParams,
      QualitiesLogisticRepository,
      BaseLogisticListContainerProps,
      QualitiesLogisticRecord,
      LogisticPost,
      LogisticPatch
    >
      model={model}
    />
  );
};

export const QualitiesLogisticContainer = createComponent(
  QualitiesLogisticContainerView,
  QualitiesLogisticListModel,
);
