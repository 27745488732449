import React from 'react';

import type { ComponentViewProps } from '~/shared/lib/components';
import { createComponent } from '~/shared/lib/components';
import { Async } from '~/shared/lib/state';
import { Pagination } from '~/shared/ui/components/pagination';
import { ProgressBar } from '~/shared/ui/kit/progress-bar';

import { DatasetModelContext } from '../../hooks/models';
import { DatasetModel } from '../../services/dataset.model';
import type { DataParams, DataRecord, DatasetViewProps, ViewMode, ViewProps } from '../../types';
import { FiltersBar } from '../filters-bar';
import { TableView } from '../table-view';
import { ViewConfigurator } from '../view-configurator/view-configurator';

const DatasetView = <R extends DataRecord, P extends DataParams>(
  props: ComponentViewProps<DatasetModel<R, P>, DatasetViewProps<R, P>>,
) => {
  const ViewComponent = React.useMemo(() => {
    const viewVariants: Record<ViewMode, React.FC<ViewProps<R, P>>> = {
      grid: TableView,
      table: TableView,
    };
    return viewVariants[props.view ?? 'grid'];
  }, [props.view]);

  return (
    <section className="mt-2 !flex flex-col gap-3">
      <DatasetModelContext.Provider value={props.model}>
        <ViewConfigurator model={props.model.view} />
        <FiltersBar />
        <Async args={{ loading: props.loading$ }}>
          {({ loading }) => (loading ? <ProgressBar /> : null)}
        </Async>
        <ViewComponent
          editing$={props.editing$}
          loading$={props.loading$}
          selectable={props.selectable}
        />

        <Async args={{ params: props.pageParams$, data: props.data$ }}>
          {({ params, data }) => (
            <Pagination
              pageCurrent={params.page}
              pageSize={params.page_size}
              recordsTotal={data.count}
              currentPageChanged={(page) => props.model.changePageParams({ params: { page } as P })}
              pageSizeChanged={(pageSize) =>
                props.model.changePageParams({ params: { page_size: pageSize } as P })
              }
              pageSizeOptions={[25]}
            />
          )}
        </Async>
      </DatasetModelContext.Provider>
    </section>
  );
};

export const Dataset = createComponent(DatasetView as never, DatasetModel) as <
  R extends DataRecord,
  P extends DataParams,
>(
  props: DatasetViewProps<R, P>,
) => React.ReactNode;
