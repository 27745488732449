import { type HeaderGroup as HeaderGroupDef, flexRender } from '@tanstack/react-table';
import React from 'react';

import { cn } from '~/shared/lib/utils';

import { getStickyStyles } from './helpers';
import type { DataRecord } from '../../types';

export const HeaderGroup = <R extends DataRecord>({
  headerGroup,
}: {
  headerGroup: HeaderGroupDef<R>;
}) => {
  return (
    <div className="flex min-h-9 w-full">
      {headerGroup.headers.map((header) => {
        return (
          <div
            key={header.id}
            style={{
              width: header.getSize(),
              ...getStickyStyles({
                getStart: header.column.getStart,
                getSize: header.column.getSize,
                sticky: header.column.columnDef.enablePinning,
                isHeader: true,
              }),
            }}
          >
            <div
              className={cn(
                'border-stroke-main-light !bg-background-main-primary flex h-full w-full items-center justify-between border-r border-b',
                header.column.columnDef.id === '_selectRowCheckbox' && 'border-r-0 pl-1',
              )}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getCanResize() && (
                <button
                  type="button"
                  onMouseDown={header.getResizeHandler()}
                  onTouchStart={header.getResizeHandler()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      header.getResizeHandler();
                    }
                  }}
                  className={cn(
                    'absolute top-0 right-0 h-full w-[3px] !cursor-col-resize touch-none select-none',
                    header.column.getIsResizing() && 'bg-gray-300',
                  )}
                  aria-label="Resize column"
                ></button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
