import type ng from 'angular';

import template from './season-limit-display.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export class SeasonLimitDisplay implements ng.IController {
  roleId?: number;
  roleName = 'buyer';
  seasonId?: number;
  dealType = 'spot';
  seasonLimits: any;

  static readonly $inject = ['GtUtils', 'ContractsService'];

  constructor(
    private readonly GtUtils: GtUtilsService,
    private readonly ContractsService: ContractsService,
  ) {}

  $onInit() {
    this.updateRoleLimits();
  }

  $onChanges(changes: any) {
    if (changes.roleId || changes.seasonId) {
      this.updateRoleLimits();
    }
  }

  updateRoleLimits() {
    if (!this.roleId || !this.seasonId) {
      return;
    }
    this.GtUtils.overlay('show');
    return this.ContractsService.getRoleLimits(this.roleName, this.roleId, this.seasonId)
      .then((data: any) => {
        this.seasonLimits = data;
      }, this.GtUtils.errorClb)
      .finally(() => this.GtUtils.overlay('hide'));
  }
}

export const seasonLimitDisplay = {
  bindings: {
    roleId: '<?',
    roleName: '<',
    seasonId: '<?',
    dealType: '<',
  },
  template,
  controller: SeasonLimitDisplay,
};
