import {
  coreColumnsSetConfigsCreate,
  coreColumnsSetConfigsDestroy,
  coreColumnsSetConfigsList,
  coreColumnsSetConfigsPartialUpdate,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { genId } from '~/shared/lib/utils';
import { EntityOption } from '~/shared/ui/data-types';

import {
  ColumnsSetConfig,
  ColumnsSetConfigPatch,
  ColumnsSetConfigPost,
  ColumnsSetConfigQueryParams,
} from '../types';

@singleton()
export class ColumnsSetConfigRepository extends EntityRepository<
  ColumnsSetConfig,
  ColumnsSetConfigQueryParams,
  ColumnsSetConfig,
  ColumnsSetConfigPost,
  ColumnsSetConfigPatch
> {
  readonly entityName = 'core.ColumnsSetConfig';

  getFn = (_id: number): Promise<ColumnsSetConfig> => {
    throw new Error('method not implemented');
  };
  queryFn = async (query: ColumnsSetConfigQueryParams) => {
    const { data } = await coreColumnsSetConfigsList({ query });
    return {
      records: data.results,
      count: data.count,
    };
  };
  searchFn = (_input: string, _id?: number): Promise<EntityOption[]> => {
    throw new Error('method not implemented');
  };
  createFn = async (entity: ColumnsSetConfigPost) => {
    const { data } = await coreColumnsSetConfigsCreate({ body: entity });
    return data;
  };
  updateFn = async (entity: ColumnsSetConfigPatch) => {
    const { data } = await coreColumnsSetConfigsPartialUpdate({
      body: entity,
      path: { id: entity.id },
    });
    return data;
  };
  deleteFn = async (id: number) => {
    await coreColumnsSetConfigsDestroy({ path: { id } });
  };

  buildNewRecord = (): ColumnsSetConfig => ({
    id: -genId(),
    update_time: null,
    create_time: null,
    config_level: '',
    title: '',
    editor: null,
  });
}
