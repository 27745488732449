import React from 'react';

import type { DatasetViewModel } from '../services/dataset-view.model';
import type { DatasetModel } from '../services/dataset.model';
import type { DataParams, DataRecord } from '../types';

export const DatasetModelContext = React.createContext<unknown>(null);

export const useDatasetModel = <R extends DataRecord, P extends DataParams>() => {
  const model = React.useContext(DatasetModelContext);

  if (!model) {
    throw new Error('useDatasetModel must be used within a DatasetModelProvider');
  }

  return model as DatasetModel<R, P>;
};

export const DatasetViewModelContext = React.createContext<unknown>(null);

export const useDatasetViewModel = <R extends DataRecord>() => {
  const model = React.useContext(DatasetViewModelContext);

  if (!model) {
    throw new Error('useViewDatasetModel must be used within a DatasetModelProvider');
  }

  return model as DatasetViewModel<R>;
};
