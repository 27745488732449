import { AuthStore } from '~/core/auth/services';
import { ContainerProps, EntityListContainerModel } from '~/core/entity-lists';
import { ApprovalRepository } from '~/features/common/approval';
import {
  Counterparty,
  CounterpartyPatch,
  CounterpartyPost,
  CounterpartyQueryParams,
  CounterpartyRecord,
} from '~/features/crm/counterparties/lib';
import {
  CounterpartiesRepository,
  CounterpartiesService,
} from '~/features/crm/counterparties/services';
import { container, injectable } from '~/shared/lib/di';
import { errorHandler } from '~/shared/lib/errors';
import { notifySuccess } from '~/shared/lib/notify';
import { lastValueFrom, take } from '~/shared/lib/state';

import { getCounterpartyFields } from '../../lib/fields.config';

export type CounterpartiesContainerProps = ContainerProps<CounterpartyQueryParams> & {
  legacyHandlers: object;
};

@injectable()
export class CounterpartiesContainerModel extends EntityListContainerModel<
  Counterparty,
  CounterpartyQueryParams,
  CounterpartiesRepository,
  CounterpartiesContainerProps,
  CounterpartyRecord,
  CounterpartyPost,
  CounterpartyPatch
> {
  entityRepoClass = CounterpartiesRepository;

  private readonly approvalsRepo = container.resolve(ApprovalRepository);
  private readonly authStore = container.resolve(AuthStore);
  private readonly counterpartiesService = container.resolve(CounterpartiesService);

  getFields = () => {
    return getCounterpartyFields({
      ...this.props.legacyHandlers,
      reactivateApprovals: this.reactivateApprovals,
      approve: this.approve,
      decline: this.decline,
    });
  };

  updateRegularStatus = async () => {
    try {
      await this.repo.updateRegularStatus();
      notifySuccess('Status updated');
    } catch (err) {
      errorHandler(err);
    } finally {
      this.pageParamsChanged({});
    }
  };

  reactivateApprovals = async (counterparties: CounterpartyRecord[]) => {
    try {
      await Promise.all(
        counterparties.map(async (counterparty) => {
          await this.approvalsRepo.reactivateByConfig({
            approvableId: counterparty.id,
            approvableContentTypeId: counterparty.content_type,
          });
        }),
      );
      notifySuccess('Reactivated approvals');
      this.pageParamsChanged({});
    } catch (err) {
      errorHandler(err);
    }
  };

  approve = async (records: CounterpartyRecord[]) => {
    try {
      const user = await lastValueFrom(this.authStore.currentUser$.pipe(take(1)));
      await this.counterpartiesService.approveRecordsForUser(records, user.id);

      notifySuccess('Approved');
      this.pageParamsChanged({});
    } catch (err) {
      errorHandler(err);
    }
  };

  decline = async (records: CounterpartyRecord[]) => {
    try {
      const user = await lastValueFrom(this.authStore.currentUser$.pipe(take(1)));
      await this.counterpartiesService.declineRecordsForUser(records, user.id);

      notifySuccess('Declined');
      this.pageParamsChanged({});
    } catch (err) {
      errorHandler(err);
    }
  };
}
