import { Preset, SortingOption } from '~/core/page-filters';
import { injectable } from '~/shared/lib/di';
import { EntityQueryParams } from '~/shared/lib/entities';
import { notifySuccess, notifyWarn } from '~/shared/lib/notify';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilKeyChanged,
  filter,
  from,
  lastValueFrom,
  map,
  switchMap,
  take,
  tap,
} from '~/shared/lib/state';
import { askUser } from '~/shared/ui/lib/ask-user';

import { QueryParamsSetRepository } from './query-params-set.repository';
import {
  filterDefToFilterOption,
  presetDefToPreset,
  presetToQueryParams,
  presetToQueryParamsSet,
  queryParamsSetToPreset,
} from '../helpers/filters';
import { FilterDef, PresetDef } from '../types';

type DatasetFiltersModelConfig<P extends EntityQueryParams> = {
  datasetId: string;
  sortingOptions: SortingOption[];
  filterDefs: FilterDef<P>[];
  onParamsChange: (params: P, replace?: boolean) => void;
  presetDefs?: PresetDef[];
};

@injectable()
export class DatasetFiltersModel<P extends EntityQueryParams> {
  private readonly configSubj = new BehaviorSubject<DatasetFiltersModelConfig<P> | null>(null);
  private readonly presetsUpdatedSubj = new BehaviorSubject<void>(undefined);
  private readonly config$ = this.configSubj.pipe(filter(Boolean));

  private readonly filterDefs$ = this.config$.pipe(
    distinctUntilKeyChanged('filterDefs'),
    map(({ filterDefs }) => filterDefs),
  );

  constructor(private readonly queryParamsSetRepo: QueryParamsSetRepository) {}

  filterDefsByArgument$ = this.filterDefs$.pipe(
    map((defs) =>
      defs.reduce<Record<string, FilterDef<P>>>(
        (acc, filter) => ({ ...acc, [filter.argument]: filter }),
        {},
      ),
    ),
  );

  filtersOptions$ = this.filterDefs$.pipe(map((defs) => defs.map(filterDefToFilterOption)));
  sortingOptions$ = this.config$.pipe(
    distinctUntilKeyChanged('sortingOptions'),
    map(({ sortingOptions }) => sortingOptions),
  );
  presetOptions$ = combineLatest({
    config: this.config$,
    filterDefMap: this.filterDefsByArgument$,
    presetsUpdated: this.presetsUpdatedSubj,
  }).pipe(
    map(({ config, filterDefMap }) => ({
      filterLevel: config.datasetId,
      presets: config.presetDefs?.map((def) => presetDefToPreset(def, config.datasetId)) ?? [],
      filterDefMap,
    })),
    switchMap(({ presets, filterLevel, filterDefMap }) =>
      this.queryParamsSetRepo
        .query({ page_size: 1000, filter_level: filterLevel })
        .then(({ records }) => [
          ...presets,
          ...records.map((param) => queryParamsSetToPreset(param, filterDefMap)),
        ]),
    ),
  );

  setConfig(config: DatasetFiltersModelConfig<P>) {
    if (this.configSubj.value) {
      console.warn('Config already set');
      return;
    }
    this.configSubj.next(config);
  }

  presetChanged = (preset: Preset) => {
    this.filterDefsByArgument$
      .pipe(
        take(1),
        map((defs) => presetToQueryParams(preset, defs)),
        tap((params) => this.configSubj.value?.onParamsChange(params, true)),
      )
      .subscribe();
  };

  savePreset = (preset: Preset) => {
    const result$ = combineLatest([this.filterDefsByArgument$, this.config$]).pipe(
      take(1),
      switchMap(([defs, conf]) =>
        this.queryParamsSetRepo.saveEntity(
          presetToQueryParamsSet({ ...preset, datasetId: conf.datasetId }, defs),
        ),
      ),
      tap(() => notifySuccess('Preset saved')),
      map(() => this.presetsUpdatedSubj.next()),
    );
    return lastValueFrom(result$);
  };

  deletePreset = (preset: Preset) => {
    if (preset.id < 0) {
      return Promise.resolve(notifyWarn('Cannot delete unsaved preset'));
    }
    return lastValueFrom(
      from(askUser()).pipe(
        take(1),
        filter(Boolean),
        switchMap(() => this.queryParamsSetRepo.delete(preset.id)),
        map(() => notifySuccess('Preset deleted')),
        tap(() => this.presetsUpdatedSubj.next()),
      ),
    );
  };
}
