import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { CustomValuesService } from '^/app/common/custom-fields/custom-values.service';
import type { CoreService } from '^/app/core/core.service';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';
import { html } from '^/shared/utils';

export class LogisticFormFieldsService {
  $rootScope: GtRootScopeService;
  $window: ng.IWindowService;
  AccountsService: AccountsService;
  ClientsService: any;
  ContractsService: ContractsService;
  CoreService: CoreService;
  CustomValuesService: CustomValuesService;
  FinancesService: FinancesService;
  GtUtils: GtUtilsService;
  gettext: ng.gettext.gettextFunction;
  constructor(
    $rootScope: GtRootScopeService,
    $window: ng.IWindowService,
    gettext: ng.gettext.gettextFunction,
    GtUtils: GtUtilsService,
    CoreService: CoreService,
    AccountsService: AccountsService,
    FinancesService: FinancesService,
    ContractsService: ContractsService,
    ClientsService: any,
    CustomValuesService: CustomValuesService,
  ) {
    this.$rootScope = $rootScope;
    this.$window = $window;
    this.gettext = gettext;
    this.GtUtils = GtUtils;
    this.CoreService = CoreService;
    this.AccountsService = AccountsService;
    this.FinancesService = FinancesService;
    this.ContractsService = ContractsService;
    this.ClientsService = ClientsService;
    this.CustomValuesService = CustomValuesService;
  }

  getDefaultLossesCharge(method?: string) {
    method =
      method ??
      this.AccountsService.user?.settings.DEFAULT_VALUES.default_losses_selfcost_accounting_method;
    if (method === 'create_default_losses_costs') {
      return this.AccountsService.user?.settings.DEFAULT_VALUES.logistics_losses_charge;
    }
  }
  getFields(logistic: any) {
    const s = this;
    const col1: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col2: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col3: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };
    const col4: any = {
      className: 'form-group-container col-sm-3 col-xs-12',
      fieldGroup: [],
    };

    col1.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('VEHICLE'),
      },
      fieldGroup: [
        {
          key: 'logistic_type',
          type: 'gt-select',
          defaultValue: 'main',
          templateOptions: {
            label: this.gettext('Logistic types'),
            placeholder: this.gettext('Choose type'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Main'), value: 'main' },
              { name: this.gettext('Intermediate'), value: 'intermediate' },
            ],
          },
        },
        {
          key: 'vehicle_type',
          type: 'gt-select',
          defaultValue: 'car',
          templateOptions: {
            label: this.gettext('Vehicle types'),
            placeholder: this.gettext('Choose type'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Car'), value: 'car' },
              { name: this.gettext('Wagon'), value: 'wagon' },
              { name: this.gettext('Container'), value: 'container' },
              { name: this.gettext('Vessel'), value: 'vessel' },
            ],
          },
        },
        {
          key: 'vehicle_subtype',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('subtype'),
            placeholder: this.gettext('Choose vehicle subtype'),
            resource: 'logistics.VehicleSubtype',
          },
        },
        {
          key: 'vehicle_number',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Vehicle (number)'),
            placeholder: this.gettext('Vehicle (number)'),
            required: true,
          },
          validation: { show: true },
        },
        {
          key: 'vehicle_number_alt',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Vehicle (number) alt'),
            placeholder: this.gettext('Vehicle (number) alt'),
          },
          validation: { show: true },
        },
        {
          key: 'auto_name',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Generate'),
            placeholder: this.gettext('Generate'),
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) => Boolean(scope.model.id),
        },
        {
          key: 'consignment_number',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Consignment (#)'),
            placeholder: this.gettext('Consignment (#)'),
            addon: this.gettext('#'),
          },
          validation: { show: true },
        },
        {
          key: 'voyage',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Voyage'),
            placeholder: this.gettext('Voyage'),
            resource: 'logistics.Voyage',
          },
        },
        {
          key: 'driver',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Driver'),
            placeholder: this.gettext('Driver'),
            resource: 'logistics.driver',
            addFunc: () => {
              this.$window.open('/admin/logistics/driver/add/');
            },
            onSelect: () => {
              this.GtUtils.getResource('logistics.driver')
                .get({ id: logistic.driver })
                .$promise.then((driver: any) => {
                  const currentDate = new Date();
                  let timeDelta = Math.round(
                    (new Date(driver.license_expire_date).getTime() - currentDate.getTime()) /
                      (1000 * 3600 * 24),
                  );
                  if (timeDelta < 60) {
                    notify(
                      this.gettext('Driving licence closure date less than two months'),
                      'warning',
                    );
                  }
                  timeDelta = Math.round(
                    (new Date(driver.passport_expire_date).getTime() - currentDate.getTime()) /
                      (1000 * 3600 * 24),
                  );
                  if (timeDelta < 60) {
                    notify(
                      this.gettext('Closing date of passport less than two months'),
                      'warning',
                    );
                  }

                  logistic.driver_first_name = driver.first_name;
                  logistic.driver_last_name = driver.last_name;
                  logistic.driver_middle_name = driver.middle_name;
                  logistic.drivers_license = driver.license_number;
                  logistic.driver_passport = driver.passport_number;
                  logistic.driver_phone = driver.phone;
                });
            },
            addPerms: true,
          },
        },
        {
          key: 'vehicle',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Vehicle'),
            placeholder: this.gettext('Vehicle'),
            resource: 'logistics.vehicle',
            addFunc: () => {
              this.$window.open('/admin/logistics/vehicle/add/');
            },
            onSelect: () => {
              this.GtUtils.getResource('logistics.vehicle')
                .get({ id: logistic.vehicle })
                .$promise.then((vehicle: any) => {
                  logistic.vehicle_number = vehicle.number;
                  logistic.vehicle_type = vehicle.vehicle_type;
                  logistic.vehicle_number_alt = vehicle.number_alt;
                });
            },
            addPerms: true,
            queryParams: {
              vehicle_type: 'car',
            },
          },
        },
        {
          key: 'trailer',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Trailer'),
            placeholder: this.gettext('Trailer'),
            resource: 'logistics.vehicle',
            addFunc: () => {
              this.$window.open('/admin/logistics/vehicle/add/');
            },
            addPerms: true,
            queryParams: {
              vehicle_type: 'trailer',
            },
          },
        },
        {
          key: 'approval_status',
          type: 'gt-select',
          defaultValue: 'wait',
          templateOptions: {
            label: this.gettext('Approval status'),
            hint: this.gettext('Will be changed automatically based on configuration'),
            options: [
              { title: this.gettext('Waiting for approval'), id: 'wait' },
              { title: this.gettext('Approved'), id: 'approved' },
              { title: this.gettext('Approval in process'), id: 'process' },
              { title: this.gettext('Rejected'), id: 'rejected' },
            ],
            disabled: !this.AccountsService.hasPerm('edit_approval_status'),
          },
        },
        {
          key: 'custom_status',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Custom status'),
            placeholder: this.gettext('Choose custom status'),
            resource: 'core.CustomStatus',
            hint: this.gettext('custom status'),
            addFunc: () => {
              this.$window.open('/admin/core/customstatus/add/');
            },
            addIcon: 'fa fa-tasks',
            addPerms: true,
            queryParams: {
              content_type__model: 'logistic',
            },
          },
        },
        {
          key: 'cargo',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Commodity'),
            placeholder: this.gettext('Choose commodity'),
            resource: 'crops.Crop',
            addFunc: () => {
              this.$window.open('/admin/crops/crop/add/');
            },
            addIcon: this.GtUtils.getIcon('crops.Crop'),
            addPerms: ['add_crop'],
          },
        },
        {
          key: 'receiver',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Receiver'),
            placeholder: this.gettext('Choose receiver'),
            resource: 'clients.Client',
            queryParams: { is_receiver: '1' },
          },
        },
        {
          key: 'producer',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Producer'),
            placeholder: this.gettext('Choose producer'),
            resource: 'clients.Client',
            queryParams: { is_producer: '1' },
          },
        },
        {
          key: 'season',
          type: 'gt-select',
          defaultValue: this.AccountsService.user?.settings.LOGISTICS_CURRENT_SEASON,
          templateOptions: {
            label: this.gettext('Season year'),
            placeholder: this.gettext('Choose year'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('2017'), value: '2017' },
              { name: this.gettext('2018'), value: '2018' },
              { name: this.gettext('2019'), value: '2019' },
              { name: this.gettext('2020'), value: '2020' },
              { name: this.gettext('2021'), value: '2021' },
              { name: this.gettext('2022'), value: '2022' },
              { name: this.gettext('2023'), value: '2023' },
            ],
          },
        },
        {
          key: 'crop_year',
          type: 'gt-input',
          defaultValue: this.AccountsService.user?.settings.DEFAULT_VALUES.crop_year,
          templateOptions: {
            label: this.gettext('Crop year'),
            placeholder: this.gettext('Crop year'),
            addon: this.gettext('year'),
          },
        },
        {
          key: 'cargo_class',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Crop class'),
            placeholder: this.gettext('Crop class'),
            addon: this.gettext('class'),
          },
          validation: { show: true },
        },
        {
          key: 'payment_date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Payment date'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'acceptance_certificate',
          type: 'gt-ui-select',
          templateOptions: {
            label:
              this.gettext('Choose ') +
              this.AccountsService.user?.settings.ACCEPTANCE_CETRIFICATE_NAME,
            resource: 'logistics.AcceptanceCertificate',
            addFunc: () => {
              this.$window.open('/admin/logistics/acceptancecertificate/add/');
            },
            addPerms: ['add_acceptancecertificate'],
            disabled: !this.AccountsService.hasPerm('change_acceptancecertificate'),
          },
          hideExpression: !this.AccountsService.hasPerm('view_acceptancecertificate'),
        },
        {
          key: 'no_balance_invoice',
          type: 'gt-checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.gettext('No balance invoice'),
          },
        },
        {
          key: 'result_month',
          type: 'gt-date-select',
          defaultValue: new Date(),
          templateOptions: {
            label: this.gettext('Result month'),
            startView: 'month',
            minView: 'month',
          },
        },
        {
          key: 'disbursementbl',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Disbursement bill of lading'),
            placeholder: this.gettext('Choose disbursement bill of lading'),
            resource: 'finances.disbursementbl',
            addPerms: ['add_disbursementbl'],
          },
        },
        {
          key: 'business_unit',
          type: 'gt-ui-select',
          defaultValueResolve: () => this.CoreService.getDefaultBuId(logistic),
          templateOptions: {
            label: this.gettext('Business unit'),
            placeholder: this.gettext('Business Unit'),
            queryParams: this.GtUtils.getBUQueryParams(),
            resource: 'core.BusinessUnit',
          },
        },
        {
          key: 'additional_info',
          type: 'gt-textarea',
          templateOptions: {
            label: this.gettext('Additional Info'),
            placeholder: this.gettext('Write down any additional information about this vehicle)'),
            className: 'additional-info',
          },
        },
        {
          key: 'note',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Note'),
            placeholder: this.gettext('Write down any note about this vehicle)'),
          },
          validation: { show: true },
        },

        {
          template: html`
            <custom-values-container
              filter-level="'logistic-custom-values-container'"
              init-query-params="{
              object_id: model.id,
              purpose: model.content_type,
              purpose_model: 'logistic',
            }"
              mode="model.id ? 'edit' : 'create'"
              object-id="model.id"
            ></custom-values-container>
          `,
        },
      ],
    });

    col2.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('PARAMETERS'),
        defaultHide: true,
      },
      fieldGroup: [
        {
          key: 'cargo_brand',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Cargo brand'),
            placeholder: this.gettext('Choose cargo brand'),
            resource: 'clients.cargobrand',
          },
        },
        {
          key: 'bags_weight',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('BAGS WEIGHT IN (KGS)'),
            placeholder: this.gettext('BAGS WEIGHT IN (KGS)'),
            hint: this.gettext('BAGS WEIGHT IN (KGS)'),
            type: 'number',
          },
          validation: { show: true },
        },
        {
          key: 'bags_quantity',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('NO OF BAGS'),
            placeholder: this.gettext('NO OF BAGS'),
            hint: this.gettext('NO OF BAGS'),
            type: 'number',
          },
          validation: { show: true },
        },
        {
          key: 'gross_volume',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Gross volume'),
            placeholder: this.gettext('Gross volume'),
            hint: this.gettext('Gross volume'),
            type: 'number',
          },
          validation: { show: true },
        },
        {
          key: 'driver_first_name',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Driver first name'),
            placeholder: this.gettext('Driver`s first name'),
          },
          validation: { show: true },
        },
        {
          key: 'driver_middle_name',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Driver middle name'),
            placeholder: this.gettext('Driver`s middle name'),
          },
          validation: { show: true },
        },
        {
          key: 'driver_last_name',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Driver last name'),
            placeholder: this.gettext('Driver`s last name'),
          },
          validation: { show: true },
        },
        {
          key: 'drivers_license',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('License number'),
            placeholder: this.gettext('License number'),
          },
          validation: { show: true },
        },
        {
          key: 'driver_passport',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Driver passport seria & number'),
            placeholder: this.gettext('Driver passport seria & number'),
          },
          validation: { show: true },
        },
        {
          key: 'driver_phone',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Driver phone number'),
            placeholder: this.gettext('Driver phone number'),
          },
          validation: { show: true },
        },
        {
          key: 'costs',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Costs'),
            placeholder: this.gettext('Costs'),
            type: 'number',
            hint: this.gettext('The sum of delivery costs for this vehicle'),
            addon: this.gettext('per 1t'),
            required: true,
          },
        },
        {
          key: 'with_costs_vat',
          type: 'gt-checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.gettext('Costs VAT'),
          },
        },
        {
          key: 'intermediate_costs_in_USD',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Intermediate costs'),
            placeholder: this.gettext('Intermediate costs'),
            type: 'number',
            hint: this.gettext('The sum of delivery costs for intermediate logistics'),
            addon: this.gettext('per 1t'),
            required: false,
          },
          expressionProperties: {
            'templateOptions.disabled': 'true',
          },
        },
        {
          key: 'penalties',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Penalties'),
            placeholder: this.gettext('Penalties'),
            hint: this.gettext(
              'The sum of penalties for this vehicle. Can be also a negative number',
            ),
            addon: this.gettext('per unit'),
            type: 'number',
            required: true,
          },
        },
        {
          key: 'with_penalties_vat',
          type: 'gt-checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.gettext('Penalties VAT'),
          },
        },
        {
          key: 'cost_invoice',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Cost invoice'),
            placeholder: this.gettext('cost invoice'),
            resource: 'finances.Finance',
            disabled: true,
          },
        },
        {
          key: 'losses_selfcost_accounting_method',
          type: 'gt-select',
          defaultValue:
            this.AccountsService.user?.settings.DEFAULT_VALUES
              .default_losses_selfcost_accounting_method,
          templateOptions: {
            label: this.gettext('Losses accounting method'),
            placeholder: this.gettext('Losses accounting method'),
            options: [
              { title: this.gettext('Ignore loss costs'), id: 'ignore_loss_costs' },
              {
                title: this.gettext('Create default loss costs'),
                id: 'create_default_losses_costs',
              },
              {
                title: this.gettext('Include loss costs in self-cost by default'),
                id: 'include_loss_costs_in_selfcost',
              },
            ],
            onSelected: () =>
              (logistic.losses_charge = this.getDefaultLossesCharge(
                logistic.losses_selfcost_accounting_method,
              )),
          },
        },
        {
          key: 'losses_charge',
          type: 'gt-ui-select',
          defaultValue: this.getDefaultLossesCharge(),
          templateOptions: {
            label: this.gettext('Charge type for losses'),
            placeholder: this.gettext('Charge type for losses'),
            resource: 'finances.Charge',
            queryParams: { tariff_type: 'loss' },
          },
          hideExpression: () =>
            logistic.losses_selfcost_accounting_method !== 'create_default_losses_costs',
        },

        {
          key: 'elevator',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Elevator'),
            resource: 'clients.Elevator',
            addFunc: () => {
              return this.ClientsService.roleModal({ model_name: 'Elevator' });
            },
            addIcon: this.GtUtils.getIcon('clients.Elevator'),
            addPerms: ['add_elevator'],
          },
        },
        {
          key: 'station',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Station departure'),
            placeholder: this.gettext('Station'),
            resource: 'logistics.Station',
            addFunc: () => {
              this.$window.open('/admin/logistics/station/add/');
            },
            addIcon: this.GtUtils.getIcon('logistics.Station'),
            addPerms: ['add_station'],
          },
        },
        {
          key: 'station_receiving',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Station receive'),
            placeholder: this.gettext('Station'),
            resource: 'logistics.Station',
            addFunc: () => {
              this.$window.open('/admin/logistics/station/add/');
            },
            addIcon: this.GtUtils.getIcon('logistics.Station'),
            addPerms: ['add_station'],
          },
        },
        {
          key: 'terminal',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Terminal'),
            placeholder: this.gettext('Terminal'),
            resource: 'logistics.Terminal',
            addFunc: () => {
              this.$window.open('/admin/logistics/terminal/add/');
            },
            addIcon: this.GtUtils.getIcon('logistics.Terminal'),
            addPerms: ['add_terminal'],
            queryParams: { is_actual: '1' },
          },
        },
        {
          key: 'forwarder_contract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Forwarder contract'),
            placeholder: this.gettext('Choose contract'),
            resource: 'contracts.ContractBase',
            queryParams: () => ({
              supplier: logistic.forwarder,
              deal_type_list: ['services'],
              active: '1',
            }),
            addFunc: () => {
              return this.ContractsService.contractModal({
                contract_type: 'purchase',
                deal_type: 'services',
              });
            },
            addIcon: this.GtUtils.getIcon('contracts.PurchaseContract'),
            addPerms: ['add_purchasecontract'],
          },
        },
        {
          key: 'forwarder',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Forwarder'),
            resource: 'clients.Deliverer',
            addFunc: () => {
              return this.ClientsService.roleModal({ model_name: 'Deliverer' });
            },
            addIcon: this.GtUtils.getIcon('clients.Deliverer'),
            addPerms: ['add_deliverer'],
          },
        },

        {
          key: 'railway',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('railway (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'railway_required',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('Railway required'),
          },
        },
        {
          key: 'vet',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('vet (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'vet_required',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('Vet required'),
          },
        },
        {
          key: 'quarantine',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('quarantine (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'quarantine_required',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('Quarantine required'),
          },
        },
        {
          key: 'quality',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('quality (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'quality_required',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('Quality required'),
          },
        },
        {
          key: 'declaration',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('declaration (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'declaration_required',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('Declaration required'),
          },
        },
        {
          key: 'broker_confirmation',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('broker confirmation (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'broker_confirmation_required',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('broker confirmation required'),
          },
        },
        {
          key: 'eur_one',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('EUR 1 (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'eur_one_required',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('EUR 1 required'),
          },
        },
        {
          key: 'additional_document_one',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Document one'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'additional_document_two',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Document two'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'additional_document_three',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Document three'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'loaded_on_board',
          type: 'gt-checkbox',
          defaultValue: true,
          templateOptions: {
            label: this.gettext('Loaded on board'),
          },
        },
        {
          key: 'use_unload_costs',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('use unload costs'),
          },
        },
        {
          key: 'exclude_from_warehouse',
          type: 'gt-checkbox',
          templateOptions: {
            label: this.gettext('Exclude from warehouse'),
          },
        },
        {
          key: 'unload_costs_t',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Unload costs per t'),
            placeholder: this.gettext('Type number'),
            hint: this.gettext('Unload costs per t'),
            type: 'number',
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) =>
            !scope.model.use_unload_costs,
          expressionProperties: {
            'templateOptions.disabled': '!model.unload_costs_unit',
          },
        },
        {
          key: 'unload_costs_unit',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Unload costs per unit'),
            placeholder: this.gettext('Type number'),
            hint: this.gettext('Unload costs per unit'),
            type: 'number',
          },
          hideExpression: (viewValue: any, modelValue: any, scope: any) =>
            !scope.model.use_unload_costs,
          expressionProperties: {
            'templateOptions.disabled': '!model.unload_costs_t',
          },
        },
      ],
    });

    col3.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('DISPATCH'),
      },
      fieldGroup: [
        {
          key: 'supplier_contract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Purchase contract'),
            placeholder: this.gettext('Choose contract'),
            resource: 'contracts.PurchaseContract',
            queryParams: () => ({
              relative_cargo: logistic.cargo,
              multicontract: logistic.supplier_multicontract,
              active: '1',
              is_blocked_for_use: this.AccountsService.hasPerm('edit_closed_elements')
                ? undefined
                : '0',
            }),
            addFunc: () => {
              return this.ContractsService.contractModal({
                contract_type: 'purchase',
              });
            },
            addIcon: this.GtUtils.getIcon('contracts.PurchaseContract'),
            addPerms: ['add_purchasecontract'],
            hint: this.gettext('Number of Purchase contract, to which this vehicle is attached to'),
            onSelect: () =>
              this.ContractsService.Contract.get({
                id: logistic.supplier_contract,
              }).$promise.then((contract: any) => (logistic.supplier = contract.supplier)),
          },
        },
        {
          key: 'intermediate_contract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Intermediate contract'),
            placeholder: this.gettext('Choose contract'),
            resource: 'contracts.IntermediateContract',
            queryParams: () => ({
              deal_type: 'intermediate',
              contract_type: 'sale',
              is_blocked_for_use: this.AccountsService.hasPerm('edit_closed_elements')
                ? undefined
                : '0',
            }),
            addFunc: () =>
              this.ContractsService.contractModal({
                deal_type: 'intermediate',
                contract_type: 'sale',
              }),
            addIcon: this.GtUtils.getIcon('contracts.IntermediateContract'),
            addPerms: ['add_intermediatecontract'],
            hint: this.gettext(
              'Number of Intermediate contract, to which this vehicle is attached to',
            ),
          },
        },
        {
          key: 'supplier',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Supplier'),
            placeholder: this.gettext('Choose Supplier'),
            resource: 'clients.Supplier',
            addIcon: this.GtUtils.getIcon('clients.Supplier'),
            addPerms: ['add_purchasecontract'],
            hint: this.gettext('Supplier'),
          },
        },
        {
          key: 'shipment_business_unit',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Shipment business unit'),
            placeholder: this.gettext('Choose BU'),
            resource: 'core.BusinessUnit',
          },
        },
        {
          key: 'from_stock_reserve_unit',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('From Stock Reserve Unit'),
            resource: 'logistics.stockReserveUnit',
            queryParams: () => {
              return {
                sale_contract: logistic.buyer_contract,
                purchase_contract: logistic.supplier_contract,
              };
            },
          },
        },
        {
          template: require('../../../legacy/logistic-modal/contract-prices.tpl.html?raw'),
          controller: [
            '$scope',
            function (scope: any) {
              scope.$watch('model.supplier_contract', () => {
                if (scope.model.supplier_contract) {
                  s.ContractsService.ContractPrice.query(
                    {
                      contract: scope.model.supplier_contract,
                    },
                    (data: any) => {
                      scope.contractPrices = data.results;
                    },
                  );
                }
              });
            },
          ],
          hideExpression: (viewValue: any, modelValue: any, scope: any) =>
            !scope.model.supplier_contract,
        },
        {
          key: 'supplier_nineteen_invoice',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Supplier prepay invoice'),
            placeholder: this.gettext('Choose invoice'),
            resource: 'finances.Finance',
            queryParams: () => {
              return {
                finance_contract: logistic.supplier_contract,
                invoice_type: 'incoming',
              };
            },
            addFunc: () => {
              return this.FinancesService.financeModal({
                contract: logistic.supplier_contract,
                currency_exchange: logistic.contract_currency_exchange,
              });
            },
            addPerms: ['add_finance'],
            addIcon: this.GtUtils.getIcon('finances.Finance'),
            hint: this.gettext('prepay invoice, issued to you by Supplier'),
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.supplier_contract',
          },
        },
        {
          key: 'supplier_ten_invoice',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Supplier balance invoice'),
            placeholder: this.gettext('Choose invoice'),
            resource: 'finances.Finance',
            queryParams: () => {
              return {
                finance_contract: logistic.supplier_contract,
                invoice_type: 'incoming',
              };
            },
            addFunc: () => {
              return this.FinancesService.financeModal({
                contract: logistic.supplier_contract,
                currency_exchange: logistic.contract_currency_exchange,
              });
            },
            addPerms: ['add_finance'],
            addIcon: this.GtUtils.getIcon('finances.Finance'),
            hint: this.gettext('balance invoice, issued to you by Supplier'),
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.supplier_nineteen_invoice',
          },
        },
        {
          key: 'exporter',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Exporter'),
            resource: 'clients.Exporter',
            addFunc: () => {
              return this.ClientsService.roleModal({ model_name: 'Exporter' });
            },
            addIcon: this.GtUtils.getIcon('clients.Exporter'),
            addPerms: ['add_exporter'],
          },
        },
        {
          key: 'exporter_contract_number',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Exporter contract (#)'),
            placeholder: this.gettext('Exporter contract (#)'),
            addon: this.gettext('#'),
          },
          validation: { show: true },
          expressionProperties: {
            'templateOptions.disabled': '!model.exporter',
          },
        },
        {
          key: 'export_contract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Sale export contract'),
            placeholder: this.gettext('Choose contract'),
            resource: 'contracts.ContractBase',
            queryParams: () => ({
              deal_type: 'export',
              contract_type: 'sale',
              is_blocked_for_use: this.AccountsService.hasPerm('edit_closed_elements')
                ? undefined
                : '0',
            }),
            addFunc: () => {
              return this.ContractsService.contractModal({
                deal_type: 'export',
                contract_type: 'sale',
              });
            },
            addPerms: ['add_salecontract'],
            addIcon: this.GtUtils.getIcon('contracts.ContractBase'),
            hint: this.gettext(
              'Number of Sale export contract, to which this vehicle is attached to',
            ),
          },
        },
        {
          key: 'loading_date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Loading (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'supplier_invoice_field',
          type: 'gt-select',
          defaultValue: 'volume_departed',
          templateOptions: {
            label: this.gettext('Supplier invoice volume'),
            placeholder: this.gettext('Choose type'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Departed'), value: 'volume_departed' },
              {
                name: this.gettext('Consignment'),
                value: 'volume_departed_consignment',
              },
              { name: this.gettext('Received'), value: 'volume_received' },
              {
                name: this.gettext('Departed real'),
                value: 'volume_departed_real',
              },
            ],
          },
        },
        {
          key: 'purchase_payment_conditions',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Prepay - balance percent'),
            placeholder: this.gettext('0-100'),
            hint: this.gettext('Pick from 0 to 100 to set up prepay'),
            addon: this.gettext('%'),
            type: 'number',
            required: false,
          },
          validation: { show: true },

          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              if ($viewValue || $modelValue) {
                return (
                  parseFloat($viewValue || $modelValue || 0) >= 0 &&
                  parseFloat($viewValue || $modelValue || 0) <= 100
                );
              } else {
                return true;
              }
            },
          },
        },
        {
          key: 'volume_departed',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Volume (departed)'),
            placeholder: this.gettext('Volume (departed)'),
            addon: this.gettext('t'),
            hint: this.gettext('Volume departed'),
            type: 'number',
            required: true,
          },
        },
        {
          key: 'volume_departed_consignment',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Volume (departed consignment)'),
            placeholder: this.gettext('Volume (departed consignment)'),
            addon: this.gettext('t'),
            hint: this.gettext('Volume departed by consignment'),
            type: 'number',
          },
        },
        {
          key: 'purchase_init_price',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Purchase Init Price'),
            placeholder: this.gettext('Purchase Init Price'),
            type: 'number',
            addon: this.gettext('per 1 mt'),
          },
        },
        {
          key: 'price',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Purchase Price'),
            placeholder: this.gettext('Purchase Price'),
            type: 'number',
            addon: this.gettext('per 1 mt'),
            required: true,
          },
        },
        {
          key: 'currency_exchange',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Currency and rate'),
            placeholder: this.gettext('USD, UAH'),
            resource: 'finances.CurrencyExchange',
            queryParams: { currency_contract: logistic.supplier_contract },
            addFunc: () => {
              this.$window.open('/admin/finances/currencyexchange/add/');
            },
            addIcon: this.GtUtils.getIcon('finances.CurrencyExchange'),
            addPerms: ['add_currencyexchange'],
          },
        },
        {
          key: 'shipment_warehouse',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Shipment Warehouse'),
            placeholder: this.gettext('Shipment Warehouse'),
            resource: 'logistics.warehouse',
            queryParams: { is_group: '0' },
          },
        },
        {
          key: 'loading_addresses',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Loading addresses'),
            resource: 'location.address',
            required: false,
            addFunc: () => {
              this.$window.open('/admin/location/address/add/');
            },
            addIcon: 'fa-location-arrow',
            addPerms: ['add_station'],
            queryParams: { address_type: 'Loading', is_actual: '1' },
          },
        },
      ],
    });

    col4.fieldGroup.push({
      wrapper: 'gt-panel',
      templateOptions: {
        label: this.gettext('RECEIVE'),
      },
      fieldGroup: [
        {
          key: 'buyer_contract',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Sale contract'),
            placeholder: this.gettext('Choose contract'),
            resource: 'contracts.SaleContract',
            queryParams: () => ({
              relative_cargo: logistic.cargo,
              multicontract: logistic.buyer_multicontract,
              from_stock_reserve_unit: logistic.from_stock_reserve_unit,
              active: '1',
              is_blocked_for_use: this.AccountsService.hasPerm('edit_closed_elements')
                ? undefined
                : '0',
            }),
            addFunc: () => {
              return this.ContractsService.contractModal({
                contract_type: 'sale',
              });
            },
            addPerms: ['add_salecontract'],
            addIcon: this.GtUtils.getIcon('contracts.SaleContract'),
            hint: this.gettext('Number of Sale contract, to which this vehicle is attached to'),
          },
        },
        {
          key: 'unloading_business_unit',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Unloading business unit'),
            placeholder: this.gettext('Choose BU'),
            resource: 'core.BusinessUnit',
          },
        },
        {
          key: 'to_stock_reserve_unit',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('To Stock Reserve Unit'),
            resource: 'logistics.stockReserveUnit',
          },
        },
        {
          template: require('../../../legacy/logistic-modal/contract-prices.tpl.html?raw'),
          controller: [
            '$scope',
            function (scope: any) {
              scope.$watch('model.buyer_contract', () => {
                if (scope.model.buyer_contract) {
                  s.ContractsService.ContractPrice.query(
                    {
                      contract: scope.model.buyer_contract,
                    },
                    (data: any) => {
                      scope.contractPrices = data.results;
                    },
                  );
                }
              });
            },
          ],
          hideExpression: (viewValue: any, modelValue: any, scope: any) =>
            !scope.model.buyer_contract,
        },
        {
          key: 'buyer_nineteen_invoice',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Buyer prepay invoice'),
            placeholder: this.gettext('Choose invoice'),
            resource: 'finances.Finance',
            queryParams: () => {
              return {
                finance_contract: logistic.buyer_contract,
                invoice_type: 'outgoing',
              };
            },
            addFunc: () => {
              return this.FinancesService.financeModal({
                contract: logistic.buyer_contract,
                currency_exchange: logistic.contract_currency_exchange,
              });
            },
            addPerms: ['add_finance'],
            addIcon: this.GtUtils.getIcon('finances.Finance'),
            hint: this.gettext('prepay invoice, issued by you to Buyer'),
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.buyer_contract',
          },
        },
        {
          key: 'buyer_ten_invoice',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Buyer balance invoice'),
            placeholder: this.gettext('Choose invoice'),
            resource: 'finances.Finance',
            queryParams: () => {
              return {
                finance_contract: logistic.buyer_contract,
                invoice_type: 'outgoing',
              };
            },
            addFunc: () => {
              return this.FinancesService.financeModal({
                contract: logistic.buyer_contract,
                currency_exchange: logistic.contract_currency_exchange,
              });
            },
            addPerms: ['add_finance'],
            addIcon: this.GtUtils.getIcon('finances.Finance'),
            hint: this.gettext('balance invoice, issued by you to Buyer'),
          },
          expressionProperties: {
            'templateOptions.disabled': '!model.buyer_nineteen_invoice',
          },
        },
        {
          key: 'estimated_receiving_date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Estimated receiving (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'receiving_date',
          type: 'gt-date-select',
          templateOptions: {
            minView: 'minute',
            label: this.gettext('Receiving (date)'),
            placeholder: this.gettext('date'),
          },
        },
        {
          key: 'buyer_invoice_field',
          type: 'gt-select',
          defaultValue: 'volume_received',
          templateOptions: {
            label: this.gettext('Buyer invoice volume'),
            placeholder: this.gettext('Choose type'),
            valueProp: 'value',
            labelProp: 'name',
            options: [
              { name: this.gettext('Departed'), value: 'volume_departed' },
              {
                name: this.gettext('Consignment'),
                value: 'volume_departed_consignment',
              },
              { name: this.gettext('Received'), value: 'volume_received' },
              {
                name: this.gettext('Departed real'),
                value: 'volume_departed_real',
              },
            ],
          },
        },
        {
          key: 'sale_payment_conditions',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Prepay - balance percent'),
            placeholder: this.gettext('0-100'),
            hint: this.gettext('Pick from 0 to 100 to set up prepay'),
            addon: this.gettext('%'),
            type: 'number',
            required: false,
          },
          validation: { show: true },

          validators: {
            notFalse: ($viewValue: any, $modelValue: any) => {
              if ($viewValue || $modelValue) {
                return (
                  parseFloat($viewValue || $modelValue || 0) >= 0 &&
                  parseFloat($viewValue || $modelValue || 0) <= 100
                );
              } else {
                return true;
              }
            },
          },
        },
        {
          key: 'volume_received',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Volume (received)'),
            placeholder: this.gettext('Volume (received)'),
            addon: this.gettext('t'),
            hint: this.gettext('Volume received at the destination place'),
            type: 'number',
          },
        },
        {
          key: 'volume_departed_real',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Volume (real)'),
            placeholder: this.gettext('Volume (real)'),
            addon: this.gettext('t'),
            hint: this.gettext('Volume for calculations'),
            type: 'number',
            required: true,
          },
        },
        {
          key: 'volume_boarded',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Volume (boarded)'),
            placeholder: this.gettext('Volume (boarded)'),
            addon: this.gettext('t'),
            hint: this.gettext('Volume boarded'),
            type: 'number',
          },
        },
        {
          key: 'sale_init_price',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Sale Init Price'),
            placeholder: this.gettext('Sale Init Price'),
            type: 'number',
            addon: this.gettext('per 1 mt'),
          },
        },
        {
          key: 'sale_price',
          type: 'gt-input',
          defaultValue: 0,
          templateOptions: {
            label: this.gettext('Sale price'),
            placeholder: this.gettext('Sale price'),
            type: 'number',
            addon: this.gettext('per 1 mt'),
            required: true,
          },
        },
        {
          key: 'sale_currency_exchange',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Sale currency and rate'),
            placeholder: this.gettext('USD, UAH'),
            resource: 'finances.CurrencyExchange',
            queryParams: { currency_contract: logistic.buyer_contract },
            addFunc: () => {
              this.$window.open('/admin/finances/currencyexchange/add/');
            },
            addIcon: this.GtUtils.getIcon('finances.CurrencyExchange'),
            addPerms: ['add_currencyexchange'],
          },
        },
        {
          key: 'unloading_warehouse',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Unloading Warehouse'),
            placeholder: this.gettext('Unloading Warehouse'),
            resource: 'logistics.warehouse',
            queryParams: { is_group: '0' },
          },
        },
        {
          key: 'sale_date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Sale (date)'),
            placeholder: this.gettext('date'),
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'boarding_date',
          type: 'gt-date-select',
          templateOptions: {
            label: this.gettext('Boarding (date)'),
            placeholder: this.gettext('date'),
          },
          validation: {
            show: true,
          },
        },
        {
          key: 'volume_sale_trader',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Volume trader'),
            placeholder: this.gettext('Volume trader'),
            type: 'number',
          },
          validation: { show: true },
        },
        {
          key: 'sale_trader_amount',
          type: 'gt-input',
          templateOptions: {
            label: this.gettext('Amount trader'),
            placeholder: this.gettext('Amount trader'),
            type: 'number',
          },
          validation: { show: true },
        },
        {
          key: 'deliverer_in_port',
          type: 'gt-ui-select',
          templateOptions: {
            label: this.gettext('Deliverer in port'),
            placeholder: this.gettext('Deliverer in port'),
            resource: 'clients.Deliverer',
            queryParams: {
              role_name_list: 'deliverer',
            },
          },
        },
        {
          key: 'ignore_excess_contract_volume',
          type: 'gt-checkbox',
          defaultValue: false,
          templateOptions: {
            label: this.gettext('Ignore excess contract volume'),
            hint: this.gettext('Ignore excess contract volume'),
            addPerms: ['can_ignore_excess_contract_volume'],
          },
          hideExpression: () => !this.AccountsService.hasPerm('can_ignore_excess_contract_volume'),
        },
        {
          key: 'delivery_addresses',
          type: 'gt-ui-multiselect',
          templateOptions: {
            label: this.gettext('Delivery addresses'),
            resource: 'location.address',
            required: false,
            addFunc: () => {
              this.$window.open('/admin/location/address/add/');
            },
            addIcon: 'fa-location-arrow',
            addPerms: ['add_station'],
            queryParams: { address_type: 'Delivery', is_actual: '1' },
          },
        },
      ],
    });

    return {
      formName: 'logistic-edit-modal',
      fieldsDef: [col1, col2, col3, col4],
    };
  }
  getCustomFieldTableColumns() {
    return this.CustomValuesService.getCustomFieldTableColumns({ purpose_model: 'logistic' });
  }
}
LogisticFormFieldsService.$inject = [
  '$rootScope',
  '$window',
  'gettext',
  'GtUtils',
  'CoreService',
  'AccountsService',
  'FinancesService',
  'ContractsService',
  'ClientsService',
  'CustomValuesService',
];
