import React from 'react';

import { EntityListContainer } from '~/core/entity-lists';
import type { ContainerViewProps } from '~/core/entity-lists/lib/types';
import { createComponent } from '~/shared/lib/components';

import type { CounterpartiesContainerProps } from './counterparties-container.model';
import { CounterpartiesContainerModel } from './counterparties-container.model';
import type {
  Counterparty,
  CounterpartyPatch,
  CounterpartyPost,
  CounterpartyQueryParams,
  CounterpartyRecord,
} from '../../lib';
import type { CounterpartiesRepository } from '../../services';

const CounterpartiesContainerView = ({
  model,
}: ContainerViewProps<CounterpartiesContainerModel, CounterpartiesContainerProps>) => {
  return (
    <EntityListContainer<
      CounterpartiesContainerModel,
      Counterparty,
      CounterpartyQueryParams,
      CounterpartiesRepository,
      CounterpartiesContainerProps,
      CounterpartyRecord,
      CounterpartyPost,
      CounterpartyPatch
    >
      model={model}
    />
  );
};

export const CounterpartiesContainer = createComponent(
  CounterpartiesContainerView,
  CounterpartiesContainerModel,
);
