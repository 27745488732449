import React from 'react';

import type { InjectionToken } from '../di';
import { container } from '../di';
import type { ComponentModel } from './component-model';
import type { ComponentProps, ComponentViewProps } from './types';

export const createComponent = <
  TViewProps extends ComponentViewProps<TModel, TProps>,
  // original type:  TProps extends ComponentProps = Omit<TViewProps, 'model'>,
  TProps extends ComponentProps & { model?: TViewProps['model'] } = Omit<TViewProps, 'model'> & {
    model?: TViewProps['model'];
  },
  TModel extends ComponentModel<TProps> = ComponentModel<TProps>,
>(
  ViewComponent: React.FC<TViewProps>,
  modelClass: InjectionToken<TModel>,
): React.FC<TProps> => {
  const ComponentWrapper: React.FC<TProps> = (props: TProps) => {
    const model = React.useMemo(() => {
      const model = (props.model as TModel) ?? container.resolve<TModel>(modelClass);
      model.props = props;
      return model;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
      model.props = props;
    }, [model, props]);

    return <ViewComponent {...({ ...props, model } as TViewProps)} />;
  };

  ComponentWrapper.displayName = `createComponent(${ViewComponent.displayName ?? ViewComponent.name ?? 'View'})`;

  return ComponentWrapper;
};
