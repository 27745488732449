import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import type {
  CertsLogisticRecord,
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
} from '~/features/execution/logistic/common';
import { createComponent } from '~/shared/lib/components';

import type { CertsLogisticListContainerProps } from './certs-logistic-list.model';
import { CertsLogisticListModel } from './certs-logistic-list.model';
import type { CertsLogisticRepository } from '../services';

const CertsLogisticContainerView: React.FC<
  ContainerViewProps<CertsLogisticListModel, CertsLogisticListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      CertsLogisticListModel,
      Logistic,
      LogisticQueryParams,
      CertsLogisticRepository,
      CertsLogisticListContainerProps,
      CertsLogisticRecord,
      LogisticPost,
      LogisticPatch
    >
      model={model}
    />
  );
};

export const CertsLogisticContainer = createComponent(
  CertsLogisticContainerView,
  CertsLogisticListModel,
);
