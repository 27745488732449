import { CustomFieldRepository } from '~/core/custom-fields/services/custom-field.repository';
import { injectable } from '~/shared/lib/di';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from '~/shared/lib/state';
import { askUser } from '~/shared/ui/lib/ask-user';

import {
  buildNewColumnConfig,
  columnConfigDefFromFieldDef,
  columnsConfigFromColumnsSetConfig,
  updateColumnsIndexes,
} from '../helpers/configs';
import { applyColumnParams, fieldDefFromCustomField } from '../helpers/fields';
import { ColumnConfigParams, ColumnsConfig, DataRecord, DataRecordsArgs } from '../types';
import { ColumnsSetConfigRepository } from './columns-set-config.repository';

type ViewModelConfig<R extends DataRecord> = {
  app: string;
  entity: string;
  datasetId: string;
  fieldsSrc: DataRecordsArgs<R>['fieldsSrc'];
};

@injectable()
export class DatasetViewModel<R extends DataRecord> {
  private readonly configSubj = new BehaviorSubject<ViewModelConfig<R> | null>(null);
  private readonly editingColumnsConfigSubj = new BehaviorSubject<ColumnsConfig | null>(null);
  private readonly activeColumnsConfigSubj = new BehaviorSubject<ColumnsConfig | null>(null);
  private readonly loadingSubj = new BehaviorSubject<boolean>(false);
  private readonly draggableItemSubj = new BehaviorSubject<ColumnConfigParams | null>(null);

  constructor(
    private readonly customFieldRepo: CustomFieldRepository,
    private readonly columnConfigsRepo: ColumnsSetConfigRepository,
  ) {}

  readonly config$ = this.configSubj.pipe(filter(Boolean));
  readonly fieldDefs$ = this.config$.pipe(switchMap(({ fieldsSrc }) => fieldsSrc()));

  readonly customFields$ = this.config$.pipe(
    switchMap((config) =>
      this.customFieldRepo.query({
        table_name: config.app,
        purpose_model: config.entity,
      }),
    ),
  );

  readonly draggableItem$ = this.draggableItemSubj.asObservable();
  readonly loading$ = this.loadingSubj.asObservable();
  readonly activeColumnsConfig$ = this.activeColumnsConfigSubj.asObservable();

  readonly defaultColumnParams$ = combineLatest([this.fieldDefs$, this.customFields$]).pipe(
    map(([fields, { records: customFields }]) =>
      columnConfigDefFromFieldDef([
        ...fields,
        ...customFields.map((i) => fieldDefFromCustomField<R>(i)),
      ]),
    ),
  );

  readonly columnParams$ = combineLatest([
    this.defaultColumnParams$,
    this.activeColumnsConfig$,
  ]).pipe(
    map(([defaultColumns, activeConfig]) =>
      activeConfig ? activeConfig.column_params : defaultColumns,
    ),
  );

  readonly columnConfigs$ = combineLatest([this.config$, this.defaultColumnParams$]).pipe(
    tap(() => this.loadingSubj.next(true)),
    switchMap(async ([config, defaultColumnParams]) => {
      const { records } = await this.columnConfigsRepo.query({ config_level: config.datasetId });
      return records.map((record) =>
        columnsConfigFromColumnsSetConfig(record, defaultColumnParams),
      );
    }),
    tap(() => this.loadingSubj.next(false)),
  );

  readonly editingColumnsConfig$ = this.editingColumnsConfigSubj.asObservable();
  readonly editingColumns$ = this.editingColumnsConfig$.pipe(
    map((config) => ({
      visible:
        config?.column_params.filter((col) => col.visible).toSorted((a, b) => a.index - b.index) ??
        [],
      hidden:
        config?.column_params.filter((col) => !col.visible).toSorted((a, b) => a.index - b.index) ??
        [],
    })),
  );

  readonly fields$ = combineLatest([this.fieldDefs$, this.customFields$, this.columnParams$]).pipe(
    switchMap(([fieldDefs, { records: customFields }, columnParams]) => {
      const customFieldDefs = customFields.map((i) => fieldDefFromCustomField<R>(i));
      return of(applyColumnParams([...fieldDefs, ...customFieldDefs], columnParams));
    }),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  public setConfig(config: ViewModelConfig<R>) {
    if (this.configSubj.value) {
      console.warn('Config already set');
      return;
    }
    this.configSubj.next(config);
  }

  public setActiveColumnsConfig(config: ColumnsConfig) {
    this.activeColumnsConfigSubj.next(
      config.id === this.activeColumnsConfigSubj.value?.id ? null : config,
    );
  }

  public setEditingColumnsConfig(config: ColumnsConfig | null) {
    this.editingColumnsConfigSubj
      .pipe(
        take(1),
        switchMap((config) => (config ? from(askUser()) : Promise.resolve(true))),
        filter((answer) => answer),
        tap(() => this.editingColumnsConfigSubj.next(config)),
      )
      .subscribe();
  }

  editColumn = (editedCol: ColumnConfigParams) => {
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) =>
          this.editingColumnsConfigSubj.next({
            ...config,
            column_params: config.column_params.map((col) =>
              col.id === editedCol.id ? editedCol : col,
            ),
          }),
        ),
      )
      .subscribe();
  };
  editAllColumns = (edited: Partial<ColumnConfigParams>) => {
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) =>
          this.editingColumnsConfigSubj.next({
            ...config,
            column_params: config.column_params.map((col) => ({ ...col, ...edited })),
          }),
        ),
      )
      .subscribe();
  };

  toggleColumnVisibility = (column: ColumnConfigParams) =>
    this.editColumn({ ...column, visible: !column.visible });
  toggleColumnRequirement = (column: ColumnConfigParams) =>
    this.editColumn({ ...column, required: !column.required });
  hideAllColumns = () => this.editAllColumns({ visible: false });
  showAllColumns = () => this.editAllColumns({ visible: true });
  reloadColumnConfigs = () => {
    this.configSubj.next(this.configSubj.value);
  };

  createColumnsConfig = () => {
    this.defaultColumnParams$
      .pipe(
        take(1),
        tap((defaultColumnParams) =>
          this.setEditingColumnsConfig(buildNewColumnConfig(defaultColumnParams)),
        ),
      )
      .subscribe();
  };

  editColumnsConfig = (edited: Partial<ColumnsConfig>) => {
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) => this.editingColumnsConfigSubj.next({ ...config, ...edited })),
      )
      .subscribe();
  };

  duplicateColumnsConfig = () => {
    combineLatest([this.editingColumnsConfig$.pipe(filter(Boolean)), this.defaultColumnParams$])
      .pipe(
        take(1),
        tap(([config, defaultColumnParams]) =>
          this.setEditingColumnsConfig(buildNewColumnConfig(defaultColumnParams, config)),
        ),
      )
      .subscribe();
  };

  saveColumnsConfig = () => {
    combineLatest([
      this.editingColumnsConfig$.pipe(filter(Boolean)),
      this.config$,
      this.defaultColumnParams$,
      askUser(),
    ])
      .pipe(
        take(1),
        filter(([, , , answer]) => answer),
        tap(() => this.loadingSubj.next(true)),
        switchMap(async ([config, modelConfig, defaultConfig]) =>
          columnsConfigFromColumnsSetConfig(
            await this.columnConfigsRepo.saveRecord({
              ...config,
              config_level: modelConfig.datasetId,
              column_params: config.column_params.map((col) => ({
                ...col,
                required: col.requiredByDefault ? col.requiredByDefault : (col.required ?? false),
                columnssetconfig_id: config.id,
                table_name: modelConfig.app,
              })),
            }),
            defaultConfig,
          ),
        ),
        tap((config) => {
          this.editingColumnsConfigSubj.next(null);
          this.activeColumnsConfigSubj.next(
            config.id === this.activeColumnsConfigSubj.value?.id
              ? config
              : this.activeColumnsConfigSubj.value,
          );
          this.reloadColumnConfigs();
        }),
      )
      .subscribe();
  };

  deleteColumnsConfig = () => {
    combineLatest([this.editingColumnsConfig$.pipe(filter(Boolean)), askUser()])
      .pipe(
        take(1),
        filter(([, answer]) => answer),
        tap(() => this.loadingSubj.next(true)),
        switchMap(([config]) =>
          config.id > 0 ? from(this.columnConfigsRepo.delete(config.id)) : from(Promise.resolve()),
        ),
        tap(() => {
          this.editingColumnsConfigSubj.next(null);
          this.reloadColumnConfigs();
        }),
      )
      .subscribe();
  };

  handleDragStart = (id: number) => {
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) =>
          this.draggableItemSubj.next(config.column_params.find((col) => col.id === id) ?? null),
        ),
      )
      .subscribe();
  };

  handleDragEnd = (draggedId: number, overId?: number) => {
    if (!overId) {
      this.draggableItemSubj.next(null);
      return;
    }
    this.editingColumnsConfig$
      .pipe(
        take(1),
        filter(Boolean),
        tap((config) =>
          this.editingColumnsConfigSubj.next({
            ...config,
            column_params: updateColumnsIndexes(config.column_params, draggedId, overId),
          }),
        ),
      )
      .subscribe();
  };
}
