import type { Column } from '@tanstack/react-table';
import type { CSSProperties } from 'react';

import type { DataRecord } from '../../types';

export const getStickyStyles = <R extends DataRecord>({
  getStart,
  getSize,
  isHeader,
  sticky,
}: {
  getStart: Column<R>['getStart'];
  getSize: Column<R>['getSize'];
  sticky?: boolean;
  isHeader?: boolean;
}): CSSProperties => {
  const stickyRow = sticky ? 1 : 0;
  const stickyHeader = sticky ? 10 : 2;

  return {
    left: sticky ? `${getStart()}px` : undefined,
    position: sticky ? 'sticky' : 'relative',
    width: getSize(),
    zIndex: isHeader ? stickyHeader : stickyRow,
  };
};
