import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import type {
  CostsLogisticRecord,
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
} from '~/features/execution/logistic/common';
import { createComponent } from '~/shared/lib/components';

import type { CostsLogisticListContainerProps } from './costs-logistic-list.model';
import { CostsLogisticListModel } from './costs-logistic-list.model';
import type { CostsLogisticRepository } from '../services';

const CostsLogisticContainerView: React.FC<
  ContainerViewProps<CostsLogisticListModel, CostsLogisticListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      CostsLogisticListModel,
      Logistic,
      LogisticQueryParams,
      CostsLogisticRepository,
      CostsLogisticListContainerProps,
      CostsLogisticRecord,
      LogisticPost,
      LogisticPatch
    >
      model={model}
    />
  );
};

export const CostsLogisticContainer = createComponent(
  CostsLogisticContainerView,
  CostsLogisticListModel,
);
