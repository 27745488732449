import React from 'react';

import { cn } from '~/shared/lib/utils';
import { CheckIcon } from '~/shared/ui/icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '~/shared/ui/kit/dropdown-menu';
import { Input } from '~/shared/ui/kit/input';
import { Tag } from '~/shared/ui/kit/tag';
import { Tooltip } from '~/shared/ui/kit/tooltip';

import { messageIntent } from '../../lib/constants';
import { type DataTypeProps } from '../../lib/types';

export const SelectWrite: React.FC<DataTypeProps<'select'>> = ({
  message,
  disabled,
  value,
  onChange,
  typeExtra,
}) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  const filteredOptions =
    typeExtra?.options.filter((item) =>
      String(item.value).toLowerCase().includes(searchTerm.toLowerCase()),
    ) ?? [];

  const selectElement = (
    <div
      className={cn(
        'flex h-full w-full items-center',
        message && 'border-stroke-additional-danger',
        message && messageIntent[message?.type],
      )}
      data-testid="select-container"
    >
      <DropdownMenu open={typeExtra?.isOpen}>
        <DropdownMenuTrigger className="h-full w-full pl-2 outline-none">
          {value ? (
            <Tag disabled={disabled} {...value.tagProps}>
              {value.label}
            </Tag>
          ) : (
            <div className="min-h-[2rem]" />
          )}
        </DropdownMenuTrigger>
        <DropdownMenuContent className="flex h-full w-max flex-col p-0">
          <Input
            type="text"
            intent="transparent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onBlur={(e) => e.target.focus()}
            placeholder="Search"
          />
          <DropdownMenuSeparator className="bg-transparent-medium my-0" />
          <div className="scrollbar flex max-h-[180px] flex-col gap-0.5 overflow-y-auto">
            {filteredOptions.map((option) => (
              <DropdownMenuItem
                key={option.value}
                className={cn(
                  'hover:bg-transparent-light flex cursor-pointer items-center justify-between p-1',
                  value?.value === option.value && 'bg-transparent-light',
                )}
                onClick={() => onChange?.(option)}
              >
                <Tag {...option?.tagProps}>{option.label}</Tag>
                {value?.value === option.value && (
                  <CheckIcon
                    size={16}
                    className="text-text-main-secondary"
                    strokeWidth="1.33"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </DropdownMenuItem>
            ))}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );

  return message ? (
    <Tooltip content={message?.text ?? ''} side="bottom" sideOffset={4}>
      <div className="w-full">{selectElement}</div>
    </Tooltip>
  ) : (
    selectElement
  );
};
