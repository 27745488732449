import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import type {
  ExportLogisticRecord,
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
} from '~/features/execution/logistic/common';
import { createComponent } from '~/shared/lib/components';

import type { ExportLogisticListContainerProps } from './export-logistic-list.model';
import { ExportLogisticListModel } from './export-logistic-list.model';
import type { ExportLogisticRepository } from '../services';

const ExportLogisticContainerView: React.FC<
  ContainerViewProps<ExportLogisticListModel, ExportLogisticListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      ExportLogisticListModel,
      Logistic,
      LogisticQueryParams,
      ExportLogisticRepository,
      ExportLogisticListContainerProps,
      ExportLogisticRecord,
      LogisticPost,
      LogisticPatch
    >
      model={model}
    />
  );
};

export const ExportLogisticContainer = createComponent(
  ExportLogisticContainerView,
  ExportLogisticListModel,
);
