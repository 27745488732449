import type { Counterparty } from '~/features/crm/counterparties';
import { type Season, coreSeasonsList } from '~/shared/api';
import { errorHandler } from '~/shared/lib/errors';

import template from './client-details-item-view.html?raw';

import type { GtRootScopeService } from '^/app/core/types';

export const clientDetailsItemView = {
  bindings: {
    client: '<',
    fields: '<',
    tab: '<',
    tabVisibility: '<',
    updateContractsData: '&?',
    hasRoles: '&?',
    supplierId: '<?',
    buyerId: '<?',
    filterLogisticVolumes: '&?',
    openClientModal: '&?',
    openClientModalQuick: '&?',
    createConfirmationTask: '&?',
    openEmailModal: '&?',
    openDocxModal: '&?',
    updateData: '&?',
    changeTab: '&?',
    contractsConsolidated: '<?',
    contractsByCrops: '<?',
    contractVolumesChartConfigs: '<?',
    hideWizard: '<?',
    isCheckList: '<?',
  },
  template,
  controller: [
    '$rootScope',
    class {
      $rootScope: GtRootScopeService;
      client!: Counterparty;
      buyerId?: number;
      supplierId?: number;
      showDetail: boolean;
      tab: string;
      fields: any;
      tabVisibility: any;
      seasons: Season[] = [];
      constructor($rootScope: GtRootScopeService) {
        // this.client = {};
        this.$rootScope = $rootScope;
        this.tab = '';
        this.tabVisibility = {};
        this.fields = [];
        this.showDetail = Boolean($rootScope.user.profile?.recap);
      }

      $onInit() {
        this.fetchSeasons().catch(errorHandler);
      }

      fetchSeasons = async () => {
        await coreSeasonsList().then((seasons) => {
          this.seasons = seasons.data.results;
        });
      };

      tabExpanded(currentTab: string) {
        return this.tab.includes(currentTab);
      }

      refreshSeason(seasonId: number) {
        this.$rootScope.$broadcast('season-limits-refresh-requested', { seasonId: seasonId });
      }
    },
  ],
};
