import React from 'react';

import { PageFilters } from '~/core/page-filters';
import { useObservableState } from '~/shared/lib/state';

import { useDatasetModel } from '../../hooks/models';

export const FiltersBar = () => {
  const datasetModel = useDatasetModel();
  const filtersOptions = useObservableState(datasetModel.filters.filtersOptions$);
  const sortingOptions = useObservableState(datasetModel.filters.sortingOptions$);
  const presetOptions = useObservableState(datasetModel.filters.presetOptions$);

  return (
    (!!filtersOptions?.length && !!sortingOptions && !!presetOptions && (
      <PageFilters
        filtersOptions={filtersOptions}
        sortingOptions={sortingOptions}
        presetOptions={presetOptions}
        onChange={datasetModel.filters.presetChanged}
        onSave={datasetModel.filters.savePreset}
        onDelete={datasetModel.filters.deletePreset}
        mode="line"
      />
    )) ||
    null
  );
};
