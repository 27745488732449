import React from 'react';

import type { PageFiltersModel } from './services/page-filters.model';

export const PageFiltersModelContext = React.createContext<PageFiltersModel | null>(null);

export const usePageFiltersModel = () => {
  const model = React.useContext(PageFiltersModelContext);

  if (!model) {
    throw new Error('useFiltersModel must be used within a FiltersModelProvider');
  }

  return model;
};
