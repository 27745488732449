import { useObservableEagerState } from 'observable-hooks';
import React from 'react';

import { usePageFiltersModel } from '../../hooks';
import { FilterItem } from '../filter-item';
import { FiltersSelector } from '../filters-selector';
import { PresetSelector } from '../preset-selector';
import { SortingSelector } from '../sorting-selector';

export const FiltersLine = () => {
  const model = usePageFiltersModel();

  const preset = useObservableEagerState(model.preset$);
  const presetOptions = useObservableEagerState(model.presetOptions$);

  const sorting = useObservableEagerState(model.sorting$);
  const sortingOptions = useObservableEagerState(model.sortingOptions$);

  const filters = useObservableEagerState(model.filters$);
  const filtersOptions = useObservableEagerState(model.filtersOptions$);

  return (
    <ul
      className="border-stroke-main-medium flex items-center justify-between gap-3 border-y px-4"
      data-testid="filters-line"
    >
      <li className="scrollbar max-w-[calc(100vw-170px)] overflow-x-auto">
        <ul className="flex w-max items-center gap-1 py-2 pr-2 pl-2">
          <li className="flex items-center">
            <div className="border-stroke-main-medium border-r pr-3">
              <SortingSelector
                sorting={sorting}
                sortingOptions={sortingOptions}
                onChange={model.changeSorting}
              />
            </div>
            <div className="pl-3">
              <PresetSelector
                preset={preset}
                presetOptions={presetOptions}
                onSelect={model.changePreset}
                onSave={model.savePreset}
                onDelete={model.deletePreset}
              />
            </div>
          </li>
          {filters.map(({ value, option, mode }) => (
            <li key={value.id}>
              <FilterItem
                filter={value}
                option={option}
                mode={mode}
                onChange={model.changeFilter}
                onDelete={model.deleteFilter}
              />
            </li>
          ))}
          <li>
            <FiltersSelector
              filters={preset.filters}
              options={filtersOptions}
              onChange={model.addFilter}
            />
          </li>
        </ul>
      </li>

      <li>
        <button
          className="text-text-main-secondary text-xs font-medium transition-opacity duration-300 hover:opacity-75"
          onClick={model.resetPreset}
        >
          Reset
        </button>
      </li>
    </ul>
  );
};
