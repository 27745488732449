import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import { createComponent } from '~/shared/lib/components';

import type { FinanceListContainerProps } from './finance-list.model';
import { FinanceListModel } from './finance-list.model';
import type {
  Finance,
  FinanceListParams,
  FinancePatch,
  FinancePost,
  FinanceRecord,
} from '../../lib';
import type { FinanceRepository } from '../../services';

const FinanceListContainerView = ({
  model,
}: ContainerViewProps<FinanceListModel, FinanceListContainerProps>) => {
  return (
    <EntityListContainer<
      FinanceListModel,
      Finance,
      FinanceListParams,
      FinanceRepository,
      FinanceListContainerProps,
      FinanceRecord,
      FinancePost,
      FinancePatch
    >
      model={model}
    />
  );
};

export const FinanceListContainer = createComponent(FinanceListContainerView, FinanceListModel);
