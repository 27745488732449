import {
  type ConnectionMode,
  type InvoicePurpose,
  type InvoicingVolume,
  type LogisticOperationsContext,
  volumeBaseMap,
} from '~/features/execution/logistic';

export class LogisticService {
  private readonly logisticInvoicingConditions: Record<
    InvoicePurpose,
    (logistic: LogisticOperationsContext, invoicingVolume?: InvoicingVolume) => boolean
  > = {
    export: (logistic: LogisticOperationsContext, invoicingVolume?: InvoicingVolume) => {
      const volume = logistic[volumeBaseMap[invoicingVolume ?? 'volume_departed']] ?? 0;
      return volume > 0 && Boolean(logistic.export_contract);
    },

    costs: (logistic: LogisticOperationsContext) =>
      Boolean(!logistic.cost_invoiceposition && logistic.costs && logistic.supplier_contract),

    incoming: (logistic: LogisticOperationsContext) =>
      Boolean(
        logistic.approval_status !== 'rejected' &&
          logistic.supplier_contract &&
          !(logistic.supplier_ten_invoice && logistic.supplier_nineteen_invoice) &&
          !(logistic.has_diff_limit && logistic.approval_status === 'process'),
      ),

    outgoing: (logistic: LogisticOperationsContext) =>
      Boolean(
        logistic.approval_status !== 'rejected' &&
          logistic.buyer_contract &&
          !(logistic.buyer_ten_invoice && logistic.buyer_nineteen_invoice) &&
          !(logistic.has_diff_limit && logistic.approval_status === 'process'),
      ),

    consolidated_costs: (logistic: LogisticOperationsContext) =>
      Boolean(
        logistic.transport_costs_data &&
          logistic.transport_costs_data.filter(
            (cost) => cost.price_per_deal > (cost.invoiced_amount ?? 0),
          ).length > 0,
      ),
  };
  private readonly logisticConnectionToEntityConditions: Record<
    ConnectionMode,
    (logistic: LogisticOperationsContext) => boolean
  > = {
    connectingToConsignment: (logistic: LogisticOperationsContext) =>
      Boolean(logistic.writeof_volume_balance && logistic.writeof_volume_balance > 0),
    connectingToBl: (logistic: LogisticOperationsContext) =>
      Boolean(logistic.bl_volume_balance && logistic.bl_volume_balance > 0),
    connectToDocument: (_logistic: LogisticOperationsContext) => true,
    connectingToDbl: (_logistic: LogisticOperationsContext) => true,
    connectingToExportContract: (_logistic: LogisticOperationsContext) => true,
    costsDistribution: (_logistic: LogisticOperationsContext) => true,
    disconnectingDBL: (logistic) => Boolean(logistic.disbursementbl_id),
  };

  isLogisticConnectable = (logistic: LogisticOperationsContext, connectionMode?: ConnectionMode) =>
    Boolean(connectionMode && this.logisticConnectionToEntityConditions[connectionMode](logistic));

  isLogisticInvoiceable = (
    logistic: LogisticOperationsContext,
    invoicePurpose?: InvoicePurpose,
    invoicingVolume?: InvoicingVolume,
  ) =>
    Boolean(
      invoicePurpose && this.logisticInvoicingConditions[invoicePurpose](logistic, invoicingVolume),
    );
}
