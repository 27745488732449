import React from 'react';

import { type ContainerViewProps, EntityListContainer } from '~/core/entity-lists';
import type {
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
  SaleLogisticRecord,
} from '~/features/execution/logistic/common';
import { createComponent } from '~/shared/lib/components';

import type { SaleLogisticListContainerProps } from './sale-logistic-list.model';
import { SaleLogisticListModel } from './sale-logistic-list.model';
import type { BaseLogisticListContainerProps } from '../../common/components';
import type { SaleLogisticRepository } from '../services';

const SaleLogisticContainerView: React.FC<
  ContainerViewProps<SaleLogisticListModel, SaleLogisticListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      SaleLogisticListModel,
      Logistic,
      LogisticQueryParams,
      SaleLogisticRepository,
      BaseLogisticListContainerProps,
      SaleLogisticRecord,
      LogisticPost,
      LogisticPatch
    >
      model={model}
    />
  );
};

export const SaleLogisticContainer = createComponent(
  SaleLogisticContainerView,
  SaleLogisticListModel,
);
