import type ng from 'angular';

import type { ViewMode } from '~/core/datasets';
import { type User, UserListModel, type UserQueryParams } from '~/features/accounts';
import { container } from '~/shared/lib/di';
import type { Subscription } from '~/shared/lib/state';

import template from './user-list-container.html?raw';
import type { GtUtilsService } from '../../../core/legacy/gt-utils/gt-utils.srv';

import type { AccountsService } from '^/app/accounts/accounts.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';

class UserListController implements ng.IController {
  vm;
  subs: Record<string, Subscription> = {};

  filterLevel = 'user-list-container';
  queryParams: UserQueryParams = {};
  users: User[] = [];
  usersCount = 0;

  viewMode: ViewMode = 'table';

  static readonly $inject = ['$scope', 'GtUtils', 'gtFilterService', 'AccountsService'];
  constructor(
    private readonly $scope: ng.IScope,
    private readonly GtUtils: GtUtilsService,
    private readonly gtFilterService: GtFilterService,
    private readonly AccountsService: AccountsService,
  ) {
    this.vm = container.resolve(UserListModel);
  }

  $onInit(): void {
    this.filterLevel = this.filterLevel || 'user-list-container';

    this.subs.pageData = this.vm.pageData$.subscribe((data) => {
      this.users = data.records;
      this.usersCount = data.count;
      this.$scope.$applyAsync();
    });

    this.subs.loading = this.vm.loading$.subscribe((loading) => {
      this.GtUtils.overlay(loading ? 'show' : 'hide');
      this.$scope.$applyAsync();
    });

    this.gtFilterService.subscribe(
      this.filterLevel,
      (queryParams) => {
        this.queryParams = queryParams;
        this.vm.pageParamsChanged(queryParams);
      },
      this.queryParams,
    );
  }
  $onDestroy(): void {
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  updateQueryParams = (params: UserQueryParams = {}) => {
    this.gtFilterService.updateQueryParams(params, this.filterLevel);
  };

  openUserModal = (item: User) => {
    return this.AccountsService.userModal(item).then(() => this.updateQueryParams({ page: 1 }));
  };

  changeViewMode = (view: ViewMode) => {
    this.viewMode = view;
    this.$scope.$applyAsync();
  };
}

export const userListContainerComponent: ng.IComponentOptions = {
  bindings: {
    filterLevel: '<?',
  },
  template,
  controller: UserListController,
};
