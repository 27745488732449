import React from 'react';

import { useObservableEagerState } from '~/shared/lib/state';
import type { Message } from '~/shared/lib/types';

import { useDatasetModel } from './models';
import type { DataParams, DataRecord, FieldDef } from '../types';

export const useErrorMessage = <R extends DataRecord, P extends DataParams>({
  recordId,
  fieldKey,
}: {
  recordId: R['id'];
  fieldKey: FieldDef<R>['key'];
}): Message | undefined => {
  const datasetModel = useDatasetModel<R, P>();
  const errors = useObservableEagerState(datasetModel.recordsErrors$);
  const message = React.useMemo(
    () => errors[recordId as number]?.[fieldKey as string],
    [errors, recordId, fieldKey],
  );

  return message;
};
