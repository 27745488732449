import { RefObject, createRef } from 'react';

import { ComponentModel } from '~/shared/lib/components';
import { injectable } from '~/shared/lib/di';
import { EntityMap } from '~/shared/lib/entities';
import type { RecordErrors } from '~/shared/lib/entities';
import { errorHandler } from '~/shared/lib/errors';
import { notifyError, notifyWarn } from '~/shared/lib/notify';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  delay,
  distinctUntilKeyChanged,
  filter,
  from,
  map,
  mergeMap,
  of,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from '~/shared/lib/state';
import { copyToClipboard, diff, rmUndefined, scrollIntoView } from '~/shared/lib/utils';
import { DataType } from '~/shared/ui/data-types';
import { askUser } from '~/shared/ui/lib/ask-user';

import { DatasetFiltersModel } from './dataset-filters.model';
import { DatasetViewModel } from './dataset-view.model';
import { fieldDefToFilterDef, fieldDefToSortingOption } from '../helpers/filters';
import { getSelected, serializeCopyData } from '../helpers/grid';
import { parseFieldValue, setRecordValue, validateRecord } from '../helpers/records';
import {
  DataErrors,
  DataParams,
  DataRecord,
  DataRecordsArgs,
  DatasetViewProps,
  FlagMap,
} from '../types';

type CellCoordinates = { rowId: number; colKey: string };

export type Selection = {
  start: CellCoordinates;
  end: CellCoordinates;
};

type ArrowKeyDown = {
  key: string;
  rowId: number;
  colKey: string;
  shiftKey?: boolean;
};

@injectable()
export class DatasetModel<R extends DataRecord, P extends DataParams> extends ComponentModel<
  DatasetViewProps<R, P>
> {
  private readonly editedRecordsByIdSubj = new BehaviorSubject<EntityMap<R>>({} as EntityMap<R>);
  private readonly rowsSelectedSubj = new BehaviorSubject<FlagMap>({});
  private readonly cellSelectedSubj = new BehaviorSubject<CellCoordinates | null>(null);
  private readonly cellRefsSubj = new BehaviorSubject<
    Record<string, RefObject<HTMLTableCellElement | null>>
  >({});

  private readonly rowRefsSubj = new BehaviorSubject<
    Record<number, RefObject<HTMLTableCellElement | null>>
  >({});
  private readonly selectionSubj = new BehaviorSubject<Selection | null>(null);
  private readonly selectingSubj = new BehaviorSubject(false);
  private readonly pasteErrorsSubj = new BehaviorSubject<DataErrors>({} as DataErrors);

  private readonly arrowKeyDownSubj = new Subject<ArrowKeyDown>();

  constructor(
    private readonly viewModel: DatasetViewModel<R>,
    private readonly filtersModel: DatasetFiltersModel<P>,
  ) {
    super();
    combineLatest({ props: this.props$, id: this.datasetId$ })
      .pipe(tap(({ props, id }) => viewModel.setConfig({ ...id, fieldsSrc: props.fieldsSrc })))
      .subscribe();

    combineLatest({ fields: this.view.fieldDefs$, id: this.datasetId$ })
      .pipe(
        tap(({ fields, id }) => {
          filtersModel.setConfig({
            datasetId: id.datasetId,
            filterDefs: fields.flatMap(fieldDefToFilterDef<R, P, DataType>),
            sortingOptions: fields.filter((f) => f.sorting).map(fieldDefToSortingOption<R>),
            onParamsChange: (params, replace) => this.changePageParams({ params, replace }),
          });
        }),
      )
      .subscribe();
  }

  datasetId$ = this.props$.pipe(
    distinctUntilKeyChanged('id'),
    map(({ id }) => {
      const [app, entity, datasetId] = id.split(/[:.]/);
      return { id, app, entity, datasetId };
    }),
  );
  editing$ = this.props$.pipe(
    distinctUntilKeyChanged('editing$'),
    switchMap(({ editing$ }) => editing$ ?? of(undefined)),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  pageParams$ = this.props$.pipe(
    distinctUntilKeyChanged('data$'),
    switchMap(({ pageParams$ }) => pageParams$),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  cellSelected$ = this.cellSelectedSubj.pipe(filter(Boolean));

  get view() {
    return this.viewModel;
  }

  get filters() {
    return this.filtersModel;
  }

  originalRecords$ = this.props$.pipe(
    distinctUntilKeyChanged('data$'),
    switchMap(({ data$ }) => data$),
    switchMap(({ records }) => of(records)),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  originalRecordsById$ = this.originalRecords$.pipe(
    map((records) => new Map(records.map((obj) => [obj.id, obj]))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  editedRecordsById$ = this.editedRecordsByIdSubj.pipe(
    map((records) => new Map(Object.values(records).map((obj) => [obj.id, obj]))),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  combinedRecords$ = combineLatest([this.originalRecordsById$, this.editedRecordsById$]).pipe(
    map(([orig, edit]) => new Map([...orig.entries(), ...edit.entries()]).values().toArray()),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  recordsErrors$ = combineLatest([
    this.view.fields$,
    this.editedRecordsById$,
    this.pasteErrorsSubj,
  ]).pipe(
    switchMap(async ([fields, edited, pasteErrors]) => {
      const editedRecords = edited
        .values()
        .toArray()
        .map(async (record) => await validateRecord({ fields, record }));
      return [...(await Promise.all(editedRecords)), pasteErrors];
    }),
    map((errors) =>
      errors.reduce((acc, rec) => {
        Object.keys(rec)
          .map(Number)
          .forEach((id) => (acc[id] = { ...acc[id], ...rec[id] }));
        return acc;
      }, {}),
    ),
    startWith({} as DataErrors),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  errors$ = this.recordsErrors$.pipe(
    map((errors) =>
      Object.entries<RecordErrors>(errors)
        .filter(([, fErrors]) => fErrors)
        .flatMap(([id, fErrors]) =>
          Object.entries(fErrors).map(([field, msg]) => ({ id, field, msg })),
        ),
    ),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  rowsSelected$ = this.rowsSelectedSubj.pipe();

  selectionChange$ = combineLatest({
    selection: this.selectionSubj,
    records: this.combinedRecords$,
    fields: this.view.fields$,
  }).pipe(
    scan(
      (prev, { selection, records, fields }) => {
        const selected = selection ? getSelected(selection, records, fields).cells : [];
        return { selected, unselected: diff(prev.selected, selected) };
      },
      { selected: [] as [number, string][], unselected: [] as [number, string][] },
    ),
  );

  nextSelectedCell$ = combineLatest({
    keyPressed: this.arrowKeyDownSubj,
    selection: this.selectionSubj,
    records: this.combinedRecords$,
    fields: this.view.fields$,
  }).pipe(
    distinctUntilKeyChanged('keyPressed'),
    map(({ keyPressed, selection, records, fields }) => {
      const edgeRowId = selection ? selection.end.rowId : keyPressed.rowId;
      const edgeColKey = selection ? selection.end.colKey : keyPressed.colKey;

      const rowIndex = records.findIndex((r) => r.id === edgeRowId);
      const colIndex = fields.findIndex((c) => c.key === edgeColKey);

      let nextRowId = edgeRowId;
      let nextColKey = edgeColKey;

      switch (keyPressed.key) {
        case 'ArrowUp':
          if (rowIndex > 0) {
            nextRowId = records[rowIndex - 1].id;
          }
          break;
        case 'ArrowDown':
          if (rowIndex < records.length - 1) {
            nextRowId = records[rowIndex + 1].id;
          }
          break;
        case 'ArrowLeft':
          if (colIndex > 0) {
            nextColKey = fields[colIndex - 1].key.toString();
          }
          break;
        case 'ArrowRight':
          if (colIndex < fields.length - 1) {
            nextColKey = fields[colIndex + 1].key.toString();
          }
          break;
        default:
          return;
      }

      return {
        prev: selection,
        next: { rowId: nextRowId, colKey: nextColKey },
        extend: keyPressed.shiftKey,
      };
    }),
    filter(Boolean),
    tap(({ prev, next, extend }) => {
      const selected = this.cellSelectedSubj.value;
      if (extend && selected) {
        const start = prev?.start ?? selected;
        this.setSelection({ start, end: next });
      } else if (!extend) {
        this.setCellSelected(next.rowId, next.colKey);
        this.setSelection({ start: next, end: next });
      }
    }),
  );

  groupActions$ = this.view.fields$.pipe(
    map((fields) =>
      fields.flatMap((field) => (field.actions ?? []).filter((action) => action.multiple)),
    ),
    shareReplay({ bufferSize: 1, refCount: false }),
  );

  setArrowKeyDown = (key: ArrowKeyDown) => this.arrowKeyDownSubj.next(key);

  setCellSelected = (rowId: number, colKey: string) => {
    this.cellSelectedSubj.next({ rowId, colKey });
  };

  setSelecting = (val: boolean) => this.selectingSubj.next(val);

  getSelecting = () => this.selectingSubj.value;

  setSelection = (selection: Selection | null) => this.selectionSubj.next(selection);
  getSelection = () => this.selectionSubj.value;

  getCellRef = (rowId: number, colKey: string) => {
    const key = `${rowId}-${colKey}`;
    if (!this.cellRefsSubj.value[key]) {
      this.cellRefsSubj.next({ ...this.cellRefsSubj.value, [key]: createRef() });
    }

    return this.cellRefsSubj.value[key];
  };

  getRowRef = (rowId: number) => {
    if (!this.rowRefsSubj.value[rowId]) {
      this.rowRefsSubj.next({ ...this.rowRefsSubj.value, [rowId]: createRef() });
    }
    return this.rowRefsSubj.value[rowId];
  };

  copyData = () => {
    if (!this.selectionSubj.value) {
      return;
    }
    combineLatest({
      records: this.combinedRecords$,
      fields: this.view.fields$,
      selection: this.selectionSubj,
    })
      .pipe(
        take(1),
        switchMap(({ records, fields, selection }) => {
          if (!selection) {
            throw new Error('selection must be defined');
          }
          const { records: sRecords, fields: sFields } = getSelected(selection, records, fields);
          return serializeCopyData(sRecords, sFields);
        }),
        tap(copyToClipboard),
      )
      .subscribe();
  };

  pasteData = (data: string[][]) => {
    if (!this.cellSelectedSubj.value) {
      return;
    }
    combineLatest({
      records: this.combinedRecords$,
      selected: this.cellSelected$,
      fields: this.view.fields$,
      params: this.pageParams$,
      props: this.props$,
    })
      .pipe(
        take(1),
        delay(0),
        switchMap(async ({ records, selected, fields, params, props: { onRecordAdd } }) => {
          const { rowId, colKey } = selected;
          const startIdx = records.findIndex((r) => r.id === rowId);
          const endIdx = startIdx + data.length;
          const pasteRecords = records.slice(startIdx, endIdx);

          if (pasteRecords.length < data.length) {
            const newRecords = await Promise.all(
              Array.from({ length: endIdx - records.length }, () => onRecordAdd(params)),
            );
            pasteRecords.push(...newRecords);
          }

          const fieldIdx = fields.findIndex((f) => f.key === colKey);
          const pasteFields = fields.slice(fieldIdx, fieldIdx + data[0].length);
          const pasteErrors: DataErrors = { ...this.pasteErrorsSubj.value };

          const resRecords = [];
          for (let i = 0; i < pasteRecords.length; i++) {
            let record = pasteRecords[i];
            for (let j = 0; j < data[i].length; j++) {
              const field = pasteFields[j];
              const value = data[i][j];

              if (!field || field.editing === false) {
                continue;
              }

              const { value: parsedValue, error } = await parseFieldValue({ field, value });

              delete pasteErrors[record.id]?.[String(field.key)];
              if (error) {
                pasteErrors[record.id] = {
                  ...pasteErrors[record.id],
                  ...{ [field.key]: { type: 'danger' as const, text: error } },
                };
              }

              record = (await setRecordValue({ field, value: parsedValue, record })) ?? record;
            }
            resRecords.push(record);
          }
          this.pasteErrorsSubj.next(pasteErrors);
          this.editedRecordsByIdSubj.next({
            ...this.editedRecordsByIdSubj.value,
            ...resRecords.reduce((acc, rec) => ({ ...acc, [rec.id]: rec }), {} as EntityMap<R>),
          });
        }),
      )
      .subscribe();
  };

  setRowsSelected = (updaterOrValue: ((rows: FlagMap) => FlagMap) | FlagMap) => {
    setTimeout(() => {
      if (typeof updaterOrValue === 'function') {
        this.rowsSelectedSubj.next(updaterOrValue(this.rowsSelectedSubj.value));
      } else {
        this.rowsSelectedSubj.next(updaterOrValue);
      }
    }, 0);
  };

  valueChanged: DataRecordsArgs<R>['onValueChange'] = ({ field, recordId, value }) => {
    this.originalRecordsById$
      .pipe(
        take(1),
        map((orig) => this.editedRecordsByIdSubj.value[recordId] ?? orig.get(recordId)),
        delay(0),
        switchMap(async (record) =>
          record ? await setRecordValue({ field, value, record }) : undefined,
        ),
        filter(Boolean),
        tap(() => {
          this.pasteErrorsSubj.next({
            ...this.pasteErrorsSubj.value,
            [recordId]: rmUndefined({
              ...this.pasteErrorsSubj.value[recordId],
              [field.key as string]: undefined,
            }),
          });
        }),
        map(this.updateEditedRecords),
      )
      .subscribe();
  };

  deleteRecords = (records: R[]) => {
    if (!records.length) {
      return;
    }
    askUser()
      .then((answer) => {
        if (!answer) {
          return;
        }
        const newRecords = records.filter((r) => r.id < 0);
        const savedRecords = records.filter((r) => r.id > 0);

        if (savedRecords.length) {
          this.props?.onRecordsDelete(savedRecords);
        }

        if (newRecords.length) {
          const editedRecords = { ...this.editedRecordsByIdSubj.value };
          newRecords.forEach((r) => delete editedRecords[r.id as keyof EntityMap<R>]);
          this.editedRecordsByIdSubj.next(editedRecords);
        }
      })
      .catch(errorHandler);
  };

  saveRecords = () => {
    combineLatest([
      this.editedRecordsById$.pipe(map((records) => records.values().toArray())),
      this.errors$,
    ])
      .pipe(
        take(1),
        map(([records, errors]) => {
          if (errors.length) {
            notifyError('Please fix errors before saving');
            return [];
          }

          if (!records.length) {
            notifyWarn('No records to save');
            return [];
          }
          return records;
        }),
        filter((i) => !!i.length),
        mergeMap((records) => from(this.props?.onRecordsSave(records) ?? Promise.resolve())),
        tap((errors) => {
          if (errors) {
            this.pasteErrorsSubj.next(errors);
          } else {
            this.changePageParams({ silent: true });
          }
        }),
      )
      .subscribe();
  };

  addRecord = () => {
    if (!this.props?.onRecordAdd) {
      throw new Error('onRecordAdd is not defined');
    }

    combineLatest([from(this.props.onRecordAdd()), this.view.fields$], (record, fields) => ({
      record,
      fields,
    }))
      .pipe(
        take(1),
        delay(0),
        tap(({ record }) => this.updateEditedRecords(record)),
        delay(20),
        tap(({ record, fields }) =>
          scrollIntoView(this.getCellRef(record.id, String(fields[0]?.key)).current),
        ),
      )
      .subscribe();
  };

  updateEditedRecords = (record: R) => {
    this.editedRecordsByIdSubj.next({ ...this.editedRecordsByIdSubj.value, [record.id]: record });
  };

  changePageParams = ({
    params,
    replace,
    silent,
  }: {
    params?: P;
    replace?: boolean;
    silent?: boolean;
  }) => {
    this.editedRecordsById$
      .pipe(
        take(1),
        switchMap((records) => {
          if (!Object.keys(records).length || silent) {
            return Promise.resolve(true);
          }
          return askUser({
            text: 'You have unsaved changes. Are you sure you want to leave this page?',
          });
        }),
        filter(Boolean),
        withLatestFrom(this.pageParams$),
        tap(([, pageParams]) => {
          this.props?.onParamsChange(params ?? pageParams, replace);
          this.editedRecordsByIdSubj.next({} as EntityMap<R>);
          this.pasteErrorsSubj.next({});
        }),
      )
      .subscribe();
  };
}
