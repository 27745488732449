import type ng from 'angular';
import type { Subscription } from 'rxjs';

import type { ChargeRecord, ChargesQueryParams } from '~/features/finances/contract-charges';
import { ChargesListViewModel } from '~/features/finances/contract-charges';
import type {
  ContractChargeListResponse,
  ContractChargesGroupedDataTotals,
  ContractChargesTotals,
} from '~/shared/api';
import { container } from '~/shared/lib/di';
import { notify } from '~/shared/lib/notify';

import template from './contract-charges-list-container.html?raw';

import type { CoreService } from '^/app/core/core.service';
import type { GtFilterService } from '^/app/core/legacy/gt-filter/gt-filter.srv';
import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { GtRootScopeService, QueryParams } from '^/app/core/types';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';
import type { MulticontractService } from '^/app/deals/multicontract/multicontract.service';
import type { FinancesService } from '^/app/finances/legacy/finances.srv';

type ViewMode = 'table' | 'spreadsheet' | 'grid';
type ChargesListQueryParams = ChargesQueryParams & {
  contract?: number;
  passport?: number;
  customs_declaration_volume?: number;
  customs_declaration?: number;
};
type Charge = ChargeRecord & { _selected: boolean; _showCheckbox: boolean };
type ChargesResponse = ContractChargeListResponse & { records: Charge[] };

export class ContractChargesContainer implements ng.IController {
  viewModel: ChargesListViewModel;
  pageData: { count: number; records: Charge[]; totals: ContractChargesTotals } = {
    records: [],
    count: 0,
    totals: {
      row_count: 0,
      price_per_deal_without_vat_global: 0,
      default_currency_symbol: '',
      groupped_data: [],
    },
  };

  subs: Record<string, Subscription> = {};

  queryParams: ChargesListQueryParams = { page_size: 25, page: 1 };
  initQueryParams: Partial<ChargesListQueryParams> = {};
  filterLevel = 'contract-charges-list-container';
  contractChargesData: Charge[] = [];
  view: ViewMode = 'table';
  grouppedData: ContractChargesGroupedDataTotals[] = [];
  totals: any = {};
  count = 0;
  selection = false;
  quickAdd = true;
  creating = false;
  showCommodity = false;
  showGrouppedTotals = false;
  showFinancingCalcButton = false;
  invoicing = false;
  loading = false;
  predictionsQueryParams?: QueryParams;
  invoceCreationStep = 0;
  invoiceType = '';
  newFinance: any = {};
  financePositions: any[] = [];
  newCharge: any = {};
  invoicingParams: QueryParams = {};
  objectStage?: string = undefined;

  tableName?: string;
  reportConfig: any;
  selectedContractCharges: any;
  consolidatedInvoiceCreation: any;
  savedReportConfigs: any[] = [];
  savedFilterChoices: any = [];

  static readonly $inject = [
    '$scope',
    '$rootScope',
    'gettext',
    'GtUtils',
    'ContractsService',
    'gtFilterService',
    'CoreService',
    'DocumentsService',
    'FinancesService',
    'MulticontractService',
  ];

  constructor(
    private readonly $scope: ng.IScope,
    private readonly $rootScope: GtRootScopeService,
    private readonly gettext: ng.gettext.gettextFunction,
    private readonly GtUtils: GtUtilsService,
    private readonly ContractsService: ContractsService,
    private readonly gtFilterService: GtFilterService,
    private readonly CoreService: CoreService,
    private readonly DocumentsService: any,
    private readonly FinancesService: FinancesService,
    private readonly MulticontractService: MulticontractService,
  ) {
    this.viewModel = container.resolve(ChargesListViewModel);
  }

  $onInit() {
    this.tableName = this.tableName ?? this.filterLevel;
    this.queryParams = { ...this.initQueryParams, ordering: '-update', is_by_default: '0' };
    this.viewModel.pageParamsChanged(this.queryParams);

    this.subs.pageData = this.viewModel.pageData$.subscribe((data) => {
      const typedData = data as ChargesResponse;

      this.pageData = {
        count: typedData.count,
        records: typedData.records,
        totals: typedData.totals,
      };

      this.updateData();
      this.$scope.$applyAsync();
    });

    this.subs.loading = this.viewModel.loading$.subscribe((loading) => {
      if (loading != this.loading || !loading) {
        this.loading = loading;
        this.GtUtils.overlay(loading ? 'show' : 'hide');
        this.$scope.$applyAsync();
      }
    });

    this.gtFilterService.subscribe(this.filterLevel, this.queryParamsChanged, this.queryParams);

    this.$rootScope.$on('gt-report-config-selected_' + this.filterLevel, (ev: any, config: any) => {
      this.reportConfig = config ? config.column_params : null;
    });
    this.$rootScope.$on('gt-report-config-created_' + this.filterLevel, (ev: any, config: any) => {
      this.savedReportConfigs = [...this.savedReportConfigs, config];
    });
    this.$rootScope.$on('gt-report-config-updated_' + this.filterLevel, (ev: any, config: any) => {
      const index = this.savedReportConfigs.findIndex((item: any) => item.id === config.id);
      this.savedReportConfigs[index] = config;
    });

    this.savedFilterChoices = this.CoreService.getSavedFilterChoices(this.filterLevel);
    this.savedReportConfigs = this.CoreService.getSavedReportConfigs(this.filterLevel);
  }

  $onDestroy() {
    this.gtFilterService.unsubscribe(this.filterLevel, this.queryParamsChanged);
    Object.values(this.subs).forEach((sub) => sub.unsubscribe());
  }

  queryParamsChanged = (params: ChargesListQueryParams) => {
    this.queryParams = params;
    this.viewModel.pageParamsUpdated(this.queryParams);
  };

  updateContractChangeStatuses() {
    return this.ContractsService.ContractCharge.setStatusClosed({}, () => {
      this.updateData();
      this.$scope.$applyAsync();
    });
  }

  updateData() {
    this.selection = false;
    this.contractChargesData = this.pageData.records;
    this.count = this.pageData.totals.row_count;
    this.grouppedData = this.pageData.totals.groupped_data;
    this.totals = this.pageData.totals;
    this.creating = false;
  }

  applyFilters(params: ChargesListQueryParams) {
    this.viewModel.pageParamsUpdated({
      ...this.initQueryParams,
      ...params,
    });
  }

  resetFilters() {
    return this.gtFilterService.setQueryParams(this.initQueryParams, this.filterLevel);
  }

  openDocumentModal(object: any) {
    return this.DocumentsService.attachDocumentModal(object, 'contractcharge').then(() =>
      this.updateData(),
    );
  }

  openContractChargeModal(charge?: ChargeRecord) {
    this.updateNewCharge();
    charge = charge ?? this.newCharge;
    if (charge) {
      charge.volume = charge.volume ?? this.initQueryParams.customs_declaration_volume;
      return this.ContractsService.contractChargeModal(charge).then(() => {
        this.viewModel.pageParamsChanged(this.queryParams);
        this.$scope.$applyAsync();
      });
    }
  }

  startStopInvoicing() {
    if (this.invoceCreationStep) {
      this.invoceCreationStep = 0;
      this.newFinance = {};
      this.financePositions = [];
      this.refreshCheckboxes();
      return;
    }
    this.setNextInvoiceCreationStep();
  }

  setNextInvoiceCreationStep() {
    if (this.invoceCreationStep === 2 && this.invoiceType) {
      this.invoceCreationStep = 3;
      this.updateNewFinance();
    } else if (
      this.invoceCreationStep === 1 &&
      this.queryParams.clientrole &&
      this.queryParams.currency
    ) {
      this.invoceCreationStep = 2;
    } else if (this.invoceCreationStep === 0) {
      this.invoceCreationStep = 1;
    }
    this.refreshCheckboxes();
  }

  selectInvoiceType(invoiceType: string) {
    this.invoiceType = invoiceType;
    this.setNextInvoiceCreationStep();
    this.refreshCheckboxes();
  }

  refreshCheckboxes() {
    this.contractChargesData.forEach((v) => {
      if (this.invoceCreationStep < 3 || !v.not_invoiced) {
        v._showCheckbox = v._selected = false;
      } else {
        v._showCheckbox = true;
      }
    });
  }

  selectAllChecboxes(value: any) {
    this.contractChargesData.forEach((v) => (v._selected = v._showCheckbox && value));
    if (this.invoceCreationStep) {
      this.updateFinancePositions();
    }
  }

  createInvoice(item?: ChargeRecord) {
    const newFinance = item?.id ? this.getNewFinanceByCharge(item) : this.newFinance;
    const financePositions = item?.id
      ? [this.getInviocePositionByContractCharge(item)]
      : this.financePositions;
    if (!financePositions.length) {
      return notify(this.gettext('Select any of item'), 'error');
    }

    return this.FinancesService.financeModal(newFinance, {
      financePositions: financePositions,
    }).then((data: any) => {
      if (data || data !== 'cancel') {
        this.newFinance = {};
        this.startStopInvoicing();
        this.resetFilters();
      }
    });
  }

  getNewFinanceByCharge(item: ChargeRecord) {
    const defaultOwner = this.$rootScope.user.settings.DEFAULT_VALUES.owner;
    const contractOwner = item.contract_owner_id || defaultOwner;
    const invoiceType = item.is_gain ? 'outgoing' : 'incoming';
    const isBroker = item.deal_type === 'brokerage';

    const clientFrom = invoiceType === 'incoming' ? item.clientrole : contractOwner;
    const clientTo = invoiceType === 'incoming' ? contractOwner : item.clientrole;
    const brokerageClientFrom = invoiceType === 'incoming' ? item.clientrole : contractOwner;
    const brokerageClientTo = invoiceType === 'incoming' ? contractOwner : item.clientrole;

    return {
      invoice_type: invoiceType,
      currency: item.currency,
      currency_exchange: item.currency_exchange,
      clientrole_from: isBroker ? brokerageClientFrom : clientFrom,
      clientrole_to: isBroker ? brokerageClientTo : clientTo,
      business_unit: this.invoicingParams.business_unit,
    };
  }

  updateNewFinance() {
    const defaultOwner = this.$rootScope.user.settings.DEFAULT_VALUES.owner;
    const clientFrom = this.invoiceType === 'incoming' ? this.queryParams.clientrole : defaultOwner;
    const clientTo = this.invoiceType === 'incoming' ? defaultOwner : this.queryParams.clientrole;

    this.newFinance = {
      invoice_type: this.invoiceType,
      amount: 0,
      volume: 0,
      currency: this.queryParams.currency,
      clientrole_from: clientFrom,
      clientrole_to: clientTo,
      business_unit: this.invoicingParams.business_unit,
    };
  }

  updateFinancePositions() {
    this.financePositions = this.contractChargesData
      .filter((row) => row._selected)
      .map((row) => this.getInviocePositionByContractCharge(row));

    this.newFinance.full_amount = this.newFinance.amount = this.financePositions.reduce(
      (res, row) => res + row.amount,
      0,
    );
  }

  updateNewCharge() {
    this.newCharge = {
      ...this.initQueryParams,
      status: 'forecast',
      price: 0,
      price_t: 0,
      percentage: 0,
      _selected: false,
      contract: this.queryParams.contract,
      passport: this.queryParams.passport,
      customs_declaration: this.queryParams.customs_declaration,
      currency: this.FinancesService.getDefaultCurrency().id,
    };
  }

  getInviocePositionByContractCharge(contractCharge: ChargeRecord) {
    let quantity = 1;
    let pricePerPiece = Math.round(contractCharge.not_invoiced * 100) / 100;

    if (contractCharge.price_t) {
      quantity = contractCharge.not_invoiced / contractCharge.price_t;
      pricePerPiece = contractCharge.price_t;
    }

    return {
      use: contractCharge.is_gain ? 'gains' : 'costs',
      amount: Math.round(contractCharge.not_invoiced * 100) / 100,
      vat_option: contractCharge.vat_option,
      vat_value: contractCharge.vat_value,
      quantity: Math.round(quantity * 1000) / 1000,
      price_per_piece: pricePerPiece,
      contract: contractCharge.contract,
      contractcharge: contractCharge.id,
      charge: contractCharge.charge,
      passport: contractCharge.passport,
      logistic: contractCharge.logistic,
      customs_declaration: contractCharge.customs_declaration,
      contracts: contractCharge.contracts ?? [],
      date: contractCharge.date,
    };
  }

  checkChargeType(charge: any) {
    if (!this.queryParams.contract) {
      return;
    }
    this.FinancesService.Charge.get({ id: charge.charge }).$promise.then(
      (data: any) => (charge.showFinancingCalcButton = data.tariff_type === 'financing'),
      this.GtUtils.errorClb,
    );
  }

  calcFinancingPrices(charge: any) {
    return this.ContractsService.ContractCharge.getFinancingData(
      {
        charge: charge.charge,
        contract: charge.contract || this.queryParams.contract,
      },
      (data: any) => {
        if (data?.data.length) {
          charge.price = data.data[0].price;
          charge.price_t = data.data[0].price_t;
          charge.currency = data.data[0].currency;
        } else {
          notify(this.gettext('no data'), 'error');
        }
      },
      this.GtUtils.errorClb,
    );
  }

  calculateChargePrice(charge: any, perVolume: any, isPriceChanged: any) {
    Object.assign(
      charge,
      this.ContractsService.calcContractChargePrice(charge, perVolume, isPriceChanged),
    );
  }

  saveCharge(charge: ChargeRecord) {
    charge.volume = charge.volume ?? 0;

    if (charge.id) {
      return this.ContractsService.ContractCharge.update(charge).$promise.then(() => {
        this.updateData();
        this.updateNewCharge();
      }, this.GtUtils.errorClb).$promise;
    }
    return this.ContractsService.checkChargeAndConfirmNotUnique(charge).then((checkResult: any) => {
      if (checkResult === true) {
        return this.ContractsService.ContractCharge.save(charge).$promise.then(() => {
          this.updateData();
          this.updateNewCharge();
        }, this.GtUtils.errorClb);
      } else {
        return this.GtUtils.errorClb({ data: ['charge was not created', checkResult] });
      }
    }).$promise;
  }

  saveMultiContractCharge(charge: ChargeRecord) {
    if (!charge.multicontract || charge.id) {
      return null;
    }
    this.GtUtils.overlay('show');
    return this.MulticontractService.getTableData({ id: charge.multicontract }).then(
      (data: any) => {
        const multicontract = data.records[0];
        const contractCharges = this.ContractsService.createMultiContractCharge(
          charge,
          multicontract,
        );
        return Promise.all(
          contractCharges.map((newCharge: any) => this.saveCharge(newCharge)),
        ).then(() => {
          this.GtUtils.overlay('hide');
        });
      },
    );
  }

  deleteCharge(charge: ChargeRecord) {
    if (!confirm(this.gettext('Are you sure that you want delete Contract Charge?'))) {
      return false;
    }
    return this.ContractsService.ContractCharge.delete({ id: charge.id }).$promise.then(() => {
      this.updateData();
      this.updateNewCharge();
    }, this.GtUtils.errorClb).$promise;
  }

  createChargesByDefault() {
    if (!confirm(this.gettext('Are you sure that you want to create Contract Charges?'))) {
      return false;
    }

    this.creating = true;
    return this.ContractsService.ContractCharge.createChargesByDefault({
      contract: this.queryParams.contract,
    }).$promise.then(() => {
      this.updateData();
      this.updateNewCharge();
    }, this.GtUtils.errorClb).$promise;
  }

  canAddCharge() {
    if (this.objectStage === 'contract') {
      return this.$rootScope.user.perms.includes('can_add_contract_charge');
    }
    if (this.objectStage === 'ticket') {
      return this.$rootScope.user.perms.includes('can_add_ticket_charge');
    }
    return true;
  }

  finishConsolidatedInvoiceCreation() {
    this.consolidatedInvoiceCreation = false;
    return this.updateData();
  }

  toogleSelectMode(flag: boolean) {
    this.selection = flag;
  }

  onCheckboxClick() {
    this.selectedContractCharges = this.contractChargesData.filter((cost: any) => cost.$_selected);
  }

  changeView(view: ViewMode) {
    this.view = view;
    this.$scope.$applyAsync();
  }
}

export const ContractChargesListContainer = {
  bindings: {
    filterLevel: '<?',
    tableName: '<?',
    initQueryParams: '<?',
    showGrouppedTotals: '<?',
    showCommodity: '<?',
    readOnly: '<?',
    invoicingParams: '<?',
    predictionsQueryParams: '<?',
    objectStage: '<?',
  },
  template,
  controller: ContractChargesContainer,
};
