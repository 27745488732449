import React from 'react';

import { useTranslation } from '~/shared/lib/i18n';
import type { ListOption } from '~/shared/lib/types';
import SelectComponent from '~/shared/ui/kit/select/select';

import type { ViewMode } from '../../types';

export const ViewModeSwitcher: React.FC<{
  view: ViewMode;
  onChange?: (view: ViewMode) => void;
  limit?: ViewMode[];
}> = ({ view, onChange, limit = [] }) => {
  const { t } = useTranslation();

  const allModes = React.useMemo<Record<ViewMode, string>>(
    () => ({
      table: t('Table'),
      grid: t('Grid (beta)'),
    }),
    [t],
  );
  const options = React.useMemo<ListOption[]>(
    () =>
      (limit.length
        ? (Object.entries(allModes) as [ViewMode, string][]).filter(([mode]) =>
            limit.includes(mode),
          )
        : (Object.entries(allModes) as [ViewMode, string][])
      ).map(([value, label]) => ({ value, label })),
    [limit, allModes],
  );

  return (
    <SelectComponent
      value={view}
      onSelect={(val) => {
        if (onChange) {
          onChange(val as ViewMode);
        } else {
          console.error('onChange is undefined in ViewModeSwitcher when trying to call it!');
        }
      }}
      options={options}
    />
  );
};

ViewModeSwitcher.displayName = 'ViewModeSwitcher';
