import React from 'react';

import { cn } from '~/shared/lib/utils';

import type { DataTypeProps } from '../../lib';

export const EntityRead: React.FC<DataTypeProps<'entity'>> = ({ disabled, value: values }) => {
  return (
    <div
      className={cn(
        'hover:bg-transparent-light flex gap-2 transition duration-300 ease-in-out',
        disabled && 'cursor-not-allowed opacity-50',
      )}
    >
      <p className="text-text-main-primary pl-2 text-xs">
        {values?.map((i) => i.title).join(', ')}
      </p>
    </div>
  );
};
