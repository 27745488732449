import React from 'react';

import { Dataset } from '~/core/datasets';
import type { Entity, EntityQueryParams, EntityRepository, Patched } from '~/shared/lib/entities';

import type { EntityListContainerModel } from '../../lib/entity-list-container.model';
import type { ContainerProps } from '../../lib/types';

export const EntityListContainer = <
  TModel extends EntityListContainerModel<
    TEntity,
    TParams,
    TRepo,
    TProps,
    TEntityRecord,
    TEntityPost,
    TEntityPatch
  >,
  TEntity extends Entity,
  TParams extends EntityQueryParams,
  TRepo extends EntityRepository<TEntity, TParams, TEntityRecord, TEntityPost, TEntityPatch>,
  TProps extends ContainerProps,
  TEntityRecord extends Entity = TEntity,
  TEntityPost extends Entity = TEntity,
  TEntityPatch extends Entity = Patched<TEntityPost>,
>({
  model,
}: {
  model: TModel;
}) => {
  return (
    <Dataset
      id={model.datasetId}
      view={model.props.viewMode}
      data$={model.pageData$}
      loading$={model.loading$}
      pageParams$={model.pageParams$}
      fieldsSrc={model.getFields}
      onRecordAdd={model.createNewRecord}
      onRecordsSave={model.saveRecords}
      onRecordsDelete={model.deleteRecords}
      onParamsChange={model.pageParamsChanged}
      editing$={model.editingMode$}
      selectable={true}
    />
  );
};
