import { BehaviorSubject, filter, shareReplay } from 'rxjs';

import type { ComponentProps } from './types';

export abstract class ComponentModel<TProps extends ComponentProps> {
  protected readonly propsSubj = new BehaviorSubject<TProps | null>(null);

  props$ = this.propsSubj.pipe(filter(Boolean), shareReplay({ bufferSize: 1, refCount: false }));

  get props(): TProps {
    if (!this.propsSubj.value) {
      throw new Error('ComponentModel propsSubj is empty');
    }
    return this.propsSubj.value;
  }

  set props(props: TProps) {
    this.propsSubj.next(props);
  }
}
