import {
  accountsUsersCreate,
  accountsUsersDestroy,
  accountsUsersList,
  accountsUsersPartialUpdate,
  accountsUsersPredictionsRetrieve,
  accountsUsersRetrieve,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { type Entity, EntityRepository } from '~/shared/lib/entities';

import type { User, UserPatch, UserPost, UserQueryParams } from '../lib/types';

@singleton()
export class UserRepository extends EntityRepository<
  User,
  UserQueryParams,
  User,
  UserPost,
  UserPatch
> {
  readonly entityName = 'accounts.User';

  getFn = async (id: number) => {
    const { data } = await accountsUsersRetrieve({ path: { id } });
    return data;
  };
  queryFn = async (params: UserQueryParams) => {
    const { data } = await accountsUsersList({ query: params });
    return { records: data.results, count: data.count };
  };
  searchFn = async (input: string) => {
    const { data } = await accountsUsersPredictionsRetrieve({ query: { search: input } });
    return data.results;
  };

  createFn = async (entity: UserPost) => {
    const { data } = await accountsUsersCreate({ body: entity });
    return data;
  };
  updateFn = async (entity: Partial<UserPost> & Entity): Promise<User> => {
    const { data } = await accountsUsersPartialUpdate({ body: entity, path: { id: entity.id } });
    return data;
  };
  deleteFn = async (id: number) => {
    await accountsUsersDestroy({ path: { id } });
  };

  recordToPatch = (record: User): UserPatch => {
    if (record.profile.work_status_start) {
      record.profile.work_status_start = new Date(record.profile.work_status_start).toISOString();
    }
    if (record.profile.work_status_end) {
      record.profile.work_status_end = new Date(record.profile.work_status_end).toISOString();
    }
    return {
      ...record,
      profile: {
        ...record.profile,
        avatar: null,
      } as UserPatch['profile'],
    };
  };

  buildNewRecord = (): User => ({
    id: -Date.now(),
    profile: {} as unknown as User['profile'],
    perms: '',
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    is_staff: false,
    is_active: true,
    date_joined: new Date().toISOString(),
    groups: [],
    user_permissions: [],
  });
}
