import type ng from 'angular';

import { notify } from '~/shared/lib/notify';

import template from './role-limits.html?raw';

import type { GtUtilsService } from '^/app/core/legacy/gt-utils/gt-utils.srv';
import type { ContractsService } from '^/app/deals/contracts/legacy/contracts.srv';

export const RoleLimits = {
  bindings: {
    roleName: '<',
    roleId: '<',
    seasonId: '<?',
  },
  template,
  controller: [
    '$scope',
    'ClientsService',
    'ContractsService',
    'GtUtils',
    'gettext',
    class {
      $scope: ng.IScope;
      ClientsService: any;
      ContractsService: ContractsService;
      GtUtils: GtUtilsService;
      edit: boolean;
      gettext: ng.gettext.gettextFunction;
      roleId?: number;
      seasonId?: number;
      roleName = '';
      roleWithLimits: any;
      isSeasonal = false;
      constructor(
        $scope: ng.IScope,
        ClientsService: any,
        ContractsService: ContractsService,
        GtUtils: GtUtilsService,
        gettext: ng.gettext.gettextFunction,
      ) {
        this.$scope = $scope;
        this.ClientsService = ClientsService;
        this.ContractsService = ContractsService;
        this.GtUtils = GtUtils;
        this.gettext = gettext;

        this.edit = false;
        this.roleWithLimits = {};
      }

      $onInit() {
        this.isSeasonal = Boolean(this.seasonId);
        this.updateRoleLimits();

        this.$scope.$on('season-limits-refresh-requested', (_, data: { seasonId: number }) => {
          if (this.seasonId && data.seasonId === this.seasonId) {
            this.updateRoleLimits();
          }
        });
      }

      updateRoleLimits() {
        if (!this.roleId) {
          throw new Error('Role ID is required');
        }
        this.GtUtils.overlay('show');
        return this.ContractsService.getRoleLimits(this.roleName, this.roleId, this.seasonId)
          .then((data: any) => {
            // TODO: refactor this
            this.roleWithLimits = { ...data, id: this.roleId, _limitId: data.id };
          }, this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }

      save() {
        this.GtUtils.overlay('show');
        return this.ClientsService.saveRole(this.roleWithLimits, this.roleName, this.seasonId)
          .then(() => {
            notify(this.gettext('Limit saved'));
            this.updateRoleLimits();
            this.edit = false;
          }, this.GtUtils.errorClb)
          .finally(() => this.GtUtils.overlay('hide'));
      }
    },
  ],
};
