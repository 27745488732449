import {
  coreQueryparamssetsCreate,
  coreQueryparamssetsDestroy,
  coreQueryparamssetsList,
  coreQueryparamssetsUpdate,
} from '~/shared/api';
import { singleton } from '~/shared/lib/di';
import { EntityRepository } from '~/shared/lib/entities';
import { toIconName } from '~/shared/ui/icons';

import { QueryParamsSet, QueryParamsSetPost, QueryParamsSetQueryParams } from '../types';

@singleton()
export class QueryParamsSetRepository extends EntityRepository<
  QueryParamsSet,
  QueryParamsSetQueryParams,
  QueryParamsSet,
  QueryParamsSetPost,
  QueryParamsSetPost
> {
  readonly entityName = 'core.QueryParamsSet';

  getFn = (_id: number) => {
    throw new Error('Method not implemented.');
  };
  queryFn = async (query: QueryParamsSetQueryParams) => {
    const { data } = await coreQueryparamssetsList({ query });
    return {
      records: data.results.map((i) => ({ ...i, icon: toIconName(i.icon, 'HeartIcon') })),
      count: data.count,
    };
  };
  searchFn = (_input: string, _id?: number) => {
    throw new Error('Method not implemented.');
  };
  createFn = async (body: QueryParamsSetPost) => {
    const { data } = await coreQueryparamssetsCreate({ body });
    return data;
  };
  updateFn = async (body: QueryParamsSetPost) => {
    const { data } = await coreQueryparamssetsUpdate({ body, path: { id: body.id } });
    return data;
  };
  deleteFn = async (id: number): Promise<void> => {
    await coreQueryparamssetsDestroy({ path: { id } });
  };
}
