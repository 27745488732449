import React from 'react';

import type { ContainerViewProps } from '~/core/entity-lists';
import { EntityListContainer } from '~/core/entity-lists';
import type {
  InfoLogisticRecord,
  Logistic,
  LogisticPatch,
  LogisticPost,
  LogisticQueryParams,
} from '~/features/execution/logistic/common';
import { createComponent } from '~/shared/lib/components';

import type { InfoLogisticListContainerProps } from './info-logistic-list.model';
import { InfoLogisticListModel } from './info-logistic-list.model';
import type { InfoLogisticRepository } from '../services';

const InfoLogisticContainerView: React.FC<
  ContainerViewProps<InfoLogisticListModel, InfoLogisticListContainerProps>
> = ({ model }) => {
  return (
    <EntityListContainer<
      InfoLogisticListModel,
      Logistic,
      LogisticQueryParams,
      InfoLogisticRepository,
      InfoLogisticListContainerProps,
      InfoLogisticRecord,
      LogisticPost,
      LogisticPatch
    >
      model={model}
    />
  );
};

export const InfoLogisticContainer = createComponent(
  InfoLogisticContainerView,
  InfoLogisticListModel,
);
