import type { CellContext } from '@tanstack/react-table';
import { useObservableEagerState } from 'observable-hooks';
import React from 'react';

import { RecordsActions } from '~/core/datasets/components/records-actions';
import type { Message } from '~/shared/lib/types';
import type { DataTypeValue } from '~/shared/ui/data-types';
import { dataTypeComponents } from '~/shared/ui/data-types';

import { getRecordValue } from '../../helpers/records';
import { useErrorMessage } from '../../hooks/errors';
import { useDatasetModel } from '../../hooks/models';
import type { DataParams, DataRecord, FieldDef } from '../../types';

export const CellRender = <R extends DataRecord, P extends DataParams>({
  cell,
  row,
}: CellContext<R, unknown>) => {
  const datasetModel = useDatasetModel<R, P>();
  const datasetEditing = useObservableEagerState(datasetModel.editing$);
  const field = cell.column.columnDef.meta?.field as FieldDef<R>;
  const value = React.useMemo(() => getRecordValue(row.original, field), [field, row.original]);
  const mode = React.useMemo(
    () => ((field.editing ?? datasetEditing) ? 'write' : 'read'),
    [field.editing, datasetEditing],
  );

  const onChangeHandler = React.useCallback(
    (value: DataTypeValue<FieldDef<R>['dataType']>) =>
      datasetModel.valueChanged({ field, recordId: row.original.id, value }),
    [datasetModel, field, row.original.id],
  );

  const message = useErrorMessage({ recordId: row.original.id, fieldKey: field.key });

  const Component = React.useMemo(() => {
    if (field.render) {
      return (props: { value: DataTypeValue<'string'>; message?: Message }) =>
        field.render?.({
          value: props.value,
          valueChanged: (value: unknown) =>
            datasetModel.valueChanged({ field, recordId: row.original.id, value }),
          field,
          editing: field.editing === false,
          record: cell.row.original,
        } as never);
    }

    switch (field.dataType) {
      case 'text':
      case 'string':
      case 'phone':
      case 'email':
      case 'url':
        return (props: { value: DataTypeValue<'string'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'number':
        return (props: { value: DataTypeValue<'number'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'template':
        return (props: { value: DataTypeValue<'template'>; message?: Message }) =>
          dataTypeComponents().template[mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'label':
        return (props: { value: DataTypeValue<'label'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'person':
        return (props: { value: DataTypeValue<'person'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'checkbox':
        return (props: { value: DataTypeValue<'checkbox'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'date':
        return (props: { value: DataTypeValue<'date'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'daterange':
        return (props: { value: DataTypeValue<'daterange'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'select':
        return (props: { value: DataTypeValue<'select'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'multiselect':
        return (props: { value: DataTypeValue<'multiselect'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      case 'entity':
        return (props: { value: DataTypeValue<'entity'>; message?: Message }) =>
          dataTypeComponents()[field.dataType][mode]({
            value: props.value,
            onChange: onChangeHandler,
            disabled: field.editing === false,
            message: props.message,
            typeExtra: field.typeExtra,
          });
      default:
        throw new Error('Unknown data type');
    }
  }, [cell.row.original, datasetModel, field, mode, onChangeHandler, row.original.id]) as React.FC<{
    value: unknown;
    message?: Message;
  }>;

  return (
    <div className="text-text-main-primary inline-flex w-full px-1">
      <Component value={value} message={message} />
      {field.actions?.length && row.original.id > 0 ? (
        <RecordsActions actions={field.actions} records={[row.original]} />
      ) : null}
    </div>
  );
};
