import type { RowData } from '@tanstack/react-table';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React from 'react';

import { useObservableEagerState, useObservableState } from '~/shared/lib/state';

import { buildColumnDefs } from './column-builder';
import { useDatasetModel } from '../../hooks/models';
import type { DataParams, DataRecord, DatasetArgs, FieldDef } from '../../types';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    field: FieldDef<DataRecord & TData>;
  }
}

export const allRowsSelected = <T extends { id: string }>(
  data: T[],
  selectedRowIds: string[],
  rowSelected?: (ids: string[]) => void,
) => {
  const allSelected = selectedRowIds.length === data.length;
  if (allSelected) {
    rowSelected?.([]);
  } else {
    rowSelected?.(data.map((item) => item.id));
  }
};

export const useTable = <R extends DataRecord, P extends DataParams>({
  fields,
  selectable,
}: Pick<DatasetArgs<R, P>, 'fields' | 'selectable'>) => {
  const datasetModel = useDatasetModel<R, P>();
  const combinedRecords = useObservableState(datasetModel.combinedRecords$, []);
  const rowsSelected = useObservableEagerState(datasetModel.rowsSelected$);

  const columns = React.useMemo(
    () =>
      buildColumnDefs({
        fields,
        selectable,
      }),
    [fields, selectable],
  );

  const table = useReactTable({
    data: combinedRecords,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: datasetModel.setRowsSelected,
    columnResizeMode: 'onChange' as const,
    columnResizeDirection: 'ltr' as const,
    state: { rowSelection: rowsSelected },
    getRowId: (row) => row.id.toString(),
    defaultColumn: { size: 200, minSize: 24, maxSize: 300 },
  });

  return table;
};
